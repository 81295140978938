import React, { useContext } from "react";
import { Helpers } from "../libs/helpers";
import { protoTypes } from '@hlmvs/hlmvs-client';
import { AuthContext } from "../contexts/authContext";

// import Button from '@material-ui/core/Button';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';


export const ValidatorActivityDetails: React.FunctionComponent<{user_choice: protoTypes.DetailedEarningRecord.DetailCase;  }> = (props) => {

    const [detailedEarnings, setDetailedEarnings] = React.useState<protoTypes.DetailedEarningRecord[]>([]);
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const [HandleClose, setHandleClose] = React.useState(false); 
    // const [breakDown, setBreakdown] = useState(false); 

    const authContext = useContext(AuthContext);

    React.useEffect(() => {
        Helpers.getEarnings(authContext).then(earnings => {
            console.log("Got earnings: " + JSON.stringify(earnings.getDataList()));
            setDetailedEarnings(earnings.getDataList());
        }).catch(ex => {
            console.warn("Failed to load earnings data" + JSON.stringify(ex));
        });
        // const records: protoTypes.DetailedEarningRecord[] = [];

        // const ex1 = new protoTypes.DetailedEarningRecord();
        // const ex2 = new protoTypes.DetailedEarningRecord();
        // const ex3 = new protoTypes.DetailedEarningRecord();

        // const payout1 = new protoTypes.DetailedPayoutRecord();
        // const payout2 = new protoTypes.DetailedPayoutRecord();

        // const stake = new protoTypes.DetailedStakeRecord(); 

        // stake.setHash("Homo"); 
        // const stakeAmount1 = new protoTypes.HeliumAmount();
        // stakeAmount1.setBones(10000000);
        // stakeAmount1.setUnit(protoTypes.HeliumAmountKind.BONES);
        // stake.setAmount(stakeAmount1); 
        // // stake.setStakewallet()
        // stake.setDate() 
        


        // payout1.setRewardhash("abcd");
        // payout2.setRewardhash("CDEF");
        // const payment1 = new protoTypes.Payment();
        // const payment2 = new protoTypes.Payment(); 
        
        
        // payment1.setHash("ownerPaymentHash");
        // const amount1 = new protoTypes.HeliumAmount();
        // amount1.setBones(1000000000);
        // amount1.setUnit(protoTypes.HeliumAmountKind.BONES);
        // payment1.setAmount(amount1);
        // payout1.setOwnerpayout(payment1);
        

        // const serviceFeePayment: protoTypes.Payment = new protoTypes.Payment();
        // serviceFeePayment.setHash("serviceFeeHash");
        // const serviceFeeAmount = new protoTypes.HeliumAmount();
        // serviceFeeAmount.setBones(1000000);
        // serviceFeeAmount.setUnit(protoTypes.HeliumAmountKind.BONES);
        // serviceFeePayment.setAmount(serviceFeeAmount);

        // const serverFeePayment: protoTypes.Payment = new protoTypes.Payment();
        // serverFeePayment.setHash("serverFeeHash");
        // const serverFeeAmount = new protoTypes.HeliumAmount();
        // serverFeeAmount.setBones(100000);
        // serverFeeAmount.setUnit(protoTypes.HeliumAmountKind.BONES);
        // serverFeePayment.setAmount(serverFeeAmount);

        // payment2.setHash("ownerPaymentHash");
        // const amount2 = new protoTypes.HeliumAmount();
        // amount2.setBones(99000000000);
        // amount2.setUnit(protoTypes.HeliumAmountKind.BONES);
        // payment2.setAmount(amount2);
        // payout2.setOwnerpayout(payment2);

        // const serviceFeePayment1: protoTypes.Payment = new protoTypes.Payment();
        // serviceFeePayment1.setHash("serviceFeeHash");
        // const serviceFeeAmount1 = new protoTypes.HeliumAmount();
        // serviceFeeAmount1.setBones(1000000);
        // serviceFeeAmount1.setUnit(protoTypes.HeliumAmountKind.BONES);
        // serviceFeePayment1.setAmount(serviceFeeAmount1);

        // const serverFeePayment1: protoTypes.Payment = new protoTypes.Payment();
        // serverFeePayment1.setHash("serverFeeHash");
        // const serverFeeAmount1 = new protoTypes.HeliumAmount();
        // serverFeeAmount1.setBones(100000);
        // serverFeeAmount1.setUnit(protoTypes.HeliumAmountKind.BONES);
        // serverFeePayment1.setAmount(serverFeeAmount1);


        // payout1.setServicefee(serviceFeePayment1);
        // payout1.setServerfee(serverFeePayment1);

        // payout2.setServicefee(serviceFeePayment1);
        // payout2.setServerfee(serverFeePayment1);
        

        // ex3.setStake(stake);

        // ex2.setPayout(payout2);
        // ex1.setPayout(payout1);
        // records.push(ex1, ex2, ex3);


        // // records.push(ex2);
        
        // setDetailedEarnings(records);
    }, [authContext]);

    const makePayoutRow = (payout: protoTypes.DetailedPayoutRecord) => {
        return (<div> 

        
        <div className="mt-2 flex flex-wrap text-center">
            <div className="w-1/4"><p className=" hmvs-font"> {Helpers.getHntFromHeliumAmount(payout.getOwnerpayout()!.getAmount())} </p></div>
            <div className="w-1/4"><p className=" hmvs-font">{Helpers.getHntFromHeliumAmount(payout.getServerfee()!.getAmount())}</p></div>
            <div className="w-1/4"><p className="hmvs-font">{Helpers.getHntFromHeliumAmount(payout.getServicefee()!.getAmount())} </p> </div>
            <div className="w-1/4"><p className="hmvs-font">{Helpers.getHntFromHeliumAmount(payout.getOwnerpayout()!.getAmount()) - Helpers.getHntFromHeliumAmount(payout.getServerfee()!.getAmount()) - Helpers.getHntFromHeliumAmount(payout.getServicefee()!.getAmount())} </p> </div>
        </div>
        <div className="mt-2 flex flex-wrap text-center">
            <div className="w-1/4"><p className=" hmvs-font"> {Helpers.getHntFromHeliumAmount(payout.getOwnerpayout()!.getAmount())} </p></div>
            <div className="w-1/4"><p className=" hmvs-font">{Helpers.getHntFromHeliumAmount(payout.getServerfee()!.getAmount())}</p></div>
            <div className="w-1/4"><p className="hmvs-font">{Helpers.getHntFromHeliumAmount(payout.getServicefee()!.getAmount())} </p> </div>
            <div className="w-1/4"><p className="hmvs-font">{Helpers.getHntFromHeliumAmount(payout.getOwnerpayout()!.getAmount()) - Helpers.getHntFromHeliumAmount(payout.getServerfee()!.getAmount()) - Helpers.getHntFromHeliumAmount(payout.getServicefee()!.getAmount())} </p> </div>
        </div>
        <div className="mt-2 flex flex-wrap text-center">
            <div className="w-1/4"><p className=" hmvs-font"> {Helpers.getHntFromHeliumAmount(payout.getOwnerpayout()!.getAmount())} </p></div>
            <div className="w-1/4"><p className=" hmvs-font">{Helpers.getHntFromHeliumAmount(payout.getServerfee()!.getAmount())}</p></div>
            <div className="w-1/4"><p className="hmvs-font">{Helpers.getHntFromHeliumAmount(payout.getServicefee()!.getAmount())} </p> </div>
            <div className="w-1/4"><p className="hmvs-font">{Helpers.getHntFromHeliumAmount(payout.getOwnerpayout()!.getAmount()) - Helpers.getHntFromHeliumAmount(payout.getServerfee()!.getAmount()) - Helpers.getHntFromHeliumAmount(payout.getServicefee()!.getAmount())} </p> </div>
        </div>
        <div className="mt-2 flex flex-wrap text-center">
            <div className="w-1/4"><p className=" hmvs-font"> {Helpers.getHntFromHeliumAmount(payout.getOwnerpayout()!.getAmount())} </p></div>
            <div className="w-1/4"><p className=" hmvs-font">{Helpers.getHntFromHeliumAmount(payout.getServerfee()!.getAmount())}</p></div>
            <div className="w-1/4"><p className="hmvs-font">{Helpers.getHntFromHeliumAmount(payout.getServicefee()!.getAmount())} </p> </div>
            <div className="w-1/4"><p className="hmvs-font">{Helpers.getHntFromHeliumAmount(payout.getOwnerpayout()!.getAmount()) - Helpers.getHntFromHeliumAmount(payout.getServerfee()!.getAmount()) - Helpers.getHntFromHeliumAmount(payout.getServicefee()!.getAmount())} </p> </div>
        </div>
        <div className="mt-2 flex flex-wrap text-center">
            <div className="w-1/4"><p className=" hmvs-font"> {Helpers.getHntFromHeliumAmount(payout.getOwnerpayout()!.getAmount())} </p></div>
            <div className="w-1/4"><p className=" hmvs-font">{Helpers.getHntFromHeliumAmount(payout.getServerfee()!.getAmount())}</p></div>
            <div className="w-1/4"><p className="hmvs-font">{Helpers.getHntFromHeliumAmount(payout.getServicefee()!.getAmount())} </p> </div>
            <div className="w-1/4"><p className="hmvs-font">{Helpers.getHntFromHeliumAmount(payout.getOwnerpayout()!.getAmount()) - Helpers.getHntFromHeliumAmount(payout.getServerfee()!.getAmount()) - Helpers.getHntFromHeliumAmount(payout.getServicefee()!.getAmount())} </p> </div>
        </div>
        </div>
    
        
        )
    }
   
    

    //   const handleBreakdown = () => {
    //     if (breakDown === true) {
    //         setBreakdown(false); 
    //     }else
    //     {
    //         setBreakdown(true); 
    //     }
        
    // };


    const makeStakeRow = (stake : protoTypes.DetailedStakeRecord) => {
        return (<div className="text-white"> 

       
        <div className="flex flex-wrap text-center">
            <div className="w-1/3  hmvs-font"><p>{new Date((stake.getDate()?.getSeconds() || 0) * 1000).toLocaleDateString()}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
        </div>
        <div className="flex flex-wrap text-center">
            <div className="w-1/3  hmvs-font"><p>{new Date((stake.getDate()?.getSeconds() || 0) * 1000).toLocaleDateString()}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
        </div>
        <div className="flex flex-wrap text-center">
            <div className="w-1/3  hmvs-font"><p>{new Date((stake.getDate()?.getSeconds() || 0) * 1000).toLocaleDateString()}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
        </div>
        <div className="flex flex-wrap text-center">
            <div className="w-1/3  hmvs-font"><p>{new Date((stake.getDate()?.getSeconds() || 0) * 1000).toLocaleDateString()}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
        </div>
        <div className="flex flex-wrap text-center">
            <div className="w-1/3  hmvs-font"><p>{new Date((stake.getDate()?.getSeconds() || 0) * 1000).toLocaleDateString()}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
        </div>
        <div className="flex flex-wrap text-center">
            <div className="w-1/3  hmvs-font"><p>{new Date((stake.getDate()?.getSeconds() || 0) * 1000).toLocaleDateString()}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
        </div>
        <div className="flex flex-wrap text-center">
            <div className="w-1/3  hmvs-font"><p>{new Date((stake.getDate()?.getSeconds() || 0) * 1000).toLocaleDateString()}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
        </div>
        <div className="flex flex-wrap text-center">
            <div className="w-1/3  hmvs-font"><p>{new Date((stake.getDate()?.getSeconds() || 0) * 1000).toLocaleDateString()}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
        </div>
        <div className="flex flex-wrap text-center">
            <div className="w-1/3  hmvs-font"><p>{new Date((stake.getDate()?.getSeconds() || 0) * 1000).toLocaleDateString()}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
            <div className="w-1/3  hmvs-font"><p>{Helpers.getHntFromHeliumAmount(stake.getAmount())}</p></div>
        </div>
      
        </div>
        
        )
    }

    const makeUnstakeRow = (stake : protoTypes.DetailedUnstakeRecord) => {
        return (<div className=""> 
        <div className="w-1/2 text-white hmvs-font"><p>{stake.getDate()}</p></div>
        <div className="w-1/2 text-white hmvs-font"><p>{stake.getAmount()} </p></div>
        </div>
        
        )
    }

    const makeRow = (earning: protoTypes.DetailedEarningRecord) => {
        switch (earning.getDetailCase()) {
            case protoTypes.DetailedEarningRecord.DetailCase.PAYOUT:
                return makePayoutRow(earning.getPayout()!);
            case protoTypes.DetailedEarningRecord.DetailCase.STAKE:
                return makeStakeRow(earning.getStake()!);
            case protoTypes.DetailedEarningRecord.DetailCase.UNSTAKE:
                return makeUnstakeRow(earning.getUnstake()!);
            default:
                return (<div></div>)    
        }
    };

    const makeHeader = (type: protoTypes.DetailedEarningRecord.DetailCase) => {
        switch (type) {
            case protoTypes.DetailedEarningRecord.DetailCase.PAYOUT:
                return (<div className="flex flex-wrap text-white text-center">
                <div className="w-1/4"><p className="  hmvs-font ">Mined</p></div>
                <div className="w-1/4"><p className=" hmvs-font">Service Fee</p></div>
                <div className="w-1/4"><p className="  hmvs-font">Server Fee</p></div>
                <div className="w-1/4"><p className="  hmvs-font">Net Income</p></div>

            </div>)
            case protoTypes.DetailedEarningRecord.DetailCase.STAKE:
                return (<div className="flex text-white flex-wrap ">
                <div className="w-1/3  hmvs-font"><p>Date</p></div>
                <div className="w-1/3  hmvs-font"><p>Event</p></div>
                <div className="w-1/3  hmvs-font"><p>Amount</p></div>
            </div>)
            case protoTypes.DetailedEarningRecord.DetailCase.UNSTAKE:
                return (<div className="flex flex-wrap text-center">
                <div className="w-1/2  hmvs-font"><p>Date</p></div>
                <div className="w-1/2  hmvs-font"><p>Amount</p></div>
            </div>)
        }
    }

  


    return (
      <div>
            <div className="overflow-auto h-32">
                {makeHeader(props.user_choice)}
                {detailedEarnings.filter(earning => earning.getDetailCase() === props.user_choice).map(earning => {
                  return makeRow(earning);
                })}
           </div>
      </div>
    )
  }

export default ValidatorActivityDetails;