import { useHistory } from 'react-router-dom'
// import { AuthIsNotSignedIn, AuthIsSignedIn } from '../contexts/authContext';
// import { AuthContext, /*AuthIsNotSignedIn, AuthIsSignedIn*/ } from '../contexts/authContext';
// import { useContext } from 'react';
import '../hmvs.css';
const Footer: React.FunctionComponent<{}> = () => {

    // const auth = useContext(AuthContext);

    const history = useHistory();

    const TermsOfService = () => {
        history.push('/TermsOfService')
    }
    // const faq = () => {
    //     history.push('/faq')
    // }

    // const BLOG = () => {
    //     history.push('/BLOG')
    // }



    // const menu = document.getElementById('menu');
    // const toggle = () => menu!.classList.toggle("hidden");


    return (


        <footer className="footer flex flex-wrap items-center justify-between p-4">
            <div className="w-full lg:w-auto lg:mr-6 mb-4 lg:mb-0 text-center text-white hmvs-font">© 2021 Vanager LLC</div>
            <div className="flex flex-grow items-center w-full lg:w-auto">
                <div className="mx-auto lg:mx-0 lg:ml-auto"> 
                    <button className="inline-block mt-0 mr-8 text-white hmvs-font" onClick={TermsOfService}>Terms of Service</button>
                 


                </div>
            </div>
        </footer>
    )
}

export default Footer;