import React from "react";
import ArticleFormat from "./articlerepository";

const article = (<div>
    <p className="hmvs-font mt-8 hmvs-text-primary mb-2 text-xl font-bold">How to unstake a Helium Validator with Vanager</p>
        <p className="hmvs-font text-white">To unstake a validator with Vanager the requirement is that the majority stake owner(s) have to initiate an unstake before the validator will begin the unstaking process. Once the unstaking process has completed the stake owner(s) will have to wait ~5 months before their funds are returned to the wallet that originated the stake. (The 5 month duration is a requirement from the helium block chain for any validator that unstakes) </p>
        <ul className="mt-3 list-decimal px-4">
            <li className="hmvs-font text-base text-white">Go to the Validator you want to unstake in your user profile.</li>
            <li className="hmvs-font text-base text-white">Click the “ooo” and then click unstake.</li>
            <p className="mt-4 mb-2 hmvs-font text-black bg-yellow-500 text-center py-2 px-2 font-bold">If the requirements are met for unstaking then the process will begin immediately and you will stop earning mining rewards. </p>

        </ul>
        <p className="mt-6 hmvs-font hmvs-text-primary font-bold">Full Stake - Unstaking</p>
        <p className="hmvs-font text-white">Individuals that stake a validator by themselves they simply have to follow the process listed above and the funds will be unstaked.</p>
        <p className="mt-5 hmvs-font hmvs-text-primary font-bold">Partial Stake - Unstaking</p>
        <p className="hmvs-font text-white">Along with following the above process the validator must also meet the requirement of having a majority of the staked amount wanting to unstake. An example of this would be if there were 10 validator members all contributing 1,000 HNT for a total of 10,000 HNT. In order for the validator to successfully unstake 6 of the members or 6,000 HNT would have to request an unstake.</p>
        
</div>) 

export const HowToUnstakeAHeliumValidator: React.FunctionComponent<{  }> = () => {
    return (
      <div className="px- ">
         <ArticleFormat article={article} ></ArticleFormat>
        


        

       
      </div>
    )
  }

export default HowToUnstakeAHeliumValidator;