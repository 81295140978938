import React from 'react'

import InterestForm from '../components/interestForm'
import Estimator from '../components/estimatorComponent'
import { useHistory } from 'react-router'

const Landing: React.FunctionComponent = () => {

  const history = useHistory();

  const signIn = () => {
    history.push('/signin');
  }

  const signUp = () => {
    history.push('/signup');
  }

  return (
    
    
    <div className="z-0">
      <section className="hmvs-bg-primary relativ lg:py-30 py-20  ">
          <div className="flex flex-wrap items-center">
            {/* <div className="flex flex-wrap"> */}
              <div className="flex-col lg:mx-10   ">
                
                <div className="lg:py-36"></div>
                {/* <div className="flex-row -mt-10 font-bold"><p className="text-7xl lg:text-8xl text-white">V</p></div>
                <div className="flex-row  -mt-10 font-bold"><p className="text-7xl lg:text-8xl text-white">V</p></div>
                <div className="flex-row  -mt-10 font-bold"><p className="text-7xl lg:text-8xl text-white">V</p></div> */}
                <div className="flex flex-wrap mx-7 lg:flex-row">
                  <span><img alt="Letter V Logo" src="images/V.png" style={{height:"250px", width:"250px"}}></img></span>
                  <div className="flex-col lg:px-4">
                    <div className="lg:flex-row   font-bold"><p className="text-7xl lg:text-8xl hmvs-font leading-none mb-5 text-black">Vanager</p></div>
                    <div className="lg:flex-row mb-7"><p className="hmvs-font  text-black">Simple, transparent, no-hassle validator management service</p></div>
                    <div className="flex-row  lg:mb-20 md:mb-6"><button value="breakdown" onClick={signUp} className={` hmvs-font  py-6 px-7 text-lg leading-none hmvs-bg-darkest text-white font-semibold rounded shadow`}>Get Started</button></div>

                  </div>

                  
                </div>
                <div className="py-2"></div>

                
              </div>
              {/* <div className="flex-col invisible lg:visible ">
                <div className="flex-row -mx-24 -my-96 font-bold"><p className="text-30xl text-black ">V</p></div>
                <div className="flex-row -mx-24  -my-96 font-bold"><p className="text-30xl text-black  ">V</p></div>
              </div> */}


            {/* </div> */}
         
        </div>
      </section>
      <div className="lg:py-10"></div>
      <div>
        <section className="py-16">
          <div className="flex flex-wrap  mb-3">
            <div className="lg:w-1/3 lg:px-14 px-6 md:mb-10 mb-12  ">
              <img src="images/MANAGE.svg" alt="" className="px-20 image-center"></img>
              <div className="py-3"></div>
              <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
              <div className="py-2"></div>


              <p className="font-bold hmvs-font  mb-3 hmvs-text-primary">Fully Managed Validator Service</p>
              <p className="text-white hmvs-font">We offer Helium Validators as a Service. We will set up, monitor, and operate a full Helium validator, as well as offer detailed metrics on earnings over time. We host our validators on AWS and make them geographically diverse to maximize rewards and network health.</p>
            </div>
            <div className="lg:w-1/3 lg:px-14 px-6 md:mb-10 mb-12 ">
              <img src="images/PARTIAL STAKE.svg" alt="" className="px-20 image-center" ></img>
              <div className="py-3"></div>
              <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
              <div className="py-2"></div>


              <p className="font-bold hmvs-font  mb-3 hmvs-text-primary">Partial Staking Service</p>
              <p className="text-white hmvs-font"> Our partial staking pool service enables anyone with at least 100 HNT to participate in Helium Validators. We group together multiple partial stakers until there is 10,000 HNT in a group.  At that point we will launch the validator and distribute earnings based on % ownership (minus fees). </p>
            </div>
            <div className="lg:w-1/3 lg:px-14 px-6 md:mb-10 mb-12 ">
              <img src="images/PRICE.svg" alt="" className="px-20 image-center " ></img>
              <div className="py-3"></div>
              <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
              <div className="py-2"></div>


              <p className="font-bold hmvs-font  mb-3 hmvs-text-primary">Transparent Pricing </p>
              <p className="text-white hmvs-font">Fundamentally we charge two fees: (1) The cost to run, operate, and maintain the servers in the cloud and (2) a service fee which is a fixed 3% of the remaining HNT rewards.</p>
            </div>
          </div>
        </section>

        <section className="">
          <div className="flex flex-wrap  lg:px-9 hmvs-bg-neutaral-dark py-20 mx-auto">
            <div className="w-full md:w-1/3 p-4 px-6 mb-2 md:mb-0">
              <div className="lg:py-20"></div>
              <p className="lg:text-7xl text-white text-4xl hmvs-font leading-none font-bold">STAKE IT <span className="hmvs-text-primary hmvs-font">YOUR</span>  WAY</p>
            </div>
            <div className="w-full md:w-1/3 p-4 mb-2 md:mb-0 ">
              <div className="py-3 px-6 border ">

                <img src="images/FULL STAKE.svg" alt="" className="px-14 image-center mt-9" ></img>
                <h3 className="text-2xl mb-4 font-bold font-heading hmvs-font hmvs-text-primary ">Full Stake</h3>
                <p className=" text-white leading-relaxed hmvs-font text-sm">For individuals that have 10,000 HNT. Full stakers retain all earnings and awards minus operational cost.  </p>
                <div className="py-2 lg:py-0"></div>
                <p className="mb-3 font-semibold text-md hmvs-font font-bold text-white mt-5">Includes</p>
                <ul className="mb-8 list-inside list-disc text-gray-400">
                  <li className="mb-1 text-white hmvs-font">Own an entire Validator!</li>
                  <li className="mb-1 text-white hmvs-font">Instant Payout</li>
                  <li className="mb-1 text-white hmvs-font">Earnings and validator health dashboard</li>
                  <li className="mb-1 text-white hmvs-font">Low operational cost:<p className="text-sm text-white hmvs-font">$4/day Operational Expense + 3% Service Fee (on earnings)</p></li>

                </ul>
                <div className="py-5"></div>
                <button className="w-full rounded hmvs-button-primary hover:bg-white shadow" onClick={signIn}>Full Stake</button>
                <div className="py-2"></div>
              </div>
            </div>
            <div className="w-full md:w-1/3 p-4 mb-2 md:mb-0">
              <div className="py-3 px-6 border">
                
                <img src="images/PARTIAL STAKE.svg" alt="" className="px-14 image-center mt-9" ></img>
                <h3 className="text-2xl mb-4 font-bold font-heading hmvs-font hmvs-text-primary ">Partial Stake</h3>
                <p className="text-white leading-relaxed text-sm hmvs-font">For individuals that have less than 10,000 HNT. Users can easily split a validator with other Vanager member. Rewards and fees are split proportionally from a single validator earnings.  </p>
                <p className="mb-3 font-semibold text-md  font-bold text-white mt-5 hmvs-font">Includes</p>
                <ul className="mb-8 list-inside list-disc text-gray-400">
                  <li className="mb-1 text-white hmvs-font">Split ownership of a single validator</li>
                  <li className="mb-1 text-white hmvs-font">Instant payout</li>
                  <li className="mb-1 text-white hmvs-font">Earnings and validator health dashboard</li>
                  <li className="mb-1 text-white hmvs-font">Low operational cost: <p className="text-sm text-white hmvs-font">$4/day operational expense + 3% service fee (on earnings)</p> </li>
                </ul>
                <div className="py-2"></div>
                <div className="flex-row "><button value="breakdown" onClick={signIn} className={` hmvs-font w-full rounded hmvs-button-primary hover:bg-white shadow"`}>Partial Stake</button></div>

                <div className="py-2"></div>
              </div>
            </div>
          </div>
        </section>
        <div className=""></div>
        {/* <section className=" hmvs-bg-neutaral-dark mb-2 rounded shadow">

          <div className="flex flex-wrap">
            
              <div className="lg:w-1/3 px-6 mb-2 mt-2 hmvs-bg-darker rounded shadow">
                <p className="text-white font-semibold text-5xl py-10">Mining with Validators vs. Helium Routers?</p>
                <p className="text-white text-lg  mb-6">Learn more about the Helium  <a href="https://www.helium.com/" className="text-blue-400 text-xl font-semibold">here</a>. </p>
                

              </div>
              <div className="lg:w-1/3 px-4 mb-2 mt-4 ">
                <img src="images/V.png" alt="" className="bg-black  image-center" style={{width:"25vh", height: "25vh"}} ></img>
                <p className="text-white text-center font-semibold text-4xl mt-3">Validators</p>
                
                <p className="text-white text-lg mt-3  text-center rounded shadow hmvs-bg-darker py-2 px-2">300,000 HNT is split evenly amongst validators every month. Initial validator size is estimated to be less than 500. Early stakers will earn a larger % of the 300,000 HNT.</p>
                <p className="font-semibold  hmvs-text-darkAlt text-lg mt-2">Activities Rewarded</p>
                <p className="text-white text-left text-sm ">6% Consensus Groups </p>
              </div>
              <div className="lg:w-1/3 px-4 mb-2 mt-4">
                <img src="images/Helium Router.png" alt="" className=" image-center " style={{width:"25vh", height: "25vh"}}></img>
                <p className="text-white text-center font-semibold text-4xl lg:mt-3">Helium Routers</p>
                <p className="text-black mt-3 text-center text-lg rounded shadow bg-white py-2 px-2">Earnings split evenly amongst routers. There are currently over 25,000 Rotuers and many more joining the network every day - reducing each routers individual earnings. </p> 

                <p className="text-white  font-semibold text-lg mt-2">Activities Rewarded</p>
                <p className="text-white text-sm">0.95% PoC Challenger | 5.31% PoC Challenges | 21% PoC Witness </p>
              
              </div>

         

          </div>


        </section> */}


        <section className="  rounded shadow">

        <div className="flex flex-wrap ">
          
            <div className="lg:w-1/2 lg:px-24 px-6 mb-2 mt-10  ">
              <p className="hmvs-text-primary hmvs-font font-semibold text-xl mt-5">What are Validators?</p>
              <p className="text-white py-3 hmvs-font  ">Validators are a new concept on the Helium network that will take over the responsibilites of the consensus group. Prior to Validators, consensus group operations were fulfilled by Helium Routers often in peoples' homes. Ultimately, moving consensus group operations off of these routers will help the Helium Network grow and remain stable.</p>
              <div className="py-5"></div>
              <p className="hmvs-text-primary font-semibold text-xl mt-50 hmvs-font">What are Consensus Groups?</p>
              <p className="text-white py-3 hmvs-font lg:text-sm text-sm ">A consensus group is a set of elected nodes that manage the act of verifying transactions and handing out rewards. These operations are currently being done by Helium Routers but they are being moved to Validators (beefy cloud computers).</p>
              <div className="py-5"></div>
              <p className="hmvs-text-primary hmvs-font font-semibold text-xl mt-50">How do Validators mine HNT?</p>
              <p className="text-white py-3 hmvs-font lg:text-sm text-sm ">Validators mine HNT by performing the work of the consensus group (verifying transactions, adding new blocks to the BlockChain, etc.).  </p>
              <p className="text-white text-sm hmvs-font  mb-6 mt-3 ">Learn more about Helium Validators <a href="https://www.helium.com/stake" className="hmvs-text-primary text-sm hmvs-font font-semibold">here</a>. </p>
              

            </div>
            <div className="lg:w-1/2  bg-black  ">
              <img src="images/Vanager diagrams.svg" alt="" className=" image-center  "   ></img>

            </div>
            {/* <div className="lg:w-1/2 px-4 mb-2 mt-4 ">
              <img src="images/V.png" alt="" className="bg-black  image-center" style={{width:"25vh", height: "25vh"}} ></img>
              <p className="text-white text-center font-semibold text-4xl mt-3">Validators</p>
              
              <p className="text-white text-lg mt-3  text-center rounded shadow hmvs-bg-darker py-2 px-2">300,000 HNT is split evenly amongst validators every month. Initial validator size is estimated to be less than 500. Early stakers will earn a larger % of the 300,000 HNT.</p>
              <p className="font-semibold  hmvs-text-darkAlt text-lg mt-2">Activities Rewarded</p>
              <p className="text-white text-left text-sm ">6% Consensus Groups </p>
            </div>
          */}

        </div>
        </section>
        <div className="py-1 lg:-my-1"></div>
        <section>
        <div className=" flex flex-wrap">
          
          <div className="lg:w-1/2 px-6 mb-2 lg:-mb-0 bg-black py-5">
            <img src="images/Total Validators Online-2.svg" alt="" className="bg-black  image-center  "  ></img>


          </div>
          
          <div className="lg:w-1/2 px-12  lg:mt-24 mt-10  ">
            <div className="lg:py-8"></div>
            <p className="hmvs-text-primary font-semibold hmvs-font text-xl mt-50 ">How much HNT will be mined by Validators?</p>
            <p className="text-white py-3 text-sm py-3 hmvs-font">Validators have a total mining potential of <span className="hmvs-font font-bold hmvs-text-dark ">3,600,000 HNT Annually (300,000/month) </span> split between all active Validators. Early investors will see higher returns, but the returns will decrease as they are splite with more validators coming online. Initial Annual Percentage Return (APR) is estimated to be about 360% for early stakers.</p>
            <div className="py-5"></div>
            <p className="hmvs-text-primary font-semibold text-xl hmvs-font mt-50 ">What about the Helium Halving?</p>
            <p className="text-white py-3 text-sm hmvs-font  py-3">In August 2021 Helium will have a  <a href="https://docs.helium.com/blockchain/helium-token/" className="hmvs-text-primary hmvs-font ">halving</a> event that will decrease the supply of HNT created annually. Effectively, the total amount of HNT created on the chain will be cut in half, which effects the validator reward pool. The total allocation of HNT to Validators 6% will be unchanged. The impact of the halving is unknown, but other coins like Bitcoin underwent the same event and the price soared to new highs due to constrained supply and strong miner incentives. <a href="https://www.investopedia.com/bitcoin-halving-4843769" className="hmvs-text-primary">source</a> </p>
            

          </div>
          {/* <div className="lg:w-1/2 px-4 mb-2 mt-4 ">
            <img src="images/V.png" alt="" className="bg-black  image-center" style={{width:"25vh", height: "25vh"}} ></img>
            <p className="text-white text-center font-semibold text-4xl mt-3">Validators</p>
            
            <p className="text-white text-lg mt-3  text-center rounded shadow hmvs-bg-darker py-2 px-2">300,000 HNT is split evenly amongst validators every month. Initial validator size is estimated to be less than 500. Early stakers will earn a larger % of the 300,000 HNT.</p>
            <p className="font-semibold  hmvs-text-darkAlt text-lg mt-2">Activities Rewarded</p>
            <p className="text-white text-left text-sm ">6% Consensus Groups </p>
          </div>
        */}

      </div>
        </section>
        <div className="  ">

        </div>
        <section className="hmvs-bg-neutaral-dark ">
          <Estimator/>
        </section>

        <section>
          <InterestForm />
        </section>
        


        <section className="pt-8 hmvs-bg-neutaral-dark px-4 pb-4 ">
          <div className="flex flex-wrap -mx-4 text-center">
            <div className="w-full lg:w-1/3 px-4 mb-8">
              <div className="h-full pb-6 hmvs-bg-neutaral-black rounded shadow">
                <img className="mx-auto mb-4 py-4 px-4" src="images/Breland.jpg" alt="" style={{width:"300px", height: "300px"}}></img> 
                <div className="px-8">
                  <h3 className="text-xl mb-1 font-semibold font-heading font-semibold text-white hmvs-font">Breland Miley</h3>
                  <span className="text-white hmvs-font">Full Stack Engineer</span>
                  <div className="flex justify-center space-x-6 py-3 my-4 mx-12 border-t border-b">
                    <a href="mailto:info@vanager.io">
                      <svg className="hmvs-text-darkAlt w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                      </svg>
                   
                    </a>
                    <a href="https://www.linkedin.com/in/brelandmiley/"> 
                      <img className="" src="images/LI-In-Bug.png" alt="" style={{width:"30px", height: "30px"}}></img>
                    </a>
              
                  </div>
                  <p className="text-white hmvs-font">10+ years of software development at leading tech corporations.</p>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/3 px-4 mb-8">
              <div className="h-full pb-6 hmvs-bg-neutaral-black rounded shadow">
                <img className="mx-auto mb-4 py-4 px-4 rounded" src="images/tjhmvs .jpg" alt="" style={{width:"300px", height: "300px"}}></img>
                <div className="px-8">
                  <h3 className="text-xl mb-1 font-semibold font-heading font-semibold text-white hmvs-font">Anthony Scott</h3>
                  <span className="text-white hmvs-font">Engineer and Product Manager</span>
                  <div className="flex justify-center space-x-6 py-3 my-4 mx-12 border-t border-b">
                    <a href="mailto:info@vanager.io"> 
                      <svg className="hmvs-text-darkAlt w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                      </svg>
                    </a>
                    <a href="https://www.linkedin.com/in/anthony-scott-ii-9024b12b"> 
                      <img  src="images/LI-In-Bug.png" alt="" style={{width:"30px", height: "30px"}}></img>
                    </a>
   
                  </div>
                  <p className="text-white hmvs-font">8+ years leading the development of scalable services for the top 5 tech companies.</p>
                </div>
              </div>
            </div>
            {/* <div className="w-full lg:w-1/3 px-4 mb-8">
              <div className="h-full pb-6 hmvs-bg-neutaral-black rounded shadow">
                <img className="mx-auto mb-4 py-4 px-4" src="images/Jessica Perrin.jpg" alt="" style={{width:"300px", height: "300px"}}></img> 
                <div className="px-8">
                  <h3 className="text-xl mb-1 font-semibold font-heading font-semibold text-white hmvs-font">Jessica Perrin</h3>
                  <span className="text-white hmvs-font">Product Designer</span>
                  <div className="flex justify-center space-x-6 py-3 my-4 mx-12 border-t border-b">
                    <a href="mailto:info@vanager.io">
                      <svg className="hmvs-text-darkAlt w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                      </svg>
                   
                    </a>
                    <a href="https://www.linkedin.com/in/jessica-perrin/"> 
                      <img className="" src="images/LI-In-Bug.png" alt="" style={{width:"30px", height: "30px"}}></img>
                    </a>
              
                  </div>
                  <p className="text-white hmvs-font">Translating complex tech into usable experiences for everyone. </p>
                </div>
              </div>
            </div> */}


          </div>
        </section>







       
      </div>

    </div>

  )
}

export default Landing
