import React from "react";
import ArticleFormat from "./articlerepository";

const article = (<div>
     <p className="hmvs-font mt-8 hmvs-text-primary text-xl font-bold">Which staking option is right for you?</p>
        <p className="hmvs-font text-white">Vanager provides 3 options for users to participate in Helium validators which are Full Stake, Private Group Stake, and Public Group Stake. </p>
        <div className="px-2 mt-3 mb-2">
            <p className="hmvs-text-primary hmvs-font"> Full Stake</p>
            <p className="hmvs-font text-white">Are for an individual who wants to stake an entire validator by alone. </p>

        </div>
        <div className="px-2 mt-3 mb-2">
            <p className="hmvs-text-primary hmvs-font">Partial Stake - Private</p>
            <p className="hmvs-font text-white"> Are for a group of individuals that want to stake a Helium validator together. There is no minimum or maximum number of group members but each member has to deposit a minimum 100 HNT. Group members can invite others to their validator by sharing the group code that is unique to their validator.</p>

        </div>
        <div className="px-2 mt-3 mb-2">
            <p className="hmvs-text-primary hmvs-font">Partial Stake - Public</p>
            <p className="hmvs-font text-white"> Are open to all Vanager members. This is good for members looking to join any available validators that are looking for contributors. </p>

        </div>
        
        
</div>) 

export const WhichStakingOptionIsRightForYou: React.FunctionComponent<{  }> = () => {
    return (
        <div className=" ">
      
        <ArticleFormat article={article} ></ArticleFormat>

 
        
    
      </div>
    )
  }

export default WhichStakingOptionIsRightForYou;