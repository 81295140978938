import { protoTypes } from "@hlmvs/hlmvs-client";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import { Helpers } from "../libs/helpers";
import {Tooltip} from '@material-ui/core'; 

export const DepositInstructionsInnerContent: React.FunctionComponent<{ stakeInstance?: protoTypes.StakeInstance }> = (props) => {
    
    return (

        <div className="sm:flex sm:items-start">
            {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <img alt="Vanager Logo" src="images/V.png" className="mx-16 rounded"></img>
            </div> */}
            <div className="mt-3 text-center sm:mt-0  sm:text-left">
                <p className="hmvs-text-primary text-left  text-xl">Deposit Instructions </p>

                <div className="px-6 py-3 lg:px-5 lg:py-3 lg:mx-12 md:mx-12 mt-5 bg-white">
                    <QRCode 
                        value={props.stakeInstance?.getCallerownerrecord()?.getOwnerstakedepositwalletaddress()?.getAddress().toString() || ""}
                        key={(props.stakeInstance?.getCallerownerrecord()?.getOwnerstakedepositwalletaddress()?.getAddress().toString() || "") + "QR"}
                    />


                </div>
                <div className="mt-3">
                    <p className="text-white text-left  lg:text-xl">Please Send: <span className="hmvs-text-primary">{Helpers.getHntFromHeliumAmount(props.stakeInstance?.getCallerownerrecord()?.getStakedamount()) + .5} HNT</span></p>
                    <div className="flex flex-wrap">
                        <p className="text-white text-left lg:text-xl "> Wallet Address: <button className="shadown hmvs-bg-primary inline rounded leading-6 lg:text-lg text-white hover:bg-black" id="">
                            <Tooltip title="copy"><CopyToClipboard text={props.stakeInstance?.getCallerownerrecord()?.getOwnerstakedepositwalletaddress()!.getAddress().toString() || ""}
                            >
                                <button onClick={(e) => (e.preventDefault())}><p className="px-2 text-s lg:text-xl">
                                    {Helpers.elippsify(props.stakeInstance?.getCallerownerrecord()?.getOwnerstakedepositwalletaddress()?.getAddress().toString() || "", 6, 6)}
                                </p></button>
                            </CopyToClipboard>
                            </Tooltip>

                        </button>
                        </p>
                        <img src="images/copyicon.svg" alt="copy" className="inline-block" style={{ width: "25px", height: "25px" }}></img>
                    </div>
                </div>

               
                <p className="text-yellow-500 font-bold mt-4">Your validator will not start until a total of 10,000 HNT has been deposited</p>
                <p className="bg-red-500 text-black py-2 px-2 text-left font-bold mt-4 text-sm rounded shadow">Important: Do not send funds from an Exchange wallet (eg. binance, bilaxy, etc) - only transfer your total stake amount from your Helium App Wallet. If you use and exchange wallet you may not be able to receive earnings or refurnd your original stake. If you have already used an exchange wallet please contact the Vanager team.</p>

                <p className="text-white text-sm mt-3 text-left">Instructions</p>
                <p className="text-white text-sm text-left">1. Open Helium App.</p>
                <p className="text-white text-sm text-left">2. Go to "My Wallet" Page.</p>
                <p className="text-white text-sm text-left">3. Click QR Code Icon in the top right corner and scan the QR code from Vanager. Alternatively you can click the "Send HNT" button and copy the Wallet Address.</p>
                <p className="text-white text-sm text-left">4. Enter <span className="hmvs-text-primary">{Helpers.getHntFromHeliumAmount(props.stakeInstance?.getCallerownerrecord()?.getStakedamount()) + .5}</span>  in the "Amount (HNT)" field (Extra .5 HNT for transfer fees)</p>
                <p className="text-white text-sm text-left">5. Click "Send HNT"</p>
                <p className="text-white text-sm text-left">6. Once received, we'll stake your funds and start your validator.</p>


            </div>
        </div>
    )
}

export default DepositInstructionsInnerContent;