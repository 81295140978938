// import HeliumExplorerStats from "./HeliumExplorerStats";
import ValidatorLeaderBoards from "./ValidatorLeaderBoards";

export const ValidatorStatsMain: React.FunctionComponent<{  }> = () => {
    return (
      <div>
          {/* <p>Validator Stats </p> */}
          {/* <section>
            <HeliumExplorerStats></HeliumExplorerStats>
          </section> */}
          <section className="mt-3">
              <ValidatorLeaderBoards></ValidatorLeaderBoards>
          </section>
          <section></section>
          <section></section>
          <section></section>
      </div>
    )
  }

export default ValidatorStatsMain;