import { Client } from '@helium/http';
import { Helpers } from './helpers';


export interface customerInput{
    stakeAmount: number; 
    validatorsOnline: number;
  
}

export enum BuyDecision {
    ROUTER,
    VALIDATOR
}



export interface customerEarnings{
    dailyHNT: number;
    dailyServiceFeeUSD: number; 
    dailyNetEarningsUSD: number; 
    dailyServerCostUSD: number; 
    dailyGrossEarningsUSD: number;

    dailyServiceFeeHNT: number; 
    dailyNetEarningsHNT: number; 
    dailyServerCostHNT: number; 
    dailyGrossEarningsHNT: number; 
    userStakeAmout: number; 

    annualHNT: number;
    annualServiceFeeUSD: number;
    annualNetEarningsUSD: number;
    annualServerCostUSD: number;
    annualGrossEarningsUSD: number; 
    userStakeAmountUSD: number; 
    
    annualServiceFeeHNT: number; 
    annualNetEArningsHNT: number; 
    annualServerCostHNT: number; 
    annualGrossEarningsHNT: number; 
    annualReturnInvestment: number; 

    validatorsOnline: number; 
    validatorDecision: BuyDecision; 

    oraclePrice: number; 

    totalValueDailyUSD: number;
    totalValueAnnuallyUSD: number;
    totalValueDailyHNT: number; 
    totalValueAnnuallyHNT: number;

}

export class StakeRewards{

    private readonly fullStakeAmount = 10000;


    hntToDollarsPromise: Promise<number>;

    constructor(heliumClient: Client, oracleOveride?: number){
        
        if (oracleOveride !== undefined){
            this.hntToDollarsPromise = Promise.resolve(oracleOveride);
        }else{
            this.hntToDollarsPromise = heliumClient.oracle.getCurrentPrice().then(oricalPrice => {
                if (oricalPrice && oricalPrice.price) {
                    return oricalPrice.price.toUsd().integerBalance / Helpers.BONES_IN_ONE_HNT;
                } else {
                    return Promise.reject("No price");
                }
        });
    }
        

    }

    public getStakeAmount(input: customerInput): number{
        return input.stakeAmount; 
    } 

    public getPercentageOwernship(input: customerInput): number {
        let ownershipPercentage = input.stakeAmount/this.fullStakeAmount; 
        return ownershipPercentage;
    }

    public getDailyHntEarnings(input: customerInput): number {
        let hntPerValidatorPerMonth = 300000/input.validatorsOnline; 
        let dailyEarnings: number | undefined = undefined; 
        dailyEarnings = (hntPerValidatorPerMonth/30)*this.getPercentageOwernship(input);
        return dailyEarnings;
    }

    public getDailyUSDEarnings(input: customerInput): Promise<number> {
        return this.hntToDollarsPromise.then(hntToDollars => {
            let dailyEarnings = this.getDailyHntEarnings(input); 
            let earningsDollars = hntToDollars * dailyEarnings;
            return earningsDollars;
        });
    }

    public getDollarsToHNT(input: number | Promise<number>): Promise<number>{
        let promisedInput: Promise<number>;
        if (typeof input === 'number') {
            promisedInput = Promise.resolve(input);
        } else {
            promisedInput = input;
        }
        return Promise.all([this.hntToDollarsPromise, promisedInput]).then(results => {
            return results[1]/results[0]; 
        });
   
        
    }

    

    public getServiceFeeDailyUSD(input: customerInput): Promise<number> {
        return this.getDailyUSDEarnings(input).then(dailyEarnings =>{
            let serviceFee = dailyEarnings * 0.03 * this.getPercentageOwernship(input);
            return serviceFee; 
        })
    }

    public getNetEarningsUSD(input: customerInput): Promise<number> {
       return Promise.all([this.getDailyUSDEarnings(input), this.getServiceFeeDailyUSD(input)]).then(results => {
            let netEarnings = results[0] - results[1] - this.getServerFee(input); 
            return netEarnings; 
        });

    }  

    public getServerFee(input: customerInput): number{
        let serverFee = 4 *input.stakeAmount/10000; ; 
        return serverFee; 
    }
//Comparing the daily earnings to validator daily earnings 
//if validator earnings are < router daily earnings = better to buy router else validator is better 
    public getRouterComp(input: customerInput): Promise<BuyDecision>{
        return Promise.all([this.hntToDollarsPromise,this.getNetEarningsUSD(input)]).then(results =>{
            let DailyRtrEarningsHnt : number = 2; 
            let DailyRtrEarningsUSD = DailyRtrEarningsHnt * results[0];
            if (DailyRtrEarningsUSD > results[1]) {
                return BuyDecision.ROUTER
            } else {
                return BuyDecision.VALIDATOR
            }
        })

    }
//minimum hnt stake amount to get a better daily return (router daily HNT > validator daily HNT )
    public minstake(input: customerInput): Promise<number>{
        return Promise.all([this.hntToDollarsPromise,this.getNetEarningsUSD(input)]).then(results =>{
            // let DailyRtrEarnings : number = 10; 
            let routerPrice: number = 350; 
            let stakeamountCon: number = input.stakeAmount * results[0]; 
            for (let index = results[1]; index < results[1]; index++) {
               
                if (stakeamountCon < routerPrice) {
                    input.stakeAmount++;
                    console.log(stakeamountCon)
                    console.log(routerPrice)
                } else {
                    let hntstake = stakeamountCon/results[0];
                    console.log("This is the break even" + hntstake); 
                    break; 
                }
               
                
            }
            return input.stakeAmount; 
        })
        
; 
    }
    
    public getestimatedEarnings(input: customerInput): Promise <customerEarnings> {
        return Promise.all([this.getServerFee(input),
             this.getDailyHntEarnings(input),
              this.getServiceFeeDailyUSD(input),
               this.getNetEarningsUSD(input),
                this.getDailyUSDEarnings(input),
                 this.getRouterComp(input),
                  this.hntToDollarsPromise,
                   this.getStakeAmount(input),]
                   ).then(results=>{
            let serverFeeUSD = results[0]; 
            let userHNTEarnings = results[1]; 
            let dailyServiceFeeUSD = results[2]; 
            let userNetEarningsUSD = results[3];
            let grossEarningsUSD = results[4];
            let stakeAmountHNT = results[7];
        

            let serverFeeDailyHNT = this.getDollarsToHNT(serverFeeUSD);
            let userEarningsDailyHNT = this.getDollarsToHNT(userHNTEarnings);
            let dailyServiceFeeHNT = this.getDollarsToHNT(dailyServiceFeeUSD); 
            let userNetEarningsHNT = this.getDollarsToHNT(userNetEarningsUSD);
            let dailyGrossEarningsHNT = this.getDollarsToHNT(grossEarningsUSD); 
    
            let annualNetEarningsUSD = userNetEarningsUSD*365;
            let annualServiceFeeUSD = dailyServiceFeeUSD*365;
            let annualHNTEarnings = userHNTEarnings*365; 
            let annualserverFeeUSD = serverFeeUSD*365; 
            let annualgrossEarningsUSD = grossEarningsUSD*365; 
            
            let annualNetEarningsHNT = this.getDollarsToHNT(annualNetEarningsUSD);
            let annualServiceFeeHNT = this.getDollarsToHNT(annualServiceFeeUSD);
            let annualserverFeeHNT = this.getDollarsToHNT(annualserverFeeUSD); 
            let annualgrossEarningsHNT = this.getDollarsToHNT(annualgrossEarningsUSD);
            
            let userStakedAmountUSD = stakeAmountHNT * results[6]; 


            
            
            let ValidatorsOnline = input.validatorsOnline; 
            let validatorDecision = results[5];

            return Promise.all([
                    serverFeeDailyHNT, userEarningsDailyHNT, dailyServiceFeeHNT, dailyGrossEarningsHNT, 
                    annualNetEarningsHNT, annualServiceFeeHNT, annualserverFeeHNT, annualgrossEarningsHNT, userNetEarningsHNT ]).then(resultsInHNT => {
                        let annualROI = resultsInHNT[4]/results[7]*100;
                return {
                    dailyHNT: userHNTEarnings, 
                    dailyNetEarningsUSD: userNetEarningsUSD,
                    dailyServerCostUSD: serverFeeUSD,
                    dailyServiceFeeUSD: dailyServiceFeeUSD,
                    dailyGrossEarningsUSD: grossEarningsUSD,
                    
                    dailyNetEarningsHNT: resultsInHNT[8],
                    dailyServerCostHNT: resultsInHNT[0], 
                    dailyServiceFeeHNT: resultsInHNT[2],
                    dailyGrossEarningsHNT: resultsInHNT[3], 
    
                    annualGrossEarningsUSD: annualgrossEarningsUSD,
                    annualHNT: annualHNTEarnings,
                    annualServerCostUSD: annualserverFeeUSD,
                    annualServiceFeeUSD: annualServiceFeeUSD,
                    annualNetEarningsUSD: annualNetEarningsUSD,
                    
                    annualGrossEarningsHNT: resultsInHNT[7],
                    annualServerCostHNT: resultsInHNT[6],
                    annualServiceFeeHNT: resultsInHNT[5],
                    annualNetEArningsHNT: resultsInHNT[4], 
                    annualReturnInvestment: annualROI, 
                    validatorsOnline: ValidatorsOnline,
                    validatorDecision: validatorDecision, 
                    oraclePrice: results[6],
                    userStakeAmout: stakeAmountHNT,
                    userStakeAmountUSD: userStakedAmountUSD,
                    totalValueDailyUSD: userStakedAmountUSD+ userNetEarningsUSD,
                    totalValueAnnuallyUSD: userStakedAmountUSD+annualNetEarningsUSD,
                    totalValueDailyHNT: stakeAmountHNT+resultsInHNT[8],
                    totalValueAnnuallyHNT: stakeAmountHNT+resultsInHNT[4]
                };
            });
            
        })
    }
}


