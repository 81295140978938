// const StakeInstanceCard: React.FunctionComponent<{
//     stakeInstance: protoTypes.StakeInstance;
//   }> = (props) => {
//     const authContext = useContext(AuthContext);
//     const [hideDepositWallet, setHideDepositWallet] = useState(true);
//     const [showQRCode, setShowQRCode] = useState(false);
//     const [showGroupCode, setshowGroupCode] = useState(false);
//     const [showUnstake, setShowUnstake] = useState(false);
//     const [unstaking, setUnstaking] = useState(false);
//     const [unstakingError, setUnstakingError] = useState(false);
//     const [stakeInstance] = useState(props.stakeInstance);
//     const [doesUserStillOweMoney, setDoesUserStillOweMoney] = React.useState(false);
//     const [userStillOwesAmountHnt, setUserStillOwesAmountHnt] = React.useState(0.0);


import * as React from "react";
import { Helpers } from "../libs/helpers";
import { protoTypes,  } from "@hlmvs/hlmvs-client";
import { AuthContext,  } from "../contexts/authContext";
import { useContext, useState } from "react";
import UserPaymentComp from "./UserPaymentComp";

export const UserPayments: React.FunctionComponent<{
    stakeInstance: protoTypes.StakeInstance;
}> = () => {

    const authContext = useContext(AuthContext);

    const [stakedInstances, setStakedInstances] = useState(new Map<string, protoTypes.StakeInstance>());


    const refreshStakedInstances = () => {
        // setLoading(true);
        Helpers.listStakedInstances(authContext).then(instancesThisUserOwns => {
            var stakedMap = new Map<string, protoTypes.StakeInstance>();
            instancesThisUserOwns.getStakeinstancesList().forEach(stakeInstance => {
                stakedMap.set(stakeInstance.getStakeinstanceid(), stakeInstance);
            });
            // console.log(`Fetched ${stakedMap.size} staked instances via list: ${stakedMap}`);
            setStakedInstances(stakedMap);
            // setLoading(false);
            // setLoadError(false);
        }).catch(err => {
            console.log(err);
            // setLoadError(true);
            // setLoading(false);
        });
    }



    React.useEffect(() => {
        refreshStakedInstances();
        // eslint-disable-next-line
    }, [authContext]);
    return (
        <div>
            <p className="text-white text-3xl">Payments</p>
            <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
        {Array.from(stakedInstances.values()).map(e => {
            return (
                
                <UserPaymentComp key={e.getStakeinstanceid()} stakeInstance={e} />
            );
        })}
        </div>
    )

}

export default UserPayments