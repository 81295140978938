import * as React from "react";
import { Helpers } from '../libs/helpers'
// import StakeInstanceCard from "../components/stakeInstanceCard";
import { protoTypes } from '@hlmvs/hlmvs-client'
import { AuthContext, } from "../contexts/authContext";
import { useState, useContext } from "react";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
// import Modal from 'react-bootstrap/Modal'
// import { useHistory } from 'react-router-dom'
// import { makeStyles } from '@material-ui/core/styles'
// import Button from 'react-bootstrap/Button';
// import ChartComponent from "../components/ProfileCharts";
import { LineData, WhitespaceData } from "lightweight-charts";
import NumberFormat from "react-number-format";
import SetupFlow from "../components/setupflow";
import {Tooltip} from '@material-ui/core'
// import Ticker, { NewsTicker } from "@hlmvs/nice-react-ticker";
// import UserProfileBreakdown from '../components/UserProfileBreakdown'
import StakeInstanceCardPrivate from "../components/stakeInstanceCardPrivate";
import StakeInstanceCardLaunched from "../components/stakeInstanceCardLaunched";
import * as qs from 'query-string';
// import Client, { Network } from "@helium/http";
// import { promises } from "fs";


// const useStyles = makeStyles((theme) => ({
//     root: {},
//     title: {
//       textAlign: 'center',
//     },
//     session: {
//       width: '80vw',
//       overflow: 'auto',
//       overflowWrap: 'break-word',
//       fontSize: '16px',
//     },
//     hero: {
//       width: '100%',
//       background: 'rgb(220,220,220)',
//     },
//   }))

const UserProfile: React.FunctionComponent = () => {

    // const heliumClient = Helpers.useTestnet() ? new Client(Network.testnet) : new Client();



    const [stakedInstances, setStakedInstances] = useState(new Map<string, protoTypes.StakeInstance>());
    const [pledgedInstances, setPledgedInstances] = useState(new Map<string, protoTypes.StakeInstance>());
    const [LaunchedInstances, setLaunchedInstances] = useState(new Map<string, protoTypes.StakeInstance>());

    const [showNewStakedInstance, setShowNewStakedInstance] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadError, setLoadError] = useState(false);
    // const [dailyEarnings, setDailyEarnings] = useState(false);
    // const [breakDown, setBreakdown] = useState(false); 
    const [chartData, setChartData] = useState(new Array<LineData | WhitespaceData>());
    const [joiningGroup, setJoiningGroup] = useState(false);
    const [fullStake, setFullStake] = React.useState(false);
    const [partialStake, setPartialStake] = React.useState(false);
    const [needsRefresh, setNeedsRefresh] = React.useState(false);
    const [rk, setRk] = React.useState("");
    // const [backendIsOutOfSync, setBackendIsOutOfSync] = React.useState(false);


    // const classes = useStyles()

    // const history = useHistory()


    // function changePasswordClicked() {
    //     history.push('changepassword')
    // }
    const authContext = useContext(AuthContext);


    const refreshStakedInstances = () => {
        setLoading(true);
        let viewAs = undefined;
        if (window.location.search && qs.parse(window.location.search)) {
            var parsed = qs.parse(window.location.search);
            var key = 'viewAs';
            if (Object.keys(parsed).includes(key)) {
                viewAs = parsed[key];
                if (viewAs === null) {
                    viewAs = undefined;
                }
                if (Array.isArray(viewAs)) {
                    viewAs = viewAs[0];
                }
            }
        }
        Helpers.listStakedInstances(authContext, viewAs).then(instancesThisUserOwns => {
            var stakedMap = new Map<string, protoTypes.StakeInstance>();
            var launchedMap = new Map<string, protoTypes.StakeInstance>();
            var pledgedMap = new Map<string, protoTypes.StakeInstance>();
            var userDepositWallets: string[] = []
            instancesThisUserOwns.getStakeinstancesList().forEach(stakeInstance => {
                stakedMap.set(stakeInstance.getStakeinstanceid(), stakeInstance);
                if (stakeInstance.getStakeinstancestate() === protoTypes.StakeInstanceState.STAKE_INSTANCE_STATE_COOLDOWN || stakeInstance.getStakeinstancestate() === protoTypes.StakeInstanceState.STAKE_INSTANCE_STATE_LIVE || stakeInstance.getStakeinstancestate() === protoTypes.StakeInstanceState.STAKE_INSTANCE_STATE_PENDING_STAKE_VALIDATION || stakeInstance.getStakeinstancestate() === protoTypes.StakeInstanceState.STAKE_INSTANCE_STATE_PAYMENTS_RECEIVED_PENDING_SETUP) {
                    launchedMap.set(stakeInstance.getStakeinstanceid(), stakeInstance);
                } else if (stakeInstance.getStakeinstancestate() === protoTypes.StakeInstanceState.STAKE_INSTANCE_STATE_INITIAL || stakeInstance.getStakeinstancestate() === protoTypes.StakeInstanceState.STAKE_INSTANCE_STATE_PENDING_PAYMENTS) {
                    pledgedMap.set(stakeInstance.getStakeinstanceid(), stakeInstance);
                }
                userDepositWallets.push(stakeInstance.getCallerownerrecord()?.getOwnerstakedepositwalletaddress()?.getAddress() || "")
            });
            // heliumClient.vars.get().then(vars => {
                
            //     const proms = userDepositWallets.map(addr => {
            //         if (addr === "") {
            //             return Promise.resolve(0);
            //         }
            //         heliumClient.accounts.get(addr).then(a => {
            //             var r = (a.balance?.integerBalance || 0) /  Helpers.BONES_IN_ONE_HNT;
            //             console.log(`Bal for ${a.address} is ${r} HNT`);
            //             return r;
            //         }).catch(e => {
            //             return 0;
            //         });
                    
            //     });
            //     return Promise.all(proms).then(dones => {
            //             return dones.reduce((accumulator, currentValue, currentIndex, array) => {
            //                 return (accumulator || 0) + (currentValue || 0)
            //             }, 0);
            //         }).then(bal => {
            //             const ourBalance = getTotalDepositAmountHnt(instancesThisUserOwns.getStakeinstancesList());
            //             console.log(`Balance according to helium: ${bal}, our balance: ${ourBalance}`)
            //             if (ourBalance !== bal) {
            //                 setBackendIsOutOfSync(true);
            //             }
            //         })
                
            // });
            console.log(`Fetched ${stakedMap.size} staked instances via list: ${stakedMap}`);
            setChartData([
                { time: '2019-04-11', value: 80.01 },
                { time: '2019-04-12', value: 96.63 },
                { time: '2019-04-13', value: 76.64 },
                { time: '2019-04-14', value: 81.89 },
                { time: '2019-04-15', value: 74.43 },
                { time: '2019-04-16', value: 80.01 },
                { time: '2019-04-17', value: 96.63 },
                { time: '2019-04-18', value: 76.64 },
                { time: '2019-04-19', value: 81.89 },
                { time: '2019-04-20', value: 74.43 },
            ])
            console.log(chartData);
            setStakedInstances(stakedMap);



            setPledgedInstances(pledgedMap);
            setLaunchedInstances(launchedMap);
            setLoading(false);
            setLoadError(false);
        }).catch(err => {
            console.log(err);
            setLoadError(true);
            setLoading(false);
        });
    }



    React.useEffect(() => {
        refreshStakedInstances();
        // eslint-disable-next-line
    }, [authContext]);

    // const handleEarningDuration = (e: React.FormEvent) => { 
    //     if (e.target && (e.target as any).value === "daily") {
    //         setDailyEarnings(true);
    //     } else {
    //         setDailyEarnings(false);
    //     }
    //     // if (e.target && (e.target as any).value === "daily") {
    //     //     this.setState({output: undefined, dailyEarnings: true, inceptionEarnings: false, newStake: false});
    //     // } else {
    //     //     this.setState({output: undefined, dailyEarnings: false, inceptionEarnings: true, newStake: false});
    //     // }
    //     // Helpers.getEarnings((e.target).value)
    // }   

    const handleNewStakeClicked = (e: React.FormEvent) => {
        setRk(Math.random().toString(36).substring(7));
        setFullStake(false);
        setPartialStake(false);
        setJoiningGroup(false);
        setShowNewStakedInstance(true);
    }

    const getTotalDepositAmountHnt = (stakedInstances: protoTypes.StakeInstance[]) => {
        const depositAmounts = stakedInstances.filter(si => si.getStakeinstancestate() !== protoTypes.StakeInstanceState.STAKE_INSTANCE_STATE_TERMINATED).map(si => Helpers.getHntFromHeliumAmount(si.getCallerownerrecord()?.getDepositwalletamount()));
        let totalHnt = 0;
        depositAmounts.forEach(amount => {
            totalHnt += amount;
        })
        return totalHnt;
    }

    // const handleBreakdown = () => {
    //     if (breakDown === true) {
    //         setBreakdown(false); 
    //     }else
    //     {
    //         setBreakdown(true); 
    //     }
        
    // };


    // const validatorSaveSuccessful = () => {
    //     refreshStakedInstances()
    //     // setShowNewStakedInstance(false);
    // }

    const addValidatorModalConent = (<SetupFlow rk={rk} showModal={showNewStakedInstance} joiningPrivate={joiningGroup} fullStaker={fullStake} newPartial={partialStake} onSaveSuccessful={() => { setNeedsRefresh(true); }} onClose={() => {if (needsRefresh) { refreshStakedInstances(); setNeedsRefresh(false);} setShowNewStakedInstance(false); }} />)

    const errorContent = (
        <div hidden={!loadError} className="flex flex-wrap -mx-4 text-white items-center place-self-center">
            <p className="hmvs-font">We had trouble loading your data - try refreshing the page</p>
        </div>
    );
    const loadContent = (
        <div hidden={!loading} className="grid grid-cols-3   py-12 text-white ">
            <div></div>
            <div className=""><p className="px-0 hmvs-font " hidden={!loading} >Just a second while we load your data</p><div className="py-5"></div><div className="lg:px-32 px-14"><ClimbingBoxLoader  loading={loading} color="white" /></div></div>
            <div></div>
        </div>
    )

    const emptyConent = (
        <div>
            <section className="">
                <div className="flex flex-wrap  lg:px-9  py-20 mx-auto">

                    

                    <div className=" md:w-1/2 p-4 mb-2 md:mb-0 ">
                        <div className="py-3 px-6 border ">

                            <img src="images/FULL STAKE.svg" alt="" className="px-14 image-center mt-9" ></img>
                            <h3 className="text-2xl mb-4 font-bold font-heading  hmvs-text-primary ">Full Stake</h3>
                            <p className=" text-white leading-relaxed hmvs-font text-sm">For individuals that have 10,000 HNT. Full stakers retain all earnings and awards minus operational cost.  </p>
                            <div className="py-2 lg:py-0"></div>
                            <p className="mb-3 font-semibold text-md  font-bold text-white hmvs-font mt-5">Includes</p>
                            <ul className="mb-8 list-inside list-disc text-gray-400">
                                <li className="mb-1 text-white hmvs-font">Own an entire Validator!</li>
                                <li className="mb-1 text-white hmvs-font">Instant Payout</li>
                                <li className="mb-1 text-white hmvs-font">Earnings and Validator Health Dashboard</li>
                                <li className="mb-1 text-white hmvs-font">Low Operational Cost:<p className="text-sm text-white hmvs-font">$4/day Operational Expense + 3% Service Fee (on earnings)</p></li>

                            </ul>
                            <div className="py-12"></div>
                            <button className="ml-24 lg:ml-40 w-2/5 hmvs-button-primary hover:bg-white  rounded shadow " onClick={() => { setJoiningGroup(false); setFullStake(true); setShowNewStakedInstance(true); }}>Full Stake</button>
                            <div className="py-8"></div>
                        </div>
                    </div>
                    <div className=" md:w-1/2 p-4 mb-2 md:mb-0">
                        <div className="py-3 px-6 border">

                            <img src="images/PARTIAL STAKE.svg" alt="" className="px-14 image-center mt-9" ></img>
                            <h3 className="text-2xl mb-4 font-bold font-heading  hmvs-text-primary hmvs-font ">Partial Stake</h3>
                            <p className="text-white leading-relaxed text-sm hmvs-font">For individuals that have less than 10,000 HNT. Users can easily split a validator amongst other Vanager users. Rewards and fees are split proportionally among a single validator's earnings. (No Pooling)  </p>
                            <p className="mb-3 font-semibold text-md  font-bold text-white mt-5 hmvs-font">Includes</p>
                            <ul className="mb-8 list-inside list-disc text-gray-400">
                                <li className="mb-1 text-white hmvs-font">Create private validator with split ownership</li>
                                <li className="mb-1 text-white hmvs-font">Instant Payout</li>
                                <li className="mb-1 text-white hmvs-font">Earnings and Validator Health Dashboard</li>
                                <li className="mb-1 text-white hmvs-font">Low Operational Cost: <p className="text-sm text-white hmvs-font">$4/day Operational Expense + 3% Service Fee (on earnings)</p> </li>
                            </ul>
                            <div className="py-9"></div>
                            <div className="ml-12 flex flex-wrap lg:ml-24 ">
                                <Tooltip title="Click here to start a private or public group">
                                    <button  value="" onClick={() => { setJoiningGroup(false); setFullStake(false); setPartialStake(true); setShowNewStakedInstance(true) }} className={` w-2/5    hmvs-button-primary hover:bg-white  rounded shadowd  "`}>Start Group</button>

                                </Tooltip>
                                <div className="p-1"></div>
                                <Tooltip title="Click here if you have a group code to join a group">
                                    <button  className="w-2/5 hmvs-button-secondary hover:bg-gray-600 shadow rounded  " onClick={() => { setJoiningGroup(true); setFullStake(false); setPartialStake(false); setShowNewStakedInstance(true) }}>Group Code</button>

                                </Tooltip>
                                
                             
                            </div>
                            <p className="text-white mt-4 lg:ml-5 text-center hmvs-font text-lg ">Don't have a group? Join a public group <a href="#/publicGroups" className="hmvs-text-primary">here</a></p>
                            <div className="py-3"></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )

    const normalContent = (
        <div data-section-id="9" data-category="__structures" data-component-id="bf2f_23_01_awz" data-custom-component-id="41929" hidden={loading}>
            {/* <section className="">
            <Ticker isNewsTicker={true}>
                <NewsTicker id={2}  title="Validators are currently being tested on the test net. Check the Helium website" url="https://www.helium.com/stake" meta=""></NewsTicker>
                <NewsTicker id={2}  title="Deposit amounts display are delayed 3-4 hours. Check Discord for updates" url="https://discord.gg/dxD9yu9ssc" meta=""></NewsTicker>

            </Ticker>  


            </section> */}
          
            <section className="mt-2 mb-2 rounded shadow-2xl" hidden={loading && !loadError}>
                <div className="flex flex-wrap">
                    <div className=" px-4 lg:mb-0 ">
                        <div className="flex w-full flex-wrap">
                            
                            <p className="mx-4 text-2xl w-full lg:text-4xl hmvs-font text-white">Staked Amount</p>
       
                        </div>
                        <div className="flex flex-wrap">
                            <div>
                                <p className="lg:text-9xl mx-4 text-5xl hmvs-font text-white"><NumberFormat displayType={'text'} thousandSeparator={true} value={getTotalDepositAmountHnt(Array.from(stakedInstances.values())) }  /><span className="hmvs-text-primary text-xl hmvs-font ">HNT</span></p>
                                <div className="flex flex-wrap">
                                    {/* <div>
                                        <p className="text-lg lg:text-2xl font-semibold font-heading font-semibold hmvs-text-dark">+$30,000 (12.5%)</p>
                                    </div> */}
                                    {/* <div className ="px-4">
                                            <button onClick={handleBreakdown} value="breakdown" className={`  text-sm  leading-none hmvs-text-dark  font-semibold hover:bg-white`}>See Breakdown  </button>
                                        </div> */}
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>


                    </div>
                    {/* <div className="w-1/2 sm:w-1/2 lg:w-1/3  px-4 lg:mb-0">
                          <p className ="text-2xl">Total Value</p>
                          <p className="text-7xl font-semibold font-heading font-semibold">$130,000</p><span className="text-lg font-semibold font-heading font-semibold text-green-500">+$30,000 (12.5%)</span>
                      </div> */}


                </div>



            </section>
           


         

            {/* <section className="py-6 mx-24 px-16 hmvs-bg-neutaral-dark mb-10 " hidden={!breakDown}> */} 

            {/* <section className="bg-black rounded shadow" hidden={!breakDown}>
                <UserProfileBreakdown/>
            </section> 
            */}



             {/* </section> 

            {/* <section className="px-16 mb-6 mx-auto">
                        <p className="font-semibold font-headin text-white text-2xl">Value Over Time</p>
                        <ChartComponent data={chartData}/>
                </section> */}

            <section className="mt-2">

                <div className="lg:px-5 flex flex-wrap grid grid-cols-3">
                    <div className="col-span-2">
                        <p className="font-semibold ml-2 mt-2 text-xl hmvs-font text-white">Launched Validators</p>
                    </div>
                    <div className="text-white mx-auto">
                        {/* {LaunchedInstances.size>0? (Array.from(LaunchedInstances.values()) as protoTypes.StakeInstance[]).sort((v1, v2) => { return v2.getCreated()!.getSeconds() - v1.getCreated()!.getSeconds() }).map((value: protoTypes.StakeInstance) => {
                            return <StakeInstanceCard stakeInstance={value} key={value.getStakeinstanceid()} onUnstake={refreshStakedInstances} />
                        }):  */}
                        <button onClick={handleNewStakeClicked} value="stake" style={{height: "40px", width:"80px"}} className={`ml-4 lg:ml-32  hmvs-button-primary hover:bg-white  rounded shadow`}>+ Add New</button>
                    </div>
                    
                
                </div>

                <div className="fflex flex-wrap hmvs-font px-4 mt-5">
                    {LaunchedInstances.size>0? (Array.from(LaunchedInstances.values()) as protoTypes.StakeInstance[]).sort((v1, v2) => { return v2.getCreated()!.getSeconds() - v1.getCreated()!.getSeconds() }).map((value: protoTypes.StakeInstance) => {
                        return <StakeInstanceCardLaunched stakeInstance={value} key={value.getStakeinstanceid()} onUnstake={refreshStakedInstances} />
                    }): 
                    <div className="w-full" style={{maxHeight:"100px", minHeight:"100px"}}>
                       
                        
                        <div className="lg:grid lg:grid-cols-3  mt-2">
                            <div></div>
                            <div><p className="lg:ml-5 ml-6 text-white center hmvs-font mt-5" >You don't have any Launched Validators</p> 
                                {/* <button onClick={handleNewStakeClicked} value="stake" style={{height: "40px", width:"80px"}} className={`ml-32 mt-3 hmvs-font center text-sm  leading-none text-black hmvs-bg-dark  rounded shadow`}>+ Add New</button> */}
                                </div>
                            <div></div>
                        </div>
                    </div>
                    
                    }

                    
                </div>

                <div className="lg:px-5 mt-5">
                    
                    <p className="font-semibold ml-2 hmvs-font text-xl text-white">Pledged Validators</p>
                
                </div>

                <div className="flex flex-wrap hmvs-font px-4 mt-5">
                    {(Array.from(pledgedInstances.values()) as protoTypes.StakeInstance[]).sort((v1, v2) => { return v2.getCreated()!.getSeconds() - v1.getCreated()!.getSeconds() }).map((value: protoTypes.StakeInstance) => {
                        return <StakeInstanceCardPrivate stakeInstance={value} key={value.getStakeinstanceid()} onUnstake={refreshStakedInstances} />
                    })}
                </div>

            </section>
            <section className="px-2 hmvs-bg-neutaral-dark">
                <div className="py-2"></div>
                <p className="font-semibold  hmvs-font text-2xl text-white mt-2 ">Helpful Articles</p>
                <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
     
                <div className="mt-2 py-2"><a href="/#/HowToGuide" className="hmvs-font text-base text-white  hover:text-green-400 lg:text-2xl ">How to stake a Helium Validator with Vanager?</a></div>
                <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
                <div className="mt-2 py-2"><a href="/#/WhichStakingOptionIsRightForYou" className="hmvs-font text-base text-white  hover:text-green-400 lg:text-2xl">Which Staking option is right for you?</a></div>
                <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
                <div className="mt-2 py-2"><a href="/#/HowToCompleteYourStake" className="hmvs-font text-base  text-white hover:text-green-400 lg:text-2xl ">How to complete your stake?</a></div>
                <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
                <div className="mt-2 py-2"><a href="/#/HowToMineHeliumTokenWithValidators}" className="hmvs-font text-base  text-white   hover:text-green-400 lg:text-2xl">How to mine helium tokens with Validators?</a></div>
                <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
                <div className="mt-2 mb-2 py-2"><a href="/#/HowToUnstakeAHeliumValidator" className="hmvs-font text-base  text-white   hover:text-green-400 lg:text-2xl">How to unstake a Helium Validator?</a></div>
                <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
                <div className="mt-2 mb-2 py-2"><a href="/#/BLOG" className="hmvs-font text-base  text-white   hover:text-green-400 lg:text-2xl">Welcome to Vanager</a></div>
            </section>



        </div>
    );

    return (
        <div>
            {loading ? loadContent : (loadError ? errorContent : (stakedInstances.size === 0 ? [emptyConent, addValidatorModalConent] : [normalContent, addValidatorModalConent]))}
        </div>
    );
}




export default UserProfile;

