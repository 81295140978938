import * as React from "react";
import Alert from 'react-bootstrap/Alert';
import { customerEarnings, StakeRewards } from '../libs/earningsEstimator';
import NumberFormat from 'react-number-format';
import Client, { Network } from "@helium/http";
import { Helpers } from "../libs/helpers";

class Estimator extends React.Component<{}, { output: customerEarnings | undefined, validationError: boolean, hntConversion: boolean, durationConversion: boolean }> {
    userStakes: StakeRewards;
    constructor(props: any) {
        super(props);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const hlmClient = new Client(Helpers.useTestnet() ? Network.testnet : Network.production, {
            userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.69 Safari/537.36"
        });
        this.estimate = this.estimate.bind(this);
        this.state = { output: undefined, validationError: false, hntConversion: true, durationConversion: true };
        this.userStakes = new StakeRewards(hlmClient);
        //   this.handleSubmit = this.handleSubmit.bind(this);
        this.userStakes.getestimatedEarnings({
            stakeAmount: 10000, 
            validatorsOnline: 200, 
        }).then(output => {
            //    let userDailyOut = output.dailyGrossEarningsUSD 
            //    let userDailyServer = output.dailyServerCostUSD
            //    let userDailyService = output.dailyServiceFeeUSD
            //    let userDailyNetOut = output.dailyNetEarningsUSD
            this.setState({
                output: output
            })
        })

    }


    private isNumeric(str: any) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    public estimate(e: React.FormEvent) {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        const stakeAmount = formData.get('stakeAmount')?.valueOf()?.toString() || "0";
        const validatorOnline = formData.get('validatorsOnline')?.valueOf()?.toString() || "0";
        if (!this.isNumeric(stakeAmount) || !this.isNumeric(validatorOnline)) {
            this.setState({ validationError: true });
            return;
        }
        if (Number.parseInt(stakeAmount) < 1) {
            this.setState({ validationError: true });
            return;
        }

        if (Number.parseInt(validatorOnline) < 1) {
            this.setState({ validationError: true });
            return;
        }

        this.setState({ validationError: false });
        this.userStakes.getestimatedEarnings({
            stakeAmount: Number.parseInt(stakeAmount),
            validatorsOnline: Number.parseInt(validatorOnline)
        }).then(output => {
            //    let userDailyOut = output.dailyGrossEarningsUSD 
            //    let userDailyServer = output.dailyServerCostUSD
            //    let userDailyService = output.dailyServiceFeeUSD
            //    let userDailyNetOut = output.dailyNetEarningsUSD
            this.setState({
                output: output
            })
        })
    }

    handleHNTConversion = (e: React.FormEvent) => {


        if (e.target && (e.target as any).value === "HNT") {
            this.setState({ hntConversion: true })
        } else {
            this.setState({ hntConversion: false })
        }

    }

    handleEarningDuration = (e: React.FormEvent) => {


        if (e.target && (e.target as any).value === "daily") {
            this.setState({ durationConversion: false })
        } else {
            this.setState({ durationConversion: true })
        }

    }


    render() {
        return (
            <div className="ml-2">



                <section className="py-20 ">
                    <form action="" onSubmit={this.estimate}>
                        <p className="mx-9 text-3xl font-bold hmvs-text-darkAlt mb-2 hmvs-font">Estimate your earnings</p>
                        <div className="py-1"></div>
                        <div className="mx-9 flex flex-wrap">
                            <p className="text-white lg:mt-2 hmvs-font"> Don't have HNT? Buy HNT here. </p> <a href="https://www.simpleswap.io"> <button className="ml-2 px-2 hmvs-font w-full rounded hmvs-button-primary hover:bg-white shadow">Simple Swap</button></a> <a href="https://www.binance.us/en/home"> <button className="ml-5 px-2 hmvs-font w-full rounded hmvs-button-primary hover:bg-white shadow">Binance</button></a> 
                        
                        </div>
                        <div className="py-4"></div>
                        <div className="mx-9 lg:flex flex-wrap">

                            <div className=" mb-6">
                                <p className="text-white py-2 font-semibold hmvs-font">Stake Amount (HNT)</p>
                                <input className="appearance-none block w-quarter py-3 px-6 md:mb-0 leading-tight text-gray-700 bg-gray-200 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none" type="number" id="stakeAmount" name="stakeAmount" placeholder="10000" defaultValue="10000" />

                            </div>
                            <div className="lg:px-1 "></div>
                            <div className="lg:mx-6 mb-3">
                                <p className="text-white py-2 hmvs-font"># of validators online</p>
                                <input className="appearance-none text-gray block w-quarter py-3 px-6  md:mb-0 leading-tight text-gray-700 bg-gray-200 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none" type="number" id="validatorsOnline" name="validatorsOnline" placeholder="200" defaultValue="200" />
                            </div>
                            <div className="lg:px-1"></div>
                            <div className="  mb-3">
                                <p className="text-white py-2 hmvs-font">Duration</p>
                                <select id="1" name="duration" onChange={this.handleEarningDuration} className=" block text-center hmvs-font hmvs-bg-neutaral-background lg:px-9 py-3 leading-tight text-white hmvs-text-dark border rounded focus:hmvs-text-dark focus:border-gray-500 focus:outline-none">
                                    <option key={0} value="yearly"> Annually </option>
                                    <option key={1} value="daily">Daily </option>
                                </select>
                            </div>
                            <div className="lg:px-1"></div>
                            <div className="lg:mx-6  mb-3">
                                <p className="text-white py-2 hmvs-font">Currency</p>
                                <select id="1" name="duration" onChange={this.handleHNTConversion} className="hmvs-font block text-center hmvs-bg-neutaral-background lg:px-9 py-3  px-3 leading-tight text-white hmvs-text-dark border rounded focus:hmvs-text-dark focus:border-gray-500 focus:outline-none">
                                    <option key={0} value="HNT"> HNT  </option>
                                    <option key={1} value="inception">USD </option>
                                </select>
                            </div>
                            <div className="lg:px-1 mb-6 lg:py-6">
                                <div className="py-1.5" />
                                <button className="ml-2 px-2 rounded hmvs-button-primary hover:bg-white shadow hmvs-font" type="submit">Estimate</button>
                                <Alert key={1} className="py-4 hmvs-font px-8 text-white" variant="danger" hidden={!this.state.validationError} dismissible={true} >
                                    Your input wasn't valid - try again
                        </Alert>

                            </div>

                        </div>


                        <div className="flex flex-wrap px-9">
                            <div className="lg:w-4/24 md:w-full flex w-full">
                                <div className="px-5    w-full  py-2 flex-col hmvs-border-darkAlt border rounded shadow  min-hmvs lg:py-5">
                                    <div className="content-center">
                                        <div className="py-3 md:py-1 xl:py-1"></div>
                                        <div className=" ">
                                            <p className="text-2xl lg:text-xl  font-heading text-white hmvs-font ">Staked Value</p>
                                        </div>
                                        <div className="py-3"></div>
                                        <div className="">
                                            <p className="lg:text-2xl text-4xl    font-semibold font-heading font-semibold text-white" >
                                                <NumberFormat displayType={'text'} value={this.state.hntConversion ? this.state.output?.userStakeAmout.toFixed(2) : this.state.output?.userStakeAmountUSD.toFixed(2) } thousandSeparator={true} prefix={this.state.hntConversion ? 'HNT ' : '$'} ></NumberFormat>
                                            </p>
                                        </div>
                                        <div className="py-3"> </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="lg:py-14 lg:mb-0 flex-col font-center text-center w-full lg:w-1/24 ">
                                <p className="text-5xl  font-semibold font-heading text-white">+</p>
                            </div>
                            <div className="lg:w-4/24 md:w-full flex w-full">
                                <div className="px-5  lg:mx-0  w-full  py-2 flex-col hmvs-border-darkAlt border rounded shadow  min-hmvs lg:py-5 ">

                                    <div className="content-center ">
                                        <div className="py-3 md:py-1 xl:py-1"></div>
                                        <div className="  ">
                                            <p className="text-2xl lg:text-xl  font-heading text-white hmvs-font ">Gross Income</p>
                                        </div>
                                        <div className="py-3"></div>
                                        <div className="">
                                            <div hidden={this.state.hntConversion}>
                                                <div>
                                                    <p className="lg:text-2xl text-4xl   font-semibold font-heading font-semibold hmvs-font text-white" hidden={this.state.durationConversion}><NumberFormat displayType={'text'} value={this.state.output?.dailyGrossEarningsUSD.toFixed(2)} thousandSeparator={true} prefix={'$'} ></NumberFormat></p>
                                                    {/* <p className ="text-3xl font-semibold font-heading font-semibold text-white" hidden={this.state.durationConversion}>${this.state.output?.dailyGrossEarningsUSD.toFixed(2)}</p> */}
                                                </div>
                                                <div className=" " >
                                                    <p className="lg:text-2xl text-4xl   font-semibold font-heading font-semibold hmvs-font text-white" hidden={!this.state.durationConversion}><NumberFormat displayType={'text'} value={this.state.output?.annualGrossEarningsUSD.toFixed(2)} thousandSeparator={true} prefix={'$'} ></NumberFormat></p>
                                                    {/* <p className ="text-3xl  font-semibold font-heading font-semibold text-white" hidden={!this.state.durationConversion}>${this.state.output?.annualGrossEarningsUSD.toFixed(2)}</p> */}
                                                </div>
                                            </div>
                                            <div hidden={!this.state.hntConversion}>
                                                <div>
                                                    <p className="lg:text-2xl text-4xl   font-semibold font-heading font-semibold hmvs-font text-white" hidden={this.state.durationConversion}><NumberFormat displayType={'text'} value={this.state.output?.dailyGrossEarningsHNT.toFixed(2)} thousandSeparator={true} prefix={'HNT '}></NumberFormat></p>

                                                    {/* <p className ="text-3xl font-semibold font-heading font-semibold text-white" hidden={this.state.durationConversion}>{this.state.output?.dailyGrossEarningsHNT.toFixed(2)}</p> */}
                                                </div>
                                                <div className=" " >
                                                    <p className="lg:text-2xl text-4xl   font-semibold font-heading font-semibold hmvs-font text-white" hidden={!this.state.durationConversion}><NumberFormat displayType={'text'} value={this.state.output?.annualGrossEarningsHNT.toFixed(2)} thousandSeparator={true} prefix={'HNT '} ></NumberFormat></p>

                                                    {/* <p className ="text-3xl  font-semibold font-heading font-semibold text-white" hidden={!this.state.durationConversion}>{this.state.output?.annualGrossEarningsHNT.toFixed(2)}</p> */}
                                                </div>
                                            </div>
                                            <div className="py-3 "> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:py-14 lg:mb-0 flex-col font-center text-center w-full lg:w-1/24">
                                <p className="text-5xl font-semibold font-heading text-white">-</p>
                            </div>

                            <div className="lg:w-4/24 md:w-full flex w-full ">
                                
                                <div className="px-5  lg:mx-0 w-full  py-2 flex-col hmvs-border-darkAlt border rounded shadow   min-hmvs lg:py-5  ">
                                    <div className="">
                                        <div className="py-3 md:py-1"></div>
                                        <div className=" ">
                                            <p className="text-2xl lg:text-xl  font-heading text-white hmvs-font  ">Service Fee</p>
                                        </div>
                                        <div className="py-3"></div>
                                        <div className="">
                                            <div hidden={this.state.hntConversion}>
                                                <div>
                                                    <p className="lg:text-2xl text-4xl hmvs-font font-semibold font-heading font-semibold text-white" hidden={this.state.durationConversion}><NumberFormat displayType={'text'} value={this.state.output?.dailyServiceFeeUSD.toFixed(2)} thousandSeparator={true} prefix={'$'} ></NumberFormat></p>
                                                    {/* <p className ="text-3xl font-semibold font-heading font-semibold text-white" hidden={this.state.durationConversion}>${this.state.output?.dailyServiceFeeUSD.toFixed(2)}</p> */}
                                                </div>
                                                <div className=" " >
                                                    <p className="lg:text-2xl text-4xl hmvs-font  font-semibold font-heading font-semibold text-white" hidden={!this.state.durationConversion}><NumberFormat displayType={'text'} value={this.state.output?.annualServiceFeeUSD.toFixed(2)} thousandSeparator={true} prefix={'$'} ></NumberFormat></p>
                                                    {/* <p className ="text-3xl  font-semibold font-heading font-semibold text-white" hidden={!this.state.durationConversion}>${this.state.output?.annualServiceFeeUSD.toFixed(2)}</p> */}
                                                </div>
                                            </div>
                                            <div hidden={!this.state.hntConversion}>
                                                <div>
                                                    <p className="lg:text-2xl text-4xl hmvs-font  font-semibold font-heading font-semibold text-white" hidden={this.state.durationConversion}><NumberFormat displayType={'text'} value={this.state.output?.dailyServiceFeeHNT.toFixed(2)} thousandSeparator={true} prefix={'HNT '}></NumberFormat></p>

                                                    {/* <p className ="text-3xl font-semibold font-heading font-semibold text-white" hidden={this.state.durationConversion}>{this.state.output?.dailyServiceFeeHNT.toFixed(2)}</p> */}
                                                </div>
                                                <div className=" " >
                                                    <p className=" lg:text-2xl text-4xl hmvs-font font-semibold font-heading font-semibold text-white" hidden={!this.state.durationConversion}><NumberFormat displayType={'text'} value={this.state.output?.annualServiceFeeHNT.toFixed(2)} thousandSeparator={true} prefix={'HNT '} ></NumberFormat></p>

                                                    {/* <p className ="text-3xl  font-semibold font-heading font-semibold text-white" hidden={!this.state.durationConversion}>{this.state.output?.annualServiceFeeHNT.toFixed(2)}</p> */}
                                                </div>
                                                
                                            </div>
                                            <div className="py-3"></div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>

                                {/* <span className="text-white leading-relaxed text-sm mt-2   ">Service Fee = Income x 3%</span> */}
                            </div>
                            
                            <div className="lg:py-14 lg:mb-0 flex-col font-center text-center w-full lg:w-1/24">
                                <p className="text-5xl font-semibold font-heading text-white">-</p>
                            </div>
                            <div className="lg:w-4/24 md:w-full flex w-full">
                                <div className="px-5  lg:mx-0 w-full  py-2 flex-col hmvs-border-darkAlt border rounded shadow   min-hmvs lg:py-5  ">
                                    <div className="">
                                        <div className="py-3 md:py-1 "> </div>
                                        <div className="  ">
                                            <p className="text-2xl lg:text-xl hmvs-font font-heading text-white   ">Server Cost</p>
                                        </div>
                                        <div className="py-3"> </div>
                                        <div className="">
                                            <div hidden={this.state.hntConversion}>
                                                <div>

                                                    <p className="lg:text-2xl text-4xl hmvs-font font-semibold font-heading font-semibold text-white" hidden={this.state.durationConversion}><NumberFormat displayType={'text'} value={this.state.output?.dailyServerCostUSD.toFixed(2)} thousandSeparator={true} prefix={'$'} ></NumberFormat></p>
                                                    {/* <p className ="text-3xl font-semibold font-heading font-semibold text-white" hidden={this.state.durationConversion}>${this.state.output?.dailyServerCostUSD.toFixed(2)}</p> */}
                                                </div>
                                                <div className=" " >
                                                    <p className="lg:text-2xl text-4xl  hmvs-font font-semibold font-heading font-semibold text-white" hidden={!this.state.durationConversion}><NumberFormat displayType={'text'} value={this.state.output?.annualServerCostUSD.toFixed(2)} thousandSeparator={true} prefix={'$'} ></NumberFormat></p>
                                                    {/* <p className ="text-3xl  font-semibold font-heading font-semibold text-white" hidden={!this.state.durationConversion}>${this.state.output?.annualServerCostUSD.toFixed(2)}</p> */}
                                                </div>
                                            </div>
                                            <div hidden={!this.state.hntConversion}>
                                                <div>
                                                    <p className="lg:text-2xl text-4xl hmvs-font font-semibold font-heading font-semibold text-white" hidden={this.state.durationConversion}><NumberFormat displayType={'text'} value={this.state.output?.dailyServerCostHNT.toFixed(2)} thousandSeparator={true} prefix={'HNT '}></NumberFormat></p>

                                                    {/* <p className ="text-3xl font-semibold font-heading font-semibold text-white" hidden={this.state.durationConversion}>{this.state.output?.dailyServerCostHNT.toFixed(2)}</p> */}
                                                </div>
                                                <div className=" " >
                                                    <p className="lg:text-2xl text-4xl hmvs-font  font-semibold font-heading font-semibold text-white" hidden={!this.state.durationConversion}><NumberFormat displayType={'text'} value={this.state.output?.annualServerCostHNT.toFixed(2)} thousandSeparator={true} prefix={'HNT '}></NumberFormat></p>
                                                    {/* <p className ="text-3xl  font-semibold font-heading font-semibold text-white" hidden={!this.state.durationConversion}>{this.state.output?.annualServerCostHNT.toFixed(2)}</p> */}
                                                </div>
                                            </div>
                                            <div className="py-3"> </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <p className="text-white leading-relaxed text-sm mt-2  ">About $4 per day</p> */}
                            </div>

                            <div className="lg:py-14 lg:mb-0 flex-col font-center text-center w-full lg:w-1/24">
                                <p className="text-5xl font-semibold font-heading text-white">=</p>
                            </div>
                            
                            <div className="lg:w-4/24 md:w-full flex w-full">
                            <div className="px-5  lg:mx-0 w-full  py-2 flex-col hmvs-border-darkAlt border rounded shadow md:px-5   min-hmvs lg:py-5 ">
                                <div className="">
                                    <div className="py-3 md:py-1"> </div>
                                    <div className="  ">
                                        <p className="text-2xl lg:text-xl  font-heading text-white hmvs-font ">Total Value</p>
                                    </div>
                                    <div className="py-3"> </div>
                                    <div className="">
                                        <div hidden={this.state.hntConversion}>
                                            <div>
                                                <p className="lg:text-2xl text-4xl hmvs-font font-semibold font-heading font-semibold text-white" hidden={this.state.durationConversion}><NumberFormat displayType={'text'} value={this.state.output?.totalValueDailyUSD.toFixed(2)} thousandSeparator={true} prefix={'$'} ></NumberFormat></p>
                                                {/* <p className ="text-3xl font-semibold font-heading font-semibold text-white" hidden={this.state.durationConversion}>${this.state.output?.totalValueDailyUSD.toFixed(2)}</p> */}
                                            </div>
                                            <div className=" " >
                                                <p className=" lg:text-2xl text-4xl hmvs-font font-semibold font-heading font-semibold text-white" hidden={!this.state.durationConversion}><NumberFormat displayType={'text'} value={this.state.output?.totalValueAnnuallyUSD.toFixed(2)} thousandSeparator={true} prefix={'$'} ></NumberFormat></p>
                                                {/* <p className ="text-3xl  font-semibold font-heading font-semibold text-white" hidden={!this.state.durationConversion}>${this.state.output?.totalValueAnnuallyUSD.toFixed(2)}</p> */}
                                            </div>
                                        </div>
                                        <div hidden={!this.state.hntConversion}>
                                            <div>
                                                <p className=" lg:text-2xl text-4xl hmvs-font  font-semibold font-heading font-semibold text-white" hidden={this.state.durationConversion}><NumberFormat displayType={'text'} value={this.state.output?.totalValueDailyHNT.toFixed(2)} thousandSeparator={true} prefix={'HNT '}></NumberFormat></p>

                                                {/* <p className ="text-3xl font-semibold font-heading font-semibold text-white" hidden={this.state.durationConversion}>{this.state.output?.totalValueDailyHNT.toFixed(2)}</p> */}
                                            </div>
                                            <div className=" " >
                                                <p className=" lg:text-2xl text-4xl hmvs-font font-semibold font-heading font-semibold text-white" hidden={!this.state.durationConversion}><NumberFormat displayType={'text'} value={this.state.output?.totalValueAnnuallyHNT.toFixed(2)} thousandSeparator={true} prefix={'HNT '}></NumberFormat></p>

                                                {/* <p className ="text-3xl  font-semibold font-heading font-semibold text-white" hidden={!this.state.durationConversion}>{this.state.output?.totalValueAnnuallyHNT.toFixed(2)}</p> */}
                                            </div>
                                        </div>
                                        <div className="py-3"> </div>
                                    </div>
                                </div>
                                
                            </div>
                                {/* <p className="py-2  text-white leading-relaxed text-sm">Based on Oracle Price: ${this.state.output?.oraclePrice.toFixed(2)}</p> */}
                            </div>

                        </div>





                    </form>
                </section>







            </div>
        );

    }
}

export default Estimator;
