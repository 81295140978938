import React from "react";
import InterestForm from '../components/interestForm'

export const ContactUs: React.FunctionComponent<{  }> = () => {
    return (
      <div>
        <section className="pt-8 hmvs-bg-neutaral-dark px-4 pb-4 ">
          <div className="flex flex-wrap -mx-4 text-center">
            <div className="w-full lg:w-1/3 px-4 mb-8">
              <div className="h-full pb-6 hmvs-bg-neutaral-black rounded shadow">
                <img className="mx-auto mb-4 py-4 px-4" src="images/Breland.jpg" alt="" style={{width:"300px", height: "300px"}}></img> 
                <div className="px-8">
                  <h3 className="text-xl mb-1 font-semibold font-heading font-semibold text-white hmvs-font">Breland Miley</h3>
                  <span className="text-white hmvs-font">Full Stack Engineer</span>
                  <div className="flex justify-center space-x-6 py-3 my-4 mx-12 border-t border-b">
                    <a href="mailto:info@vanager.io">
                      <svg className="hmvs-text-darkAlt w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                      </svg>
                   
                    </a>
                    <a href="https://www.linkedin.com/in/brelandmiley/"> 
                      <img className="" src="images/LI-In-Bug.png" alt="" style={{width:"30px", height: "30px"}}></img>
                    </a>
              
                  </div>
                  <p className="text-white hmvs-font">10+ years of software development at leading tech corporations.</p>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/3 px-4 mb-8">
              <div className="h-full pb-6 hmvs-bg-neutaral-black rounded shadow">
                <img className="mx-auto mb-4 py-4 px-4 rounded" src="images/tjhmvs .jpg" alt="" style={{width:"300px", height: "300px"}}></img>
                <div className="px-8">
                  <h3 className="text-xl mb-1 font-semibold font-heading font-semibold text-white hmvs-font">Anthony Scott</h3>
                  <span className="text-white hmvs-font">Engineer and Product Manager</span>
                  <div className="flex justify-center space-x-6 py-3 my-4 mx-12 border-t border-b">
                    <a href="mailto:info@vanager.io"> 
                      <svg className="hmvs-text-darkAlt w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                      </svg>
                    </a>
                    <a href="https://www.linkedin.com/in/anthony-scott-ii-9024b12b"> 
                      <img  src="images/LI-In-Bug.png" alt="" style={{width:"30px", height: "30px"}}></img>
                    </a>
   
                  </div>
                  <p className="text-white hmvs-font">8+ years leading the development of scalable services for the top 5 tech companies.</p>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/3 px-4 mb-8">
              <div className="h-full pb-6 hmvs-bg-neutaral-black rounded shadow">
                <img className="mx-auto mb-4 py-4 px-4" src="images/Jessica Perrin.jpg" alt="" style={{width:"300px", height: "300px"}}></img> 
                <div className="px-8">
                  <h3 className="text-xl mb-1 font-semibold font-heading font-semibold text-white hmvs-font">Jessica Perrin</h3>
                  <span className="text-white hmvs-font">Product Designer</span>
                  <div className="flex justify-center space-x-6 py-3 my-4 mx-12 border-t border-b">
                    <a href="mailto:info@vanager.io">
                      <svg className="hmvs-text-darkAlt w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                      </svg>
                   
                    </a>
                    <a href="https://www.linkedin.com/in/jessica-perrin/"> 
                      <img className="" src="images/LI-In-Bug.png" alt="" style={{width:"30px", height: "30px"}}></img>
                    </a>
              
                  </div>
                  <p className="text-white hmvs-font">Translating complex tech into usable experiences for everyone. </p>
                </div>
              </div>
            </div>


          </div>
        </section>

        <section>
          <InterestForm />
        </section>
      </div>
    )
  }

export default ContactUs;