import React, { useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import { useValidPassword, useValidEmail } from '../../hooks/useAuthHooks'
import { Password, Email } from '../../components/authComponents'

import { AuthContext } from '../../contexts/authContext'

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
})

const SignIn: React.FunctionComponent<{}> = () => {
  const classes = useStyles()

  const { email, setEmail, emailIsValid } = useValidEmail('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const [error, setError] = useState('')

  const isValid = !emailIsValid || email.length === 0 || !passwordIsValid || password.length === 0

  const history = useHistory()

  const authContext = useContext(AuthContext)

  const signInClicked = async () => {
    try {
      await authContext.signInWithEmail(email, password)
      history.push('home')
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        history.push('verify')
      } else {
        setError(err.message)
      }
    }
  }

  const passwordResetClicked = async () => {
    history.push('requestcode')
  }

  return (
    <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} container direction="row" justify="center" alignItems="center" item>
        <Paper style={{ width: '100%', padding: 32, borderColor: "#41B061", backgroundColor:"#000000" }}>
          <Grid container direction="column" justify="center" alignItems="center">
            {/* Title */}
            <Box m={2}>
              <img src="images/V.png" style={{height:"15vh", width:"15vh", alignContent: "inline"}} className="mx-16" alt="Vanager Logo"></img>
              <Typography  variant="h3"><p className="text-7xl text-white">Sign in</p></Typography>
            </Box>

            {/* Sign In Form */}
            <Box style={{backgroundColor: "#FFFFFF"}} className="rounded " width="80%" m={1}>
              <Email emailIsValid={emailIsValid} setEmail={setEmail} />{' '}
              {/* <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />{' '} */}
            </Box>
            <Box style={{backgroundColor: "#FFFFFF"}} color="white"  className="rounded" width="80%" m={1}>
              <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
              
            </Box>
            <Grid container direction="row" justify="flex-start" alignItems="center">
                <Box onClick={passwordResetClicked} mt={2}>
                  <Typography className={classes.hover} variant="body2">
                    <p className="text-white ml-11">Forgot Password?</p>
                  </Typography>
                </Box>
            </Grid>

            {/* Error */}
            <Box mt={2}>
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            </Box>

            {/* Buttons */}
            <Box mt={2}>
              <Grid container direction="row" justify="center" >
                <Box m={1} >
                  <button className="rounded hmvs-font hmvs-button-secondary hover:bg-gray-600 shadow" 
                  onClick={() => history.goBack()}>
                    Cancel
                  </button>
                </Box>
                <Box m={1}>
                  <button className="rounded hmvs-font hmvs-button-primary hover:bg-white shadow" 
                  disabled={isValid} 
                  onClick={signInClicked}>
                    Sign In
                  </button>
                </Box>
              </Grid>
            </Box>
            <Box mt={2}>
              <Box onClick={() => history.push('signup')}>
                <Typography className={classes.hover} variant="body1">
                  Register a new account
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default SignIn
