import * as React from "react";
import { Helpers } from "../libs/helpers";
import Alert from "react-bootstrap/Alert";
import { protoTypes } from "@hlmvs/hlmvs-client";
import { AuthContext } from "../contexts/authContext";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import DepositInstructionsInnerContent from "./depositInstructionsInnerContent";


interface SetupData {
  publicOrPrivate: "PUBLIC" | "PRIVATE",
  fullOrPartial: "FULL" | "PARTIAL",
  amount: number,
  joinOrCreate: "JOIN" | "CREATE",
  name: string,
  groupCode: string
}


const ValidatorSetup: React.FunctionComponent<{
  onSaveSuccessful?: () => void;
  prePopulatedGroupCode?: string;
  joiningPrivate?: boolean;
  fullStaker?: boolean;
  newPartial?: boolean;
  rk?: string;
  onSaveAndCloseSuccessful?: () => void;
}> = (props) => {
  const [validationError, setValidationError] = React.useState(false);
  const [formEnabled, setFormEnabled] = React.useState(true);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [saveError, setSaveError] = React.useState(false);
  const [partialStaker, setPartialStaker] = React.useState(false);
  const [fullStaker, setFullStaker] = React.useState(false);
  const [newGroup, setNewGroup] = React.useState(false);
  const [joinGroup, setJoinGroup] = React.useState(false);
  // const [publicGroup, setPublicGroup] = React.useState(false);
  const [isPrivateGroup, setIsPrivateGroup] = React.useState(false);
  const [groupPreview, setGroupPreview] = React.useState<
    protoTypes.PublicStakeInstance | undefined
  >(undefined);
  const [showGroupCodePreviewResults, setShowGroupCodePreviewResults] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [hideNewOrJoin, setHideNewOrJoin] = React.useState(false);
  const [hideFullOrPartial, setHideFullOrPartial] = React.useState(false);
  const [hideGroupCodeInput, setHideGroupCodeInput] = React.useState(false);
  const [hideOwnership, setHidOwnership] = React.useState(false);
  // const [hideValidatorNaming, setValidatorNaming] = React.useState(false);
  const [hideGroupChoice, setHideGroupChoice] = React.useState(false);
  const [showPickPublicOrPrivateGroup, setShowPickPublicOrPrivateGroup] = React.useState(false);
  // const [finishStaking, setfinishStaking] = React.useState(true);
  const [showQRCode, setShowQRCode] = React.useState(false);
  // const [depositAddress, setDepositAddress] = React.useState("");
  const [
    lastCreatedStakeInstance,
    setlastCreatedStakeInstance,
  ] = React.useState<protoTypes.StakeInstance | undefined>(undefined);
  const [saveErrorMessage, setSaveErrorMessage] = React.useState("");
  const [noMatchingGroupFound, setNoMatchingGroupFound] = React.useState(false);
  const [groupCodeValidatorError, setGroupInputCodeValidationError] = React.useState(false);

  const [groupCodeInputValue, setGroupCodeInputValue] = React.useState("");



  
  const [formData, setFormData] = React.useState<Partial<SetupData>>({});





  const authContext = React.useContext(AuthContext);

  const originalState = () => {
    setFormData({})
    setValidationError(false)
    
     setFormEnabled(true)
     setShowSuccess(false)
     setSaveError(false)
     setPartialStaker(false)
     setFullStaker(false)
     setNewGroup(false)
     setJoinGroup(false)
     setIsPrivateGroup(false)
     setGroupPreview(undefined)
     setShowGroupCodePreviewResults(false)
     setSaving(false)
     setHideNewOrJoin(false)
     setHideFullOrPartial(false)
     setHideGroupCodeInput(false)
     setHidOwnership(false)
     setHideGroupChoice(false)
     setShowPickPublicOrPrivateGroup(false)
    //  setShowQRCode(false)
     // setlastCreatedStakeInstance(undefined)
     setSaveErrorMessage("")
     setNoMatchingGroupFound(false)
     setGroupInputCodeValidationError(false)
     setGroupCodeInputValue("")
  }

  // eslint-disable-next-line
  const fetchGroupCodePreview = (input: string) => {
    if (input && input.length === 8) {
      setGroupInputCodeValidationError(false);
      console.log(`Getting code ${input}`);
      setNoMatchingGroupFound(false);
      Helpers.groupCodePreview(input, authContext)
        .then((group) => {
          console.log(
            `Got a group code preview: ${JSON.stringify(group.toObject())}`
          );
          // setState({groupPreview: group!});
          setGroupPreview(group.getGroup());
          setShowGroupCodePreviewResults(true);
          setNoMatchingGroupFound(false);
        })
        .catch((err) => {
          console.log(`No such group, probably ${err.message}`);
          setNoMatchingGroupFound(true);
        });
    } else {
      setNoMatchingGroupFound(false);
      setGroupInputCodeValidationError(true);
      setShowGroupCodePreviewResults(false);
    }
  };

  // eslint-disable-next-line
  React.useEffect(() => {
    originalState()
    if (props.prePopulatedGroupCode) {
      setFormData({
        fullOrPartial: "PARTIAL",
        joinOrCreate: "JOIN",
        groupCode: props.prePopulatedGroupCode
      });
      setJoinGroup(true);
      setNewGroup(false);
      setIsPrivateGroup(false);
      setPartialStaker(true);
      setShowGroupCodePreviewResults(true);
      setHideNewOrJoin(true);
      setHideFullOrPartial(true);
      setHideGroupCodeInput(true);
      fetchGroupCodePreview(props.prePopulatedGroupCode);
    } else if (props.joiningPrivate === true) {
      setFormData({
        joinOrCreate: "JOIN",
        fullOrPartial: "PARTIAL",
      })
      setJoinGroup(true);
      setNewGroup(false);
      setFullStaker(false);
      setIsPrivateGroup(true);
      setPartialStaker(true);
      setShowGroupCodePreviewResults(false);
      setHideNewOrJoin(true);
      setHideFullOrPartial(true);
      setHideGroupCodeInput(false);
    } else if (props.fullStaker === true) {
        setFormData({
          fullOrPartial: "FULL",
        })
        setHideFullOrPartial(true);
        setFullStaker(true);
        setJoinGroup(false);
        setNewGroup(false);
    } else if (props.newPartial === true) {

        setFormData({
          fullOrPartial: "PARTIAL",
        })
        setFullStaker(false);
        setJoinGroup(false);
        setNewGroup(true);
        setShowPickPublicOrPrivateGroup(true);
        setHideNewOrJoin(true);

        setIsPrivateGroup(false);
        setPartialStaker(true);
        setShowGroupCodePreviewResults(false);
        
        setHideFullOrPartial(true);
        setHideGroupCodeInput(true);
    } else {
        // console.log(`Fell to default for useeffect`);
        setFullStaker(false);
        setJoinGroup(false);
        setNewGroup(false);
        setShowPickPublicOrPrivateGroup(false);
        setHideNewOrJoin(false);

        setIsPrivateGroup(false);
        setPartialStaker(false);
        setShowGroupCodePreviewResults(false);
        
        setHideFullOrPartial(false);
        setHideGroupCodeInput(true);

        setHidOwnership(false);
    }

    // eslint-disable-next-line
  }, [props, props.rk]);

  // const handlePublicOrPrivate = (e: React.FormEvent) => {
  //     e.preventDefault();
  //     if (e.target && (e.target as any).id === "publicGroup") {
  //         // setPublicGroup(true);
  //         setPrivateGroup(false);
  //         // setState({privateGroup: false, publicGroup: true});
  //     } else {
  //         // setPublicGroup(false);
  //         setPrivateGroup(true);
  //     }
  // };

  // const setPage(page: "PARTIAL_OR_FULL" | "NEW_OR_JOIN") {
  //     switch (page) {
  //         case "NEW_OR_JOIN":
  //             set
  //     }
  // }

  const handleGroupCode = (e: React.FormEvent) => {
    const input = (e.target as any).value as string;
    setGroupCodeInputValue(input);
    fetchGroupCodePreview(input);
  };

  const handlePartialOrFullChanged = (e: React.FormEvent) => {
    e.preventDefault();
    if (e.target && (e.target as any).id === "fullStaker") {
      setFormData({
        ...formData,
        fullOrPartial: "FULL"
      })
      setFullStaker(true);
      setPartialStaker(false);
    } else {
      setFormData({
        ...formData,
        fullOrPartial: "PARTIAL"
      })
      setFullStaker(false);
      setPartialStaker(true);
    }
    setNewGroup(false);
    setJoinGroup(false);
    // setPublicGroup(false);
    setIsPrivateGroup(false);
    setHidOwnership(true);
  };

  const handleNewOrJoinChanged = (e: React.FormEvent) => {
    e.preventDefault();
    if (e.target && (e.target as any).id === "newGroup") {
      setJoinGroup(false);
      setShowPickPublicOrPrivateGroup(true);
      setPartialStaker(false);
      setHideNewOrJoin(false);
      setFormData({
        ...formData,
        joinOrCreate: "CREATE"
      })
    } else {
      setJoinGroup(true);
      setNewGroup(false);
      setPartialStaker(false);
      setHideNewOrJoin(false);
      setHideGroupCodeInput(false);
      setFormData({
        ...formData,
        joinOrCreate: "JOIN"
      })
    }
    
  };

  // const handleGroupCodeFinder = (e: React.FormEvent) => {
  //     setGroupCodeFinder(true);
  //     // this.setState({GroupCodeFinder: true});
  //     setHideGroupCodeInput(true);
  // }

  // const handleHideOwnership = (e: React.FormEvent) => {
  //     e.preventDefault();
  //     setHidOwnership(false);
  //     setFullStaker(false);

  // }

  // const handleHideOwnershipType = (e: React.FormEvent) =>{
  //     e.preventDefault();
  //     setParialStaker(true);
  //     setHideNewOrJoin(true);

  // }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setlastCreatedStakeInstance(undefined)
    setFormEnabled(false);
    console.log(`On submit - formdata state is ${JSON.stringify(formData)}`);

    // const formData = new FormData(e.target as HTMLFormElement);
    const kind = formData.fullOrPartial === "PARTIAL"
      ? protoTypes.StakeInstanceKind.STAKE_INSTANCE_KIND_PARTIAL
      : protoTypes.StakeInstanceKind.STAKE_INSTANCE_KIND_FULL;

    let validator_name = formData.name;
    // if (
    //   formData.get("ValidatorName") !== undefined &&
    //   formData.get("ValidatorName")?.valueOf() !== undefined &&
    //   formData.get("ValidatorName")!.valueOf()!.toString().trim().length > 0
    // ) {
    //   validator_name = formData
    //     .get("ValidatorName")!
    //     .valueOf()
    //     .toString()
    //     .trim();
    // } else if (
    //   formData.get("ValidatorNamePartial") !== undefined &&
    //   formData.get("ValidatorNamePartial")?.valueOf() !== undefined &&
    //   formData.get("ValidatorNamePartial")!.valueOf()!.toString().trim()
    //     .length > 0
    // ) {
    //   validator_name = formData
    //     .get("ValidatorNamePartial")!
    //     .valueOf()
    //     .toString()
    //     .trim();
    // }

    let stake_amount = formData.amount
    // if (
    //   formData.get("StakeAmount") !== undefined &&
    //   formData.get("StakeAmount")!.valueOf() !== undefined &&
    //   formData.get("StakeAmount")!.valueOf().toString().trim().length > 0
    // ) {
    //   stake_amount = Number.parseFloat(
    //     formData.get("StakeAmount")?.valueOf()?.toString() || "0.0"
    //   );
    // } else if (
    //   formData.get("StakeAmountPartial") !== undefined &&
    //   formData.get("StakeAmountPartial")!.valueOf() !== undefined &&
    //   formData.get("StakeAmountPartial")!.valueOf().toString().trim().length > 0
    // ) {
    //   stake_amount = Number.parseFloat(
    //     formData.get("StakeAmountPartial")?.valueOf()?.toString() || "0.0"
    //   );
    // }

    const group_code =
      formData.groupCode

    if (kind === protoTypes.StakeInstanceKind.STAKE_INSTANCE_KIND_FULL) {
      stake_amount = 10_000;
    }

    if (!stake_amount) {
      console.log(`No stake amount`);
      setFormEnabled(true);
      setValidationError(true);
      return;
    }

    setSaving(true);

    if (
      kind === protoTypes.StakeInstanceKind.STAKE_INSTANCE_KIND_PARTIAL &&
      formData.joinOrCreate === "JOIN"
    ) {
      if (group_code === undefined || group_code.trim().length === 0) {
        console.log(`No group code`);
        setSaving(false);
        setFormEnabled(true);
        setValidationError(true);
        return;
      }
      Helpers.joinGroup(group_code, stake_amount, authContext)
        .then((done) => {
          setSaving(false);
          setlastCreatedStakeInstance(done.getStakeinstance());
          console.log(done);
          setShowSuccess(true);
          setValidationError(false);
          setSaveError(false);
          if (props.onSaveSuccessful) {
            props.onSaveSuccessful();
          }
          // setDepositAddress(done.getStakeinstance()!.getCallerownerrecord()!.getOwnerstakedepositwalletaddress()!.toString())

          setHideGroupCodeInput(false);
          setShowQRCode(true);
          setFullStaker(false);
          setPartialStaker(false);
          setNewGroup(false);
          setJoinGroup(false);
          setIsPrivateGroup(false);
          setGroupPreview(undefined);
          setShowGroupCodePreviewResults(false);
          setHideNewOrJoin(false);
          setHideFullOrPartial(true);
          setHidOwnership(false);
          setHideGroupChoice(false);
        })
        .catch((err) => {
          console.error(err);
          setSaveErrorMessage(err?.message);
          setFormEnabled(true);
          setSaveError(true);
          setSaving(false);
          setShowQRCode(false);
          
          // setDepositAddress("");
        });
    } else {
      Helpers.createValidator(
        {
          validator_name,
          stake_amount: stake_amount,
          kind:
            formData.fullOrPartial === "PARTIAL" || formData.joinOrCreate === "CREATE"
              ? protoTypes.StakeInstanceKind.STAKE_INSTANCE_KIND_PARTIAL
              : protoTypes.StakeInstanceKind.STAKE_INSTANCE_KIND_FULL,
          privacy: formData.publicOrPrivate === "PRIVATE" ? protoTypes.PartialPrivacy.PARTIAL_PRIVACY_PRIVATE : protoTypes.PartialPrivacy.PARTIAL_PRIVACY_PUBLIC
        },
        authContext
      )
        .then((done) => {
          setSaving(false);
          setlastCreatedStakeInstance(done.getStakeinstance());
          setShowSuccess(true);
          setValidationError(false);
          setSaveError(false);
          if (props.onSaveSuccessful) {
            props.onSaveSuccessful();
          }
          // setDepositAddress(done.getStakeinstance()!.getCallerownerrecord()!.getOwnerstakedepositwalletaddress()!.toString())

          setHideGroupCodeInput(false);
          setShowQRCode(true);
          setFullStaker(false);
          setPartialStaker(false);
          setNewGroup(false);
          setJoinGroup(false);
          setIsPrivateGroup(false);
          setGroupPreview(undefined);
          setShowGroupCodePreviewResults(false);
          setHideNewOrJoin(false);
          setHideFullOrPartial(true);
          setHidOwnership(false);
          setHideGroupChoice(false);
        })
        .catch((err) => {
          setSaveErrorMessage(err?.message);
          console.error(err);
          setFormEnabled(true);
          setSaveError(true);
          setSaving(false);
          setShowQRCode(false);
          // setDepositAddress("");
        });
    }
  };

  return saving ? (
    <div
      hidden={!saving}
      className="flex flex-wrap -mx-4 text-white items-center place-self-center hmvs-font"
    >
      <p hidden={!saving}>
        Just a second while we create a few things. This will take a while.
      </p>
      <ClimbingBoxLoader loading={saving} color="white" />
    </div>
  ) : (
    <div>
      <Alert
        key={0}
        className="py-4 px-8"
        variant="success"
        hidden={!showSuccess}
      >
        WooHoo! We're ready to start your validator. Next up, we'll walk you
        through the staking and transfer process
      </Alert>
      <Alert
        key={1}
        className="py-4 px-8"
        variant="warning"
        hidden={!validationError}
        dismissible={true}
      >
        We need a bit more data before submitting - try again.
      </Alert>
      <Alert
        key={2}
        className="py-4 px-8"
        variant="danger"
        hidden={!saveError}
        dismissible={true}
      >
        Unfortunately - we had difficulties saving your response.{" "}
        {saveErrorMessage === undefined
          ? "Maybe you could try again later?"
          : saveErrorMessage}
      </Alert>

      <form className="py-4 bg-black text-white" onSubmit={handleSubmit}>
        <div className=" " hidden={hideFullOrPartial}>
          <div hidden={hideOwnership}>
            <fieldset disabled={!formEnabled} id="rgFullOrPartial">
              <p className="text-lg mb-4 hmvs-font ">
                Do you want to Full Stake or Partial Stake?{" "}
              </p>

              <div className="flex flex-row">
                <button
                  onClick={handlePartialOrFullChanged}
                  id="fullStaker"
                  style={{ height: "50px", width: "90px" }}
                  className={`hmvs-font  leading-none text-white hmvs-bg-dark font-semibold rounded shadow ${
                    fullStaker ? "hover:bg-green-700" : ""
                  }`}
                >
                  Full Stake
                </button>

                <button
                  id="partialStaker"
                  onClick={handlePartialOrFullChanged}
                  style={{ height: "50px", width: "90px" }}
                  className={`hmvs-font ml-2  leading-none text-white hmvs-bg-dark font-semibold rounded shadow ${
                    partialStaker ? "hover:bg-green-700" : ""
                  } `}
                >
                  Partial Stake
                </button>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="" id="fullStakerDiv" hidden={!fullStaker}>
          <div className="w-full flex flex-wrap max-w-md mx-auto">
            <p className="hmvs-font py-2 text-lg">
              You can give your validator a custom name if you want - but if
              not, we'll generate one for you.
            </p>
            <div className="mb-4">
              <input
                className="appearance-none block w-full py-3 px-4 leading-tight text-black bg-gray-50 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
                type="text"
                placeholder="Optional Name"
                name="ValidatorName"
                id="ValidatorName"
                onChange={(e) => setFormData({...formData, name: (e.target as any).value as string})}
              />
            </div>

            <div className="mb-4  mx-2 ">
              <button
                className={`center hmvs-font py-3 text-xl   leading-none px-5  text-white hmvs-bg-dark font-semibold rounded shadow ${
                  formEnabled ? "hover:hmvs-bg-darkAlt" : ""
                }`}
                disabled={!formEnabled}
              >
                Next
              </button>
            </div>
            {/* <div className=""> <button onClick={handleHideOwnership} className={`center   py-4 px-4   leading-none text-white hmvs-bg-neutaral-medium font-semibold rounded shadow`} >Go Back</button></div> */}
          </div>
        </div>

        <div
          id="partialStakerDiv"
          className=""
          hidden={!partialStaker || hideNewOrJoin}
        >
          <fieldset disabled={!formEnabled} id="rgStartOrJoin">
            <div
              className="w-full flex flex-wrap max-w-md mx-auto"
              hidden={!hideGroupChoice}
            >
              <div className="flex-col">
                <p className="text-lg mb-4 hmvs-font">
                  Do you want to start a group or join a group?
                </p>
              </div>
              <div className="flex flex-wrap">
                <div>
                  <button
                    onClick={handleNewOrJoinChanged}
                    id="newGroup"
                    style={{ height: "50px", width: "90px" }}
                    className={`inline-block hmvs-font leading-none hmvs-text-dark bg-white font-semibold rounded shadow ${
                      newGroup ? "hover:bg-indigo-700" : ""
                    }`}
                  >
                    New Group
                  </button>
                  {/* <div className=""> <button onClick={handleNewOrJoinChanged} className={`center   py-4 px-4   leading-none text-white hmvs-bg-neutaral-medium font-semibold rounded shadow ${newGroup ? "hover:bg-indigo-700" : ""}`} >New Group</button></div> */}
                </div>
                <div className="px-5">
                  <button
                    id="joinGroup"
                    onClick={handleNewOrJoinChanged}
                    style={{ height: "50px", width: "90px" }}
                    className="inline-block hmvs-font leading-none text-white hmvs-bg-dark font-semibold rounded shadow"
                  >
                    Join Group
                  </button>
                  {/* <div className=""> <button onClick={handleNewOrJoinChanged} id="joinGroup" className={`center   py-4 px-4   leading-none text-white hmvs-bg-neutaral-medium font-semibold rounded shadow`} >Join Group</button></div> */}
                </div>
              </div>
              {/* <div className="mt-2"> <button onClick={(e) => { e.preventDefault(); setHidOwnership(false); setHideFullOrPartial(false); setJoinGroup(false); setNewGroup(false); setHideGroupChoice(false); setParialStaker(false); setHideGroupCodeInput(false); setGroupCodeFinder(false) }} className={`center   py-4 px-4   leading-none text-white hmvs-bg-neutaral-medium font-semibold rounded shadow`} >Go Back</button></div> */}
            </div>
          </fieldset>
        </div>

        <div className="w-full ">
          <div id="newGroup" hidden={!newGroup}>
            <p className="text-lg hmvs-font text-left mb-4">
              Please create a name for your validator and enter the amount you
              want to stake.
            </p>
            <div className="flex flex-wrap">
              <div className="mb-4 ">
                <input
                  style={{ height: "50px", width: "110px" }}
                  className="appearance-none text-center leading-tight text-gray-700 bg-gray-50 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
                  type="text"
                  placeholder="Validator Name"
                  name="ValidatorNamePartial"
                  id="ValidatorNamePartial"
                  onChange={(e) => setFormData({...formData, name: (e.target as any).value as string})}
                />
              </div>
              <div className="mb-4 mx-1">
                <input
                  style={{ height: "50px", width: "110px" }}
                  className="appearance-none text-center leading-tight text-gray-700 bg-gray-50 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
                  type="text"
                  placeholder="Stake Amount"
                  name="StakeAmountPartial"
                  id="StakeAmountPartial"
                  onChange={(e) => setFormData({...formData, amount: Number.parseFloat((e.target as any).value as string) || 0.0})}
                />
              </div>

              <div className="mt-2 ">
                <button
                  className={`center hmvs-font  py-3 px-8 leading-none text-white hmvs-bg-dark text-lg font-semibold rounded shadow ${
                    formEnabled ? "hover:hmvs-bg-dark " : ""
                  }`}
                  disabled={!formEnabled}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          <div hidden={!showPickPublicOrPrivateGroup}>
            <p className="text-lg mb-4 hmvs-font ">
              Do you want this group to be public or private?
            </p>
            <fieldset disabled={!formEnabled} id="rgPrivateOrPublic">
              <div className="flex flex-wrap">
                <div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setNewGroup(true);
                      setShowPickPublicOrPrivateGroup(false);
                      setFormData({...formData, publicOrPrivate: "PRIVATE"})
                    }}
                    style={{ height: "50px", width: "90px" }}
                    id="privateGroup"
                    className={`inline-block hmvs-font leading-none text-black bg-white font-semibold rounded shadow ${
                      isPrivateGroup ? "hover:bg-indigo-700" : ""
                    }`}
                  >
                    Private
                  </button>
                </div>
                <div className="px-3">
                  <button
                    id="publicGroup"
                    onClick={(e) => {
                      e.preventDefault();
                      setNewGroup(true);
                      setShowPickPublicOrPrivateGroup(false);
                      setFormData({...formData, publicOrPrivate: "PUBLIC"})
                    }}
                    style={{ height: "50px", width: "90px" }}
                    className="inline-block hmvs-font leading-none text-black bg-white font-semibold rounded shadow"
                  >
                    Public
                  </button>
                </div>
              </div>
              {/* <div className="flex">
                                    <div className="mt-2"> <button onClick={(e) => { e.preventDefault(); setpublicOrPrivateGroup(false); setHidOwnership(false); setHideFullOrPartial(false); setJoinGroup(false); setNewGroup(false); setHideGroupChoice(false); setParialStaker(false); setHideGroupCodeInput(false); setGroupCodeFinder(false) }} className={`center   py-4 px-4   leading-none text-white hmvs-bg-neutaral-medium font-semibold rounded shadow`} >Go Back</button></div>

                                </div> */}
            </fieldset>
          </div>
        </div>

        <div
          id="joinGroup"
          className="w-full max-w-md mx-auto"
          hidden={!joinGroup}
        >
          <div hidden={hideGroupCodeInput}>
            <p className="text-xl mb-4 hmvs-font font-semibold  ">
              {props.joiningPrivate ? "" : ""}Please enter the group code
            </p>
            <div className="mb-4">
              <input
                className="appearance-none hmvs-font block py-3 px-4 leading-tight text-gray-700 bg-gray-50 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
                type="text"
                placeholder="1ABCD3"
                name="GroupCode"
                id="GroupCode"
                value={groupCodeInputValue}
                onChange={(e) => { setFormData({...formData, groupCode: (e.target as any).value as string}); handleGroupCode(e)}}
              />
              <div className="py-4 flex flex-wrap ">
                <div hidden={!showGroupCodePreviewResults} className="">
                  {" "}
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setJoinGroup(false);
                      setNewGroup(false);
                      setHideGroupChoice(false);
                      setPartialStaker(true);
                    }}
                    className={`center hmvs-font  py-4 px-4   leading-none text-white hmvs-bg-neutaral-medium font-semibold rounded shadow`}
                  >
                    Go Back
                  </button>
                </div>

                {/* <button
                  id="groupCodeFinderdiv"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="inline-block py-4 px-8 mx-4 leading-none text-white hmvs-bg-dark font-semibold rounded shadow"
                >
                  Check
                </button> */}
              </div>
            </div>
          </div>

          <div hidden={!noMatchingGroupFound}>
              <p className="hmvs-font">We didn't find a group code that matched that input. Make sure it's correct and try again.</p>
          </div>
          <div hidden={!groupCodeValidatorError}>
              <p className="hmvs-font">Group codes should be exactly 8 characters long.</p>
          </div>

          <div id="groupCode" hidden={!showGroupCodePreviewResults}>
            
            <div className="w-full  max-w-md mx-auto" hidden={groupPreview === undefined}>
              <p className="text-lg hmvs-font mb-2 font-semibold font-heading text-left ">
                Group Details
              </p>
              <p className="text-white hmvs-font text-left">
                Group Name: {groupPreview?.getName() || ""}{" "}
              </p>
              <p className="text-white hmvs-font text-left">
                Pledged Amount:{" "}
                {Helpers.getHntFromHeliumAmount(
                  groupPreview?.getStakedamount()
                )}{" "}
                HNT
              </p>
              <p className="text-white hmvs-font text-left">
                Members: {groupPreview?.getStakercount()}{" "}
              </p>

              {/* <div className="table-responsive mb-8 lg:mb-0">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th><p>Group Name</p></th>
                                                <th><p>Setup Status</p></th>
                                                <th><p>Staked Amount</p></th>
                                                <th><p>Members</p></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>{groupPreview?.getName() || ""}</th>
                                                <th>Pending memebers</th>
                                                <th>{Helpers.getHntFromHeliumAmount(groupPreview?.getStakedamount())} HNT</th>
                                                <th>{groupPreview?.getStakercount()}</th>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div> */}

              <div className="">
                <p className="text-lg hmvs-font font-semibold mb-4 mt-4 text-left">
                  Enter Stake Amount
                </p>
                <div className="flex flex-wrap">
                  <div>
                    <input
                      style={{ height: "50px", width: "100px" }}
                      className="appearance-none block  text-center leading-tight text-gray-700 bg-gray-50 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
                      type="text"
                      placeholder="Stake Amount"
                      name="StakeAmount"
                      onChange={(e) => setFormData({...formData, amount: Number.parseFloat((e.target as any).value as string) || 0.0})}
                    />
                  </div>
                  <div className="px-4">
                    <button
                      style={{ height: "50px", width: "90px" }}
                      className={`center inline-block hmvs-font leading-none text-white hmvs-bg-dark text-lg font-semibold rounded shadow ${
                        formEnabled ? "hover:hmvs-bg-dark " : ""
                      }`}
                      disabled={!formEnabled}
                    >
                      Next
                    </button>
                  </div>
                  {/* <div className="mt-4"> <button onClick={(e) => { e.preventDefault(); setJoinGroup(true); setNewGroup(false); setHideGroupChoice(false); setParialStaker(false); setHideGroupCodeInput(false); setGroupCodeFinder(false) }} className={`center   py-4 px-4   leading-none text-white hmvs-bg-neutaral-medium font-semibold rounded shadow`} >Go Back</button></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div hidden={!showQRCode}>
          <DepositInstructionsInnerContent stakeInstance={lastCreatedStakeInstance!}/>
        </div> 
      </form>
    </div>
  );
};

export default ValidatorSetup;
