import { HLMVSClientFactory, protoTypes } from "@hlmvs/hlmvs-client";

export class LinkHelpers {
    public static getExplorerLinkForWallet(address: string | protoTypes.B58PubKey): string {
        let addrStr: string = "";
        if (typeof address !== 'string') {
            addrStr = address.getAddress()
        } else {
            addrStr = address;
        }
        if (HLMVSClientFactory.isTest() || HLMVSClientFactory.isLocal()) {
            return `https://explorer.helium.wtf/accounts/${addrStr}`;
          }
          return `https://explorer-beta.helium.com/accounts/${addrStr}`;
    }

    public static getExplorerLinkForValidator(validator: string | protoTypes.B58PubKey): string {
        let addrStr: string = "";
        if (typeof validator !== 'string') {
            addrStr = validator.getAddress()
        } else {
            addrStr = validator;
        }
        if (HLMVSClientFactory.isTest() || HLMVSClientFactory.isLocal()) {
            return `https://explorer.helium.wtf/validators/${addrStr}`;
        }
        return `https://explorer-beta.helium.com/validators/${addrStr}`;
    }
}