import { Client, Network } from '@helium/http';
import React from 'react';
import { Helpers } from '../libs/helpers';

export const HeliumExplorerStats: React.FunctionComponent<{  }> = () => {

    const [onlineValCount, setOnlineValCount] = React.useState(0);
    const [blockHeight, setBlockHeight] = React.useState(0);
    // const [hotspotCount, setHotspotCount] = React.useState(0);
    const [oraclePrice, setOraclePrice] = React.useState(0);

    const heliumClient = new Client(Helpers.useTestnet() ? Network.testnet : Network.production, {
      userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.69 Safari/537.36"
    });

    React.useEffect(() => {

        heliumClient.stats.get().then(stats => {
            setOnlineValCount(stats.counts.validators);
            setBlockHeight(stats.counts.blocks);
            // setHotspotCount(stats.counts.hotspots);
            // stats.counts.


        });

        heliumClient.oracle.getCurrentPrice().then(current => {
          if (current && current.price) {
            setOraclePrice(current.price.floatBalance);
          }
        });

    //    heliumClient.
    });


    return (
      <div className="">
          <section className="mb-2">
              <p className="hmvs-text-primary ml-2 text-xl">Helium Validator Stats</p>
              <div className="bg-black  rounded py-3 shadow text-center mb-2 mr-2 ml-2 ">
                  <p className="hmvs-text-primary lg:text-2xl">August 1st, 2021</p>
                  <p className="text-white mt-1 lg:text-2xl">Rewards Halving Date</p>
              </div>
              <div className=" hmvs-font grid text-white grid-cols-2 gap-1 px-2">
                  <div className="  bg-black py-3  rounded  shadow text-center">
                    <p className="text-base hmvs-text-primary lg:text-2xl">{blockHeight}</p>
                    <p className="text-sm mt-1 lg:text-2xl">Current Block Height</p>
                  </div>
                  <div className="bg-black py-3 rounded shadow text-center">
                    <p className="text-base hmvs-text-primary lg:text-2xl">{onlineValCount}</p>
                    <p className="text-sm mt-1 lg:text-2xl">Online Validators</p>
                  </div>
                  <div className="bg-black py-3 rounded shadow text-center"> 
                    <a href="/#/ValidatorStatsMain">
                        <p className="text-base hmvs-text-primary lg:text-2xl">13</p>
                        <p className="text-sm mt-1 lg:text-2xl text-white">Vanager Validators</p>
                    </a>

                  </div>
                  {/* <div className="bg-black py-1 rounded shadow text-center">
                    <p className="text-sm mt-1 lg:text-2xl">Lifetime Earnings</p>
                    <p className="text-base hmvs-text-primary lg:text-2xl">test</p>
                  </div> */}
                  <div className=" bg-black py-3 rounded shadow text-center">
                    <p className="text-base hmvs-text-primary lg:text-2xl">${oraclePrice}</p>

                    <p className="text-sm mt-1 lg:text-2xl">Market Price</p>
                  </div>
              </div>
             
          </section>
   
      </div>
    )
  }

export default HeliumExplorerStats;