import * as React from "react";
import { Helpers } from "../libs/helpers";
import { protoTypes } from "@hlmvs/hlmvs-client";
import { AuthContext, AuthStatus } from "../contexts/authContext";
import { useContext, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Line } from 'rc-progress';
import DepositInstructionsModal from "./despositInstructionsModal";
import SectionalCard from "./sectionalCard";
import {Menu, Button, MenuItem} from '@material-ui/core';
import { LinkHelpers } from '../libs/link-helpers';
// import {
  // EmailShareButton,
  // EmailIcon,
  // FacebookMessengerShareButton,
  // FacebookMessengerIcon,
//   RedditShareButton,
//   RedditIcon,
//   TwitterShareButton,
//   WhatsappShareButton,
//   WhatsappIcon,
//   TwitterIcon
// } from "react-share";
// import SetupFlow from "./setupflow";
import {Tooltip} from '@material-ui/core'
import NumberFormat from "react-number-format";
// import {CountdownCircleTimer} from 'react-countdown-circle-timer';
import Countdown from 'react-countdown'; 




const StakeInstanceCardPrivate: React.FunctionComponent<{
  stakeInstance?: protoTypes.StakeInstance;
  publicStakeInstance?: protoTypes.PublicStakeInstance;
  onUnstake?: () => void;
}> = (props) => {
  const countdown = React.createRef<Countdown>();
  const authContext = useContext(AuthContext);
//   const [hideDepositWallet, setHideDepositWallet] = useState(true);
  const [showQRCode, setShowQRCode] = useState(false);
  const [showGroupCode, setshowGroupCode] = useState(false);
  const [showUnstake, setShowUnstake] = useState(false);
  const [unstaking, setUnstaking] = useState(false);
  const [unstakingError, setUnstakingError] = useState(false);
  const [stakeInstance] = useState(props.stakeInstance);
  // const [doesUserStillOweMoney, setDoesUserStillOweMoney] = React.useState(false);
  // const [userStillOwesAmountHnt, setUserStillOwesAmountHnt] = React.useState(0.0);
  const [anchorEl, setAnchorEl] = React.useState(null);

//   const [showNewStakedInstance, setShowNewStakedInstance] = useState(false);
//   const [joiningGroupCode, setJoiningGroupCode] = React.useState("");
//   const [joiningPrivate, setJoiningPrivate] = React.useState(false);
  // const [hcs, sethcs] = useState(false); 

//   const [cardMode, setCardMode] = React.useState<"DEPOSIT" | "STAKING" | "JOINING">("DEPOSIT")




  

  const reallyUnstake = (e: React.FormEvent) => {
    if (!stakeInstance) {
      return;
    }
    setUnstaking(true);
    setUnstakingError(false);
    Helpers.unstake(stakeInstance.getStakeinstanceid(), authContext).then(result => {
      setUnstaking(false);
      setShowUnstake(false);
      setUnstakingError(false);
      if (props.onUnstake) {
        props.onUnstake();
      }
    }).catch(err => {
      setUnstakingError(true);
      setUnstaking(false);
      setShowUnstake(false);
      console.log(err);
    })
    console.log(unstaking)
    console.log(unstakingError)
  }


  const hideUnstake = (e: React.FormEvent) => {
    setShowUnstake(!showUnstake)
  }

  const handleShowGroupCode = (e: React.FormEvent) => {
    setshowGroupCode(!showGroupCode)
  }

  const getMyOnwership = (stakeInstance?: protoTypes.StakeInstance) => {
    if (authContext.authStatus !== AuthStatus.SignedIn) {
      return undefined;
    }
    return stakeInstance?.getCallerownerrecord();
  }

  const getOwnershipPercentage = (stakeInstance?: protoTypes.StakeInstance) => {
    if (!stakeInstance) {
      return 0;
    }
    const myOwnership = getMyOnwership(stakeInstance);

    if (myOwnership === undefined) {
      return 0;
    }
    const pct = myOwnership.getPercentageownership();
    if (pct === undefined) {
      return 0;
    }
    return pct;
  };



  const getPendingPaymentState = () => {
    if (!stakeInstance) {
      return;
    }
    // const owedAmount = Helpers.getHntFromHeliumAmount(stakeInstance.getCallerownerrecord()?.getStakedamount()) - Helpers.getHntFromHeliumAmount(stakeInstance.getCallerownerrecord()?.getDepositwalletamount());
    // const userStillOwesMoney = owedAmount > 0;
    // setUserStillOwesAmountHnt(owedAmount);
    // setDoesUserStillOweMoney(userStillOwesMoney);
    // if (userStillOwesMoney) {
    //   setHideDepositWallet(false);
    // } else {
    //   setHideDepositWallet(true);
    // }
  };

  const getStakeOwnerDepositWallet = (
    stakeInstance?: protoTypes.StakeInstance
  ) => {
    return getMyOnwership(stakeInstance)?.getOwnerstakedepositwalletaddress();
  };

  React.useEffect(() => {
    getPendingPaymentState();
    
     

    // eslint-disable-next-line
  }, []);

  const timerIsHidden = (stakeInstance?: protoTypes.StakeInstance) => {

    return Helpers.getHntFromHeliumAmount(stakeInstance?.getCallerownerrecord()?.getDepositwalletamount()) >= Helpers.getHntFromHeliumAmount(stakeInstance?.getCallerownerrecord()?.getStakedamount()) 
      
  }

  const getHeaderCardColor = (state: protoTypes.StakeInstanceState) => {
    switch (state) {
      case protoTypes.StakeInstanceState.STAKE_INSTANCE_STATE_LIVE:
        return "hmvs-bg-dark";
      case protoTypes.StakeInstanceState.STAKE_INSTANCE_STATE_PENDING_PAYMENTS:
        return "bg-white ";
      case protoTypes.StakeInstanceState
        .STAKE_INSTANCE_STATE_PAYMENTS_RECEIVED_PENDING_SETUP:
      case protoTypes.StakeInstanceState
        .STAKE_INSTANCE_STATE_PENDING_STAKE_VALIDATION:
        return "hmvs-bg-dark";
      case protoTypes.StakeInstanceState
        .STAKE_INSTANCE_STATE_INITIAL:
        return "bg-white"
      default:
        return "hmvs-bg-darker";
    }
  };


  const hcs = (e: any) => {
    setAnchorEl(e.currentTarget);

  };

  const hcs_close = () =>{
    setAnchorEl(null);

  }

    const headerName = (<div className={`rounded-xl   rounded-b-none items-center ${getHeaderCardColor(stakeInstance?.getStakeinstancestate() || protoTypes.StakeInstanceState.STAKE_INSTANCE_STATE_INITIAL )}`}>
    <div className="flex py-4 px-5   ">
      <div className="flex-col text-black w-3/4 ">
        <p className=" text-base mt-2 font-bold text-black">{stakeInstance?.getName()?.toString() || props.publicStakeInstance!.getName()!.toString()!}</p>
      </div>
      {/* <div className="px-24"></div> */}
      <div  className="flex-col pl-10 mt-2 ">
        
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={hcs}>
          <p className="text-black font-bold rounded  " style={{fontSize:"8px"}}>o o o</p>
        </Button>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={hcs_close}>
          <Tooltip title="Share your Group Code with friends so that they can easily find your validator">
            <MenuItem onClick={() => {setshowGroupCode(true); hcs_close()}}>Group Code</MenuItem>
          </Tooltip>
          <Tooltip title="See your unique deposit wallet on Helium Explorer">
            <MenuItem><a
          className="hmvs-text-dark "
          target="_blank"
          rel="noreferrer"
          href={LinkHelpers.getExplorerLinkForWallet((getStakeOwnerDepositWallet(stakeInstance)?.getAddress() || ""))}
        >
          <p className="text-gray-800">Deposit Wallet</p>
          
        </a></MenuItem>
          </Tooltip>
          <Tooltip title="Once you leave the group your deposit will be returned to you">
            <MenuItem onClick={() => {setShowUnstake(true); hcs_close()}}>Leave Group</MenuItem>
          </Tooltip>
          

           
        </Menu>
      </div>
      
    </div>
    <div style={{ border: "1px solid white" }} />
  </div>)




// const yourDeposits = (
    
//   <div className="mt-5">
//     <p className=" text-white text-sm">Your deposits: <p className="font-bold">{Helpers.getHntFromHeliumAmount(stakeInstance?.getCallerownerrecord()?.getDepositwalletamount())}/{Helpers.getHntFromHeliumAmount(stakeInstance?.getCallerownerrecord()?.getStakedamount())} HNT</p></p>
//     <Line strokeLinecap="square" className="mt-3" percent={Helpers.getHntFromHeliumAmount(stakeInstance?.getCallerownerrecord()?.getDepositwalletamount()) / Helpers.getHntFromHeliumAmount(stakeInstance?.getCallerownerrecord()?.getStakedamount()) * 100} strokeWidth={4} strokeColor="#57E87F" />
//   </div>
// )


  const bodyDeposit = (<div>


    <div className="flex mx-3 mt-4 " style={{ minHeight: "170px", maxHeight: "170px" }}>
      <div className="flex-col lg:px-2 w-3/4">
        <p className="text-white text-sm">Amount pledged: <p className="font-bold"> <NumberFormat displayType={'text'} value={Math.trunc(Helpers.getHntFromHeliumAmount(props.publicStakeInstance ? props.publicStakeInstance?.getStakedamount() : stakeInstance?.getPledgedamount()))} thousandSeparator={true} />/10,000 HNT </p></p>
        {/* {Helpers.getHntFromHeliumAmount(props.publicStakeInstance ? props.publicStakeInstance?.getStakedamount() : stakeInstance?.getPledgedamount())} */}

        <Line strokeLinecap="square" className="mt-3" percent={(Helpers.getHntFromHeliumAmount(props.publicStakeInstance ? props.publicStakeInstance?.getStakedamount() : stakeInstance?.getPledgedamount()) / 10000) * 100} strokeWidth={4} strokeColor="#57E87F" />
        <div className="py-3"></div>
        <p className=" text-white text-sm">Validator deposits: <p className="font-bold">        <NumberFormat displayType={'text'} value={Math.trunc(Helpers.getHntFromHeliumAmount( props.publicStakeInstance ? props.publicStakeInstance?.getDepositedamount() : props.stakeInstance?.getTotaldepositamount()))} thousandSeparator={true} />/<NumberFormat displayType={'text'} value={Math.trunc(Helpers.getHntFromHeliumAmount(props.publicStakeInstance ? props.publicStakeInstance?.getStakedamount() : props.stakeInstance?.getPledgedamount()))} thousandSeparator={true} /> HNT</p> </p>

        {/* <NumberFormat displayType={'text'} value={Math.trunc(Helpers.getHntFromHeliumAmount( props.publicStakeInstance ? props.publicStakeInstance?.getDepositedamount() : props.stakeInstance?.getTotaldepositamount()))} thousandSeparator={true} />/<NumberFormat displayType={'text'} value={Math.trunc(Helpers.getHntFromHeliumAmount(props.publicStakeInstance ? props.publicStakeInstance?.getStakedamount() : props.stakeInstance?.getPledgedamount()))} thousandSeparator={true} /> */}

        <Line strokeLinecap="square" className="mt-3" percent={(Helpers.getHntFromHeliumAmount(props.publicStakeInstance ? props.publicStakeInstance.getDepositedamount() : props.stakeInstance?.getTotaldepositamount()) / Helpers.getHntFromHeliumAmount(props.publicStakeInstance ? props.publicStakeInstance!.getStakedamount() : props.stakeInstance?.getPledgedamount())) * 100} strokeWidth={4} strokeColor="#57E87F" />
        <div className="mt-5">
        <p className=" text-white text-sm">Your deposits: <p className="font-bold"><NumberFormat displayType={'text'} value={Math.trunc(Helpers.getHntFromHeliumAmount(stakeInstance?.getCallerownerrecord()?.getDepositwalletamount()))} thousandSeparator={true} />/<NumberFormat displayType={'text'} value={Math.trunc(Helpers.getHntFromHeliumAmount(stakeInstance?.getCallerownerrecord()?.getStakedamount()))+.5} thousandSeparator={true} /> HNT</p></p>

        {/* {Helpers.getHntFromHeliumAmount(stakeInstance?.getCallerownerrecord()?.getDepositwalletamount())}
        {Helpers.getHntFromHeliumAmount(stakeInstance?.getCallerownerrecord()?.getStakedamount())} */}
        <Line strokeLinecap="square" className="mt-3" percent={Helpers.getHntFromHeliumAmount(stakeInstance?.getCallerownerrecord()?.getDepositwalletamount()) / Helpers.getHntFromHeliumAmount(stakeInstance?.getCallerownerrecord()?.getStakedamount()) * 100} strokeWidth={4} strokeColor="#57E87F" />
        </div>
      </div>

      <div className="px-4 lg:px-3"></div>
      <div  className="flex-col px-3 lg:w-1/4">
        <p className=" text-sm text-white">Ownership</p>
        <p className="text-white text-xl font-bold text-center">{getOwnershipPercentage(stakeInstance).toFixed(2)}%</p>
        <div className="py-5 lg:py-5"></div>
        {props.publicStakeInstance ? 
        <div>
          <p className="text-white hmvs-font">Members</p>
          <p className="text-white hmvs-font"> {props.publicStakeInstance?.getStakercount().toString()}</p>

          
        </div>
        : <div></div>}
        <div className="py-5 lg:py-5"></div>
        <div hidden={timerIsHidden(stakeInstance)}>
        
              <Tooltip title="Deposits that are unfulfilled for more than 48 hours automatically expire." >
                <div className="mt-3">
                  <p className=" text-sm text-white">Deposit Countdown</p>
                  <p className="text-white font-bold text-sm"><Countdown ref={countdown} autoStart={true} controlled={false} date={new Date((props.stakeInstance?.getCallerownerrecord()?.getPledgeexpiration()?.getSeconds() || 0) * 1000)} /></p>
                </div> 
              </Tooltip>
          
              <div></div>
         
        </div>

      </div>

  

    </div>

  </div>

  )



  
  

  const footerDeposit =(<div>
    <div  className="flex "  >
      <div className="flex-col mt-4 lg:w-3/4">
        <button className=" rounded hmvs-button-primary hover:bg-white shadow " onClick={() => setShowQRCode(true)}>Deposit Funds</button>
      </div>
      <div className="px-3"></div>
      <div className="flex-col mt-4 lg:w-3/4">
        <button style={{height: "40px", width: "120px"}}className=" rounded  bg-red-500 hover:bg-white shadow " onClick={() => {setShowUnstake(true)}}>Leave Group</button>
      </div>

      
     

    </div>

  </div>)








  return (
    <div className="flex-wrap">
      <SectionalCard header={headerName} body={bodyDeposit} footer={footerDeposit} />
      {/* <SetupFlow showModal={showNewStakedInstance} onClose={() => setShowNewStakedInstance(false)}  onSaveSuccessful={validatorSaveSuccessful} prePopulatedGroupCode={joiningGroupCode === "" ? undefined : joiningGroupCode} joiningPrivate={joiningPrivate} /> */}


      


        <div hidden={!showGroupCode} className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>


            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>



            <div className="inline-block align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-black px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <img alt="Vanager Logo" src="images/V.png" className="mx-16 rounded"></img>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left ">
                    <div className="flex flex-wrap">
                      <p className="text-lg hmvs-font leading-6 font-medium text-white" id="modal-title">
                        The group code for {props.stakeInstance?.getName().toString()} is



                        <button className="ml-2 shadown  inline rounded leading-6 lg:text-lg text-white hover:bg-yellow-500"><CopyToClipboard text={props.stakeInstance?.getPartialownershipgroupcode()?.toString() || ""}><p> {props.stakeInstance?.getPartialownershipgroupcode().toString()} <img src="images/copyicon.svg" alt="copy" className="inline" style={{ width: "25px", height: "25px" }}></img></p></CopyToClipboard></button>  </p>
                      <span></span>
                    </div>

                  </div>
                </div>
              </div>
              <div className="bg-black px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button type="button" onClick={handleShowGroupCode} className="rounded hmvs-button-primary hover:bg-white shadow">
                  Close
                  </button>
              </div>
            </div>
          </div>
        </div>

        <div hidden={!showUnstake} className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center  pt-4 px-4 pb-20 text-center sm:block sm:p-0">

            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>


            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>



            <div className="inline-block align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-black px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <p className="text-lg hmvs-font leading-6 font-medium text-white" id="modal-title">
                      Warning! You are about to leave group: {props.stakeInstance?.getName()?.toString()}. Once this has been initiated the money will be refunded to your HNT wallet.

                      </p>

                  </div>
                </div>
              </div>
              <div className="bg-black px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button type="button" onClick={reallyUnstake} className="rounded hmvs-button-primary hover:bg-white shadow">
                  Leave
                  </button>
                <button type="button" onClick={hideUnstake} className="rounded mx-4 hmvs-button-secondary hover:bg-gray-600 shadow">
                  Close
                  </button>
              </div>
            </div>
          </div>
        </div>

        { stakeInstance ? <DepositInstructionsModal stakeInstance={stakeInstance} showQRCode={showQRCode} onClose={() => setShowQRCode(false)} /> : <div></div> }

      </div>




  );
};

export default StakeInstanceCardPrivate;