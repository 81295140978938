import { protoTypes } from "@hlmvs/hlmvs-client";
import React from "react";
import DepositInstructionsInnerContent from "./depositInstructionsInnerContent";

export const DepositInstructionsModal: React.FunctionComponent<{ showQRCode: boolean, stakeInstance: protoTypes.StakeInstance, onClose: () => void }> = (props) => {
  return (
    <div hidden={!props.showQRCode} className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>


        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>



        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-black px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <DepositInstructionsInnerContent stakeInstance={props.stakeInstance}/>
          </div>
          <div className="bg-black px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

            {/* <button type="button" onClick={props.onClose} className="mt-3 w-full  inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Close
              </button> */}

            <button type="button" onClick={props.onClose} className="mt-3 w-full  hmvs-button-secondary hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Close
            </button>              
          </div>
        </div>
      </div>
    </div>
  )
}

export default DepositInstructionsModal;