export const ToS: React.FunctionComponent = () => {
    return (
        <div>
            <section className="ml-4 mr-4"> 
                <div>
                    <p className="text-white text-5xl">Terms of Service</p>
                </div>
                <div className="py-4 ">
                    <p className="text-2xl font-bold hmvs-text-darkAlt"> Capacity and Status</p>
                    <p className="text-white py-2 px-4">If an individual, I am of legal age under the laws of the state where I reside and authorized to enter into this Agreement. If an entity, I am duly formed, validly existing and in good standing in My state of organization, have full power and authority to enter and perform this Agreement, and the persons signing the account application are fully authorized to act on My behalf. No person, except Myself, has any interest in the Account opened pursuant to this Agreement.</p>
                </div>
                <div className="py-4 ">
                    <p className="text-2xl font-bold hmvs-text-darkAlt"> Risks</p>
                    <p className="text-white py-2 px-4">I understand that all crypto mining involve risk and that I may incur losses.</p>
                </div>
                <div className="py-4 ">
                    <p className="text-2xl font-bold hmvs-text-darkAlt"> Assistance by Vanager LLC</p>
                    <p className="text-white py-2 px-4">I understand that when I request assistance from Vanager LLC or its employees in using the investment tools available on the Website or the App, it will be limited to an explanation of the tool's functionality and, if requested by Me, to the entry by Vanager LLC or its employees of variables provided by Me, and that such assistance does not constitute investment advice, an opinion with respect to the suitability of any transaction, or solicitation of any orders.</p>
                </div>
                <div className="py-4 ">
                    <p className="text-2xl font-bold hmvs-text-darkAlt"> No Tax or Legal Advice</p>
                    <p className="text-white py-2 px-4">I understand that Vanager LLC does not provide tax or legal advice.</p>
                </div>
                <div className="py-4 ">
                    <p className="text-2xl font-bold hmvs-text-darkAlt">Discontinuation of Services</p>
                    <p className="text-white py-2 px-4">I understand that Vanager LLC may discontinue My Account and any services related to My Account immediately by providing written notice to Me.</p>
                </div>
                <div className="py-4 ">
                    <p className="text-2xl font-bold hmvs-text-darkAlt">Tax Reporting; Tax Withholding</p>
                    <p className="text-white py-2 px-4">The proceeds of mining transactions will not be reported by Vanager LLC. This is the sole responsibility of Me. </p>
                </div>
                <div className="py-4 ">
                    <p className="text-2xl font-bold hmvs-text-darkAlt">Fees and Charges</p>
                    <p className="text-white py-2 px-4">I understand that Vanager LLC does charge fees on mining validator mining activities. I also agree to pay all applicable federal, state, local, and foreign taxes. </p>
                </div>
                <div className="py-4 ">
                    <p className="text-2xl font-bold hmvs-text-darkAlt">Intellectual Property</p>
                    <p className="text-white py-2 px-4">My use of any API Products will not confer to Me any title, ownership interest or intellectual property rights that otherwise belongs to Vanager LLC. The API Package, including content, is protected under U.S. patent, copyright laws, international treaties or conventions, and other laws and will remain Vanager LLC's exclusive property, as applicable. Names, logos, and all related product and service names, design marks, and slogans displayed by or relating to Vanager LLC or any of its Affiliates or API Licensees in the context of the API Products shall remain the property of the respective owner, and use of such property by Vanager LLC or any API Licensee in marketing or provision of any API Products does not grant ownership of or entitle Me to use any such name or mark in any manner.</p>
                </div>
                <div className="py-4 ">
                    <p className="text-2xl font-bold hmvs-text-darkAlt">Electronic Signatures; Modifications to the Agreement</p>
                    <p className="text-white py-2 px-4">I agree to transact business with Vanager LLC electronically. By electronically signing an application for an Account, I acknowledge and agree that such electronic signature is valid evidence of My consent to be legally bound by this Agreement and such subsequent terms as may govern the use of Vanager LLC's services. The use of an electronic version of any document fully satisfies any requirement that the document be provided to Me in writing. I accept notice by electronic means as reasonable and proper notice, for the purpose of any and all laws, rules and regulations. I acknowledge and agree that Vanager LLC Financial may modify this Agreement from time to time and I agree to consult the Website from time to time for the most up-to-date Agreement. The electronically stored copy of this Agreement is considered to be the true, complete, valid, authentic and enforceable record of the Agreement, admissible in judicial or administrative proceedings to the same extent as if the documents and records were originally generated and maintained in printed form. I agree to not contest the admissibility or enforceability of Vanager LLC Financial's electronically stored copy of the Agreement</p>
                </div>
                <div className="py-4 ">
                    <p className="text-2xl font-bold hmvs-text-darkAlt">Amendment</p>
                    <p className="text-white py-2 px-4">Vanager LLC may at any time amend this Agreement without prior notice to Me. The current version of the Agreement will be posted on the Website and My continued Account activity after such amendment constitutes My agreement to be bound by all then-in-effect amendments to the Agreement, regardless of whether I have actually reviewed them. Continued use of the App, the Website or any other Vanager LLC Financial services after such posting will constitute My acknowledgment and acceptance of such amendment. I agree to regularly consult the Website for up-to-date information about Vanager LLC Financial services and any modifications to this Agreement. Vanager LLC is not bound by any verbal statements that seek to amend the Agreement.</p>
                </div>
                <div className="py-4 ">
                    <p className="text-2xl font-bold hmvs-text-darkAlt">International Customers</p>
                    <p className="text-white py-2 px-4">The products and services described on the Website are offered only in jurisdictions where they may be legally offered. Neither the Website nor the App shall be considered a solicitation for or offering of any investment product or service to any person in any jurisdiction where such solicitation or offering would be illegal. I understand that Vanager LLC, in its sole discretion, may accept unsolicited accounts from non- U.S. residents, depending on the country of residence and other factors. I understand that Vanager LLC is based in the United States and that Vanager LLC accepts only HNT in Vanager LLC's customer accounts.</p>
                </div>
                <div className="py-4 ">
                    <p className="text-2xl font-bold hmvs-text-darkAlt">Social Services and Forums</p>
                    <p className="text-white py-2 px-4">This Site may include interactive features, including, but not limited to, message boards, web logs, e-mail services, and areas that allow uploading of user-generated content (the "Interactive Features"). You are responsible for any material that you post on message boards or provide through any other Interactive Features on the Site.</p>
                    <p className="text-white py-2 px-4">We do not control the messages, information, or files that you or others may provide through the Site. This Site, including its Interactive Features, shall be used for lawful purposes only; you shall not:</p>
                    <p className="text-white py-2 px-9">1. Engage in any conduct that, in our sole judgment, restricts or inhibits any other user from using or enjoying the Site.</p>
                    <p className="text-white py-2 px-9">2. Post or transmit any material that violates our rights or the rights of others, including, without limitation, privacy rights, publicity rights, copyrights, trademark rights, patent rights, contract rights, or any other right.</p>
                    <p className="text-white py-2 px-9">3. Use the Site to impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity. </p>
                    <p className="text-white py-2 px-9">4. Interfere with or disrupt any servers or networks used to provide the Site, its Services, or its features, or disobey any requirements, procedures, policies, or regulations of the networks we use to provide the Site.</p>
                    <p className="text-white py-2 px-9">5. Use the Site in an unlawful, threatening, or abusive manner, including without limitation, to promote racism, bigotry, hatred, or physical harm of any kind against any group or individual. </p>
                    <p className="text-white py-2 px-9">6. Gain unauthorized access to the Site, or any account, computer system, or network connected to this Site, by means such as hacking, password mining, or other illicit means.</p>
                    <p className="text-white py-2 px-9">7. Obtain or attempt to obtain any materials or information not intentionally made available through this Site. </p>
                    <p className="text-white py-2 px-9">8. Use the Site to post or transmit any vulgar, obscene, or otherwise objectionable information of any kind, including without limitation, any transmissions that encourage conduct that would constitute a criminal offense, give rise to civil liability, or otherwise violate any law.</p>
                    <p className="text-white py-2 px-9">9. Use the Site to post or transmit any information, software, or other material that contains a virus or other harmful component.</p>
                    <p className="text-white py-2 px-9">10. Use the Site to post, transmit, or in any way exploit any information, software, or other material for commercial purposes, or that contains advertising.</p>
                    <p className="text-white py-2 px-9">11. Engage in advertising or commercial solicitation of any product or service, or to solicit donations of any kind on the Site, without our written consent. </p>
                    <p className="text-white py-2 px-9">12. Gather for marketing purposes any e-mail addresses or other personal information that has been posted by other users of the Site.</p>

                </div>
            </section>
            <section className="py-12">

      </section>
        </div>
    );
}

export default ToS;