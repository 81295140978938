export const BlogMain: React.FunctionComponent<{  }> = () => {
    return (
      <div className="px-4">
          <div className="py-4"></div>
          <div className="flex flex-wrap mb-5">
              <div className="w-3/4 px-1">
                <p className="text-white hmvs-font lg:text-xl">Validator Blog</p>
                <p className="text-white hmvs-font font-semibold text-xl lg:text-4xl">Vanager Managed Helium Validator Service</p>
                <p className="text-white hmvs-font mt-4 lg:text-2xl">All the latest from Vanager product and services</p>
              </div>
              <div className="w-1/4 px-1">
                <a href="https://discord.gg/dxD9yu9ssc"><p style={{maxWidth:"120px"}} className="text-center text-black hmvs-font rounded shadow hmvs-bg-primary">Join Discord</p></a>
              </div>
          </div>
          <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
          <div className="py-4">
            <a href="/#/HowToGuide" >
            <img alt="Letter V Logo" src="images/Vanager Logo2.svg" className="mx-auto rounded shadow" style={{ minHeight:"50px", minWidth:"50px"}} ></img>
            <p className="mt-2 text-white hmvs-font lg:text-xl">Vanager Validators</p>
            <p className="text-white hmvs-font font-semibold lg:text-4xl">How to stake a Helium Validator with Vanager</p>
            <p className="mt-4 text-white hmvs-font lg:text-2xl">Learn more about how to stake a Helium Validator anywhere and anytime with Vanager</p>
            <p className="mt-1 hmvs-text-primary hmvs-font lg:text-xl">READ ARTICLE</p>
            </a>
          </div>
          <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
          <div className="mt-2 py-2"><p className="text-white hmvs-font text-xl font-semibold lg:text-4xl">All the latest</p></div>
          <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
     
          <div className="mt-2 py-2"><a href="/#/HowToGuide" className="hmvs-font text-base text-white  hover:text-green-400 lg:text-2xl ">How to stake a Helium Validator with Vanager?</a></div>
          <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
          <div className="mt-2 py-2"><a href="/#/WhichStakingOptionIsRightForYou" className="hmvs-font text-base text-white  hover:text-green-400 lg:text-2xl">Which Staking option is right for you?</a></div>
          <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
          <div className="mt-2 py-2"><a href="/#/HowToCompleteYourStake" className="hmvs-font text-base  text-white hover:text-green-400 lg:text-2xl ">How to complete your stake?</a></div>
          <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
          <div className="mt-2 py-2"><a href="/#/HowToMineHeliumTokenWithValidators}" className="hmvs-font text-base  text-white   hover:text-green-400 lg:text-2xl">How to mine helium tokens with Validators?</a></div>
          <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
          <div className="mt-2 mb-2 py-2"><a href="/#/HowToUnstakeAHeliumValidator" className="hmvs-font text-base  text-white   hover:text-green-400 lg:text-2xl">How to unstake a Helium Validator?</a></div>
          <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
          <div className="mt-2 mb-2 py-2"><a href="/#/BLOG" className="hmvs-font text-base  text-white   hover:text-green-400 lg:text-2xl">Welcome to Vanager</a></div>

        <div className="py-4"></div>
      </div>
    )
  }

export default BlogMain;