import * as React from "react";


export const UserTotalValue: React.FunctionComponent<{}> = () => {
    return (
        <div className="">
         
            <div className="flex flex-wrap">
                <div className="w-3/4"> <p className="text-lg hmvs-text-primary font-bold">Total Value</p></div>
                {/* <div  className="w-1/4"><p className="hmvs-text-primary text-right text-sm font-bold"><button style={{height:"30px", width:"60px"}}className="hmvs-button-secondary rounded shadow" >Details</button></p> </div> */}
            </div>
            <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />    
            <div className="ml-28 lg:flex lg:flex-wrap hidden md:block mt-3 ">
                <div className="px-2 py-2 ">
                    <div className="h-full border rounded shadow hmvs-border-darkAlt">
                        <div className="flex items-center justify-between px-4 py-4 ">
                            <p className="text-sm font-semibold font-headin text-white ">Staked Value</p>
                        </div>
                        <div className="flex flex-wrap items-center justify-between px-4">
                            <div>
                                <p className="text-3xl font-semibold font-heading font-semibold text-white">$33,000</p>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center justify-between ">
                            <div>
                                <p className="  px-6 hmvs-text-dark">+$2,400 (24%)</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-14 lg:mb-0">
                    <p className="text-3xl font-semibold font-heading text-white">+</p>
                </div>
                <div className="px-2 py-2 ">
                    <div className="h-full border rounded shadow hmvs-border-darkAlt">
                        <div className="flex items-center justify-between px-4 py-4 ">
                            <p className="text-sm font-semibold font-headin text-white ">Gross Income</p>
                        </div>
                        <div className="flex flex-wrap items-center justify-between px-4">
                            <div>
                                <p className="text-3xl font-semibold font-heading font-semibold text-white" >$20,000</p>
                            </div>
                            {/* <div className=" " >
                                    <p className ="text-3xl  font-semibold font-heading font-semibold text-white" >$50,000</p>
                                  </div>                                  */}
                        </div>
                    </div>
                </div>
                <div className="py-14 lg:mb-0">
                    <p className="text-3xl font-semibold font-heading text-white">-</p>
                </div>

                <div className="px-2 py-2 ">
                    <div className="h-full border rounded shadow hmvs-border-darkAlt">
                        <div className="flex items-center justify-between px-4 py-4 ">
                            <p className="text-sm font-semibold font-headin text-white ">Service Fee</p>
                        </div>
                        <div className="flex flex-wrap items-center justify-between px-4">
                            <div>
                                <p className="text-3xl font-semibold font-heading font-semibold text-white" >$5,000</p>
                            </div>
                            {/* <div className=" " >
                                    <p className ="text-3xl  font-semibold font-heading font-semibold text-white" >$1,000</p>
                                  </div>                                  */}
                        </div>

                    </div>
                </div>

                <div className="py-14 lg:mb-0">
                    <p className="text-3xl font-semibold font-heading text-white">-</p>
                </div>

                <div className="px-2 py-2">
                    <div className="h-full border rounded shadow hmvs-border-darkAlt">
                        <div className="flex items-center justify-between px-4 py-4 ">
                            <p className="text-sm font-semibold font-headin text-white ">Server Cost</p>
                        </div>
                        <div className="flex flex-wrap items-center justify-between px-4">
                            <div>
                                <p className="text-3xl font-semibold font-heading font-semibold text-white" >$20,000</p>
                            </div>
                            {/* <div className=" " >
                                    <p className ="text-3xl  font-semibold font-heading font-semibold text-white" >$60,000</p>
                                  </div>                                  */}
                        </div>

                    </div>
                </div>

                <div className="py-14 lg:mb-0">
                    <p className="text-3xl font-semibold font-heading text-white">=</p>
                </div>

                <div className="px-2 py-2">
                    <div className="h-full border rounded shadow hmvs-border-darkAlt">
                        <div className="flex items-center justify-between px-4 py-4 ">
                            <p className="text-sm font-semibold font-headin text-white ">Account Value</p>
                        </div>
                        <div className="flex flex-wrap items-center justify-between px-4">
                            <div>
                                <p className="text-3xl font-semibold font-heading font-semibold text-white" >$20,000</p>
                            </div>
                            {/* <div className=" " >
                                        <p className ="text-3xl  font-semibold font-heading font-semibold text-white" >$20,000</p>
                                    </div>                                  */}
                        </div>
                    </div>

                </div>

            </div>


        <div className=" lg:hidden md:hidden ">
        <section className=" ">
            {/* <p className="hmvs-text-primary text-xl font-bold">Account Value</p>
            <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} /> */}
            <div className="flex text-white flex-wrap mt-2">
                <div className="w-1/2 text-left hmvs-font ">Staked Value </div>
                <div className="w-1/2 text-right hmvs-font">80,000 </div>
                <div className="w-1/2 text-left hmvs-font ">Gross Income </div>
                <div className="w-1/2 text-right hmvs-font ">+ 20,000 </div>
                <div className="w-1/2 text-left hmvs-font ">Server Expenses </div>
                <div className="w-1/2 text-right hmvs-font ">- 3,000 </div>
                <div className="w-1/2 text-left hmvs-font ">Service Fee </div>
                <div className="w-1/2 text-right hmvs-font ">- 3,000 </div>
            </div>
            <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
            <div className="flex text-white font-semibold flex-wrap mt-2">
                <div className="w-1/2 text-left hmvs-font ">Net Income </div>
                <div className="w-1/2 text-right hmvs-font ">80,000 </div>
            </div>

           
            
            </section>
        </div>
        </div>

    )
}

export default UserTotalValue;