import React from "react";
import ValidatorSetup from "./validatorSetup";

export const SetupFlow: React.FunctionComponent<{ rk?: string, onClose: () => void, prePopulatedGroupCode?: string, joiningPrivate?: boolean, onSaveSuccessful?: () => void, showModal: boolean, fullStaker?: boolean, newPartial?: boolean }> = (props) => {
    

    return (
        <div hidden={!props.showModal} className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div>
                <div className="flex items-end justify-center pt-4 px-4 pb-40 text-center sm:block sm:p-0">

                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>


                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div className="bg-black px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <img alt="Vanager Logo" src="images/V.png" className="mx-16"></img>

                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <span className="text-2xl hmvs-font leading-6 font-medium hmvs-text-darkAlt" id="modal-title">
                                        Validator Setup Process
            </span>
                                    <div className="mt-2">
                                        <ValidatorSetup rk={props.rk} prePopulatedGroupCode={props.prePopulatedGroupCode} joiningPrivate={props.joiningPrivate} fullStaker={props.fullStaker} newPartial={props.newPartial} onSaveSuccessful={props.onSaveSuccessful} />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-black px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

                            <button onClick={(e) => {  e.preventDefault(); props.onClose() }} type="button" className="mt-3 w-full inline-flex justify-center hmvs-font rounded border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                Close
        </button>
                        </div>
                    </div>
                </div>
            </div>)
        </div>
    )
}

export default SetupFlow;