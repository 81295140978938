export const SectionalCard: React.FunctionComponent<{ header: React.ReactElement, body: React.ReactElement, footer: React.ReactElement }> = (props) => {
    return (
        <div className="w-full flex-wrap px-4 lg:px-4 lg:mx-8" style={{maxHeight:"600px" , minHeight:"500px", minWidth:"150px", maxWidth:"400px"}}>
            <div className=" hmvs-bg-neutaral-dark border-transparent rounded-xl hmvs-one-edge-shadow flex-wrap   ">
                <div className={` rounded-b-none items-center`}>
                    {props.header}
                </div>
                <div style={{ border: "1px solid white" }} />
                <div style={{ maxHeight: "600px", minHeight: "240px" }}> 
                    {props.body}
                </div>
                <p style={{ border: "1px solid white" }} />
                <div className="mx-5 mt-4" style={{ maxHeight: "80px", minHeight: "80px" }}>
                    {props.footer}
                </div>
                
            </div>
        <div className="py-2"></div>
        </div>
    )
}

export default SectionalCard;