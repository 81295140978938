import React from 'react'
import './App.css'
//import Ticker, { FinancialTicker, NewsTicker } from '@hlmvs/nice-react-ticker';

import { HashRouter as Router, Route, Switch } from 'react-router-dom'

import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from './contexts/authContext'

import SignIn from './routes/auth/signIn'
import SignUp from './routes/auth/signUp'
import VerifyCode from './routes/auth/verify'
import RequestCode from './routes/auth/requestCode'
import ForgotPassword from './routes/auth/forgotPassword'
import ChangePassword from './routes/auth/changePassword'
import Landing from './routes/landing'
// import Home from './routes/home'
import UserProfile from './routes/userProfile'
import Estimator from './components/estimatorComponent'
import NavBar from './components/navBar'
import FAQ from './components/faq'
import PublicGroups from './components/publicGroups'
import ToS from './components/TermsOfService'
import Footer from './components/Footer'
import BLOG from './components/Blog'
import UserPayments from './components/UserPayments'
import { SignOut } from './components/authComponents'
import ContactUs from './components/contactus'
import HowToGuide from './components/HowToGuide'
import WhichStakingOptionIsRightForYou from './components/WhichStakingOptionIsRightForYou'
import HowToUnstakeAHeliumValidator from './components/HowToUnstakeAHeliumValidator'
import HowToMineHeliumTokenWithValidators from './components/HowToMineHeliumTokenWithValidators'
import HowToCompleteYourStake from './components/HowToCompleteYourStake'
import BlogMain from './components/BlogMain'
import StakeInstanceDetailedActivity from './components/StakeInstanceDetailedActivity'
import userValidatorPosition from './components/userValidatorPosition'
import userTotalValue from './components/userTotalValue'
import UserProfileBreakdown from './components/UserProfileBreakdown'
import ValidatorStatsMain from './components/ValidatorStatsMain'

let lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    background: {
      default: "#262626"
    }
  },

})
lightTheme = responsiveFontSizes(lightTheme)

// let darkTheme = createMuiTheme({
//   palette: {
//     type: 'dark',
//   },
// })
// darkTheme = responsiveFontSizes(darkTheme)

const SignInRoute: React.FunctionComponent = () => (
  <Router>
    <NavBar />
    <div className=" ">
    <Switch>
      <Route path="/UserProfileBreakdown" component = {UserProfileBreakdown}/>
      <Route path="/userTotalValue" component = {userTotalValue}/>
      <Route path="/userValidatorPosition" component = {userValidatorPosition}/>
      <Route path="/StakeInstanceDetailedActivity" component = {StakeInstanceDetailedActivity} />
      <Route path="/BlogMain" component = {BlogMain}/> 
      <Route path="/HowToCompleteYourStake" component = {HowToCompleteYourStake}/> 

      <Route path="/HowToMineHeliumTokenWithValidators" component = {HowToMineHeliumTokenWithValidators}/> 
      <Route path="/HowToUnstakeAHeliumValidator" component = {HowToUnstakeAHeliumValidator}/> 
      <Route path="/WhichStakingOptionIsRightForYou" component = {WhichStakingOptionIsRightForYou}/> 
      <Route path="/HowToGuide" component = {HowToGuide}/> 
      <Route path="/contactus" component={ContactUs} />
      <Route path="/TermsOfService" component={ToS} />  
      <Route path="/signin" component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/verify" component={VerifyCode} />
      <Route path="/requestcode" component={RequestCode} />
      <Route path="/forgotpassword" component={ForgotPassword} />
      <Route path="/estimator" component={Estimator} />
      <Route path="/publicGroups" component={PublicGroups}/>
      <Route path="/faq" component={FAQ} />
      <Route path="/BLOG" component={BLOG} />
      <Route path="/" component={Landing} />
    </Switch>
    </div>
    <Footer />
  </Router>
)

const MainRoute: React.FunctionComponent = () => (
  <Router>
    <NavBar />
    <div className="  ">
    <Switch>
      <Route path="/BlogMain" component = {BlogMain}/> 
      <Route path="/HowToCompleteYourStake" component = {HowToCompleteYourStake}/> 
      <Route path="/ValidatorStatsMain" component = {ValidatorStatsMain}/>

      <Route path="/HowToMineHeliumTokenWithValidators" component = {HowToMineHeliumTokenWithValidators}/> 
      <Route path="/HowToUnstakeAHeliumValidator" component = {HowToUnstakeAHeliumValidator}/> 
      <Route path="/WhichStakingOptionIsRightForYou" component = {WhichStakingOptionIsRightForYou}/> 
      <Route path="/HowToGuide" component = {HowToGuide}/> 
      <Route path="/contactus" component={ContactUs} />
      <Route path="/UserPayments" component={UserPayments}></Route>
      <Route path="/TermsOfService" component={ToS} />
      <Route path="/changepassword" component={ChangePassword} />
      <Route path="/estimator" component={Estimator} />
      <Route path="/faq" component={FAQ} />
      <Route path="/publicGroups" component={PublicGroups}/>
      <Route path="/BLOG" component={BLOG} />
      <Route path="/signOut" component={SignOut} />
      <Route path="/" component={UserProfile} />
    </Switch>
    </div>
    <Footer />
  </Router>
)

const App: React.FunctionComponent = () => (
  <ThemeProvider theme={lightTheme} >
    <CssBaseline />
        
    <AuthProvider>
      <AuthIsSignedIn>
        <MainRoute />
      </AuthIsSignedIn>
      <AuthIsNotSignedIn>
        <SignInRoute />
      </AuthIsNotSignedIn>
    </AuthProvider>
  </ThemeProvider>
)

export default App
