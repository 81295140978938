import * as React from "react";
import { Helpers } from '../libs/helpers'
import Alert from 'react-bootstrap/Alert'

class InterestForm extends React.Component<{}, { validationError: boolean, formEnabled: boolean, showSuccess: boolean, saveError: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { validationError: false, formEnabled: true, showSuccess: false, saveError: false };
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        this.setState({ formEnabled: false });
        const formData = new FormData(e.target as HTMLFormElement);
        const email = formData.get('email')?.valueOf()?.toString();
        const comments = formData.get('comments')?.valueOf()?.toString();
        const stakeType = formData.get('stakeType')?.valueOf();

        if (!email) {
            this.setState({ validationError: true, formEnabled: true });
            return;
        }
        Helpers.registerInterest({
            email,
            comments,
            stakeType: stakeType && Number.isInteger(stakeType) ? Number.parseInt(stakeType.toString()) : -1
        }).then(() => {
            this.setState({ showSuccess: true, validationError: false });
        }).catch((err) => {
            this.setState({ formEnabled: true });
        });

    };

    render() {
        return (
            <div>
           

                <form className="" onSubmit={this.handleSubmit} >
                    <fieldset disabled={!this.state.formEnabled}>


                        <section className="py-12 px-9">
                            <div className="flex flex-wrap -mx-6">
                                <div className="w-full lg:w-1/2 px-6 mb-8 lg:mb-0">
                                <Alert key={0} className="py-4 px-8" variant="success" hidden={!this.state.showSuccess}>
                                        Thanks for the submission! We've recorded your data and we'll let you know when we're ready to onboard
                                </Alert>
                                    <Alert key={1} className="py-4 px-8" variant="warning" hidden={!this.state.validationError} dismissible={true} >
                                        We need a bit more data before submitting - try again.
                                </Alert>
                                    <Alert key={2} className="py-4 px-8" variant="danger" hidden={!this.state.saveError} dismissible={true} >
                                        Unfortunately - we had difficulties saving your response. Maybe you could try again later?
                                </Alert>
                                    <p className="text-3xl font-bold hmvs-text-darkAlt mb-2">Have Questions? Contact Us</p>
                                    <p className="mb-8 text-white hmvs-font">If you have questions please contact us. We look forward to helping you mine HNT with Validators.</p>
                                    <p className="text-white hmvs-font">Connect with us on Discord! Members can find groups to join, get help from the Vanager team, and get the latest updates on our progress. <a href="https://discord.gg/dxD9yu9ssc"><p style={{height:"50px", width:"160px"}}className="pt-1 pl-3 mt-3 shadow center  mx-auto rounded bg-indigo-400 text-white"> Go to Discord<img src="images/discordlogo.svg" alt="Join Discord button" style={{height:"40px", width:"40px"}} className="inline"></img></p>  </a>
</p>

                                </div>
                                <div className="w-full lg:w-1/2 px-6">
                                    
                                        <div className="mb-4">
                                            <input className="appearance-none w-full px-6  py-3 leading-tight text-white  border hmvs-bg-neutaral-background rounded  focus:outline-none" type="email" placeholder="Email" id="email" name="email" />
                                        </div>
                                        <div className="mb-4">
                                            <select id="1" name="stakeType" className="appearance-none px-10 hmvs-font py-3 w-full leading-tight text-white hmvs-bg-neutaral-background border border-gray-200 rounded  focus:border-gray-500 focus:outline-none">
                                                <option key={-1} >-- Select product --</option>
                                                <option key={0}>Full Staking</option>
                                                <option key={1}>Partial Staking</option>
                                            </select>
                                        </div>
                                        <div className="mb-4"><textarea className="appearance-none block w-full py-3 px-4 leading-tight text-white hmvs-bg-neutaral-background  border border-gray-200 focus:border-gray-500 rounded focus:outline-none" placeholder="Write something..." rows={5}></textarea></div>
                                        <div>

                                            <button className="hmvs-button-primary hover:bg-white  rounded shadow" disabled={!this.state.formEnabled}>Submit</button>
                                        </div>
                                   
                                </div>
                            </div>
                        </section>

                    </fieldset>
                </form>
            </div>
        );
    }
}

export default InterestForm