import React, { useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import { useValidEmail } from '../../hooks/useAuthHooks'
import { Email } from '../../components/authComponents'

import { AuthContext } from '../../contexts/authContext'

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
  text: {
    textAlign: 'center',
  },
})

export default function RequestCode() {
  const classes = useStyles()

  const { email, setEmail, emailIsValid } = useValidEmail('')
  const [error, setError] = useState('')
  const [resetSent, setResetSent] = useState(false)

  const isValid = !emailIsValid || email.length === 0

  const history = useHistory()

  const authContext = useContext(AuthContext)

  const sendCodeClicked = async () => {
    try {
      await authContext.sendCode(email)
      setResetSent(true)
    } catch (err) {
      setError('Unknown user')
    }
  }

  const emailSent = (
    <>
      <Box mt={1}>
        <Typography className="text-white leading-relaxed text-sm">{`Reset Code Sent to ${email}`}</Typography>
      </Box>
      <Box mt={4}>
        <Button onClick={() => history.push('forgotpassword')} color="primary" variant="contained">
          Reset Password
        </Button>
      </Box>
    </>
  )

  const sendCode = (
    <>
      <Box width="80%" className="bg-white rounded" m={1}>
        <Email emailIsValid={emailIsValid} setEmail={setEmail} />
      </Box>
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <button className="px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-gray-200 rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" 
            onClick={() => history.goBack()}>
              Cancel
            </button>
          </Box>
          <Box m={1}>
            <button className="lg:mx-3 lg:mx-0 px-4 py-2 mt-2 text-sm font-semibold text-black hmvs-bg-primary rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" 
            disabled={isValid} 
            onClick={sendCodeClicked}>
              Send Code
            </button>
          </Box>
        </Grid>
      </Box>
    </>
  )

  return (
    <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} container direction="row" justify="center" alignItems="center" item>
        <Paper style={{ width: '100%', padding: 32, backgroundColor:"#000000" }}>
          <Grid container direction="column" justify="center" alignItems="center">
            <Box m={2}>
              <img alt="vanager logo" src="images/V.png" style={{height:"15vh", width:"15vh"}} className="mx-14  lg:mx-32"></img>

              <Typography variant="h3" className="text-white text-center">Send Reset Code</Typography>
            </Box>

            {resetSent ? emailSent : sendCode}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
