import { protoTypes } from "@hlmvs/hlmvs-client";
import React from "react";
import ValidatorActivityDetails from "./validatorActivityDetails" 
// import VanagerModal from "./VanagerModal";


export const StakeInstanceDetailedActivity: React.FunctionComponent<{  }> = () => {

    // const [hidden, setHidden] = React.useState(true);


    // handleEarningDuration = (e: React.FormEvent) => {


    //     if (e.target && (e.target as any).value === "daily") {
    //         this.setState({ durationConversion: false })
    //     } else {
    //         this.setState({ durationConversion: true })
    //     }

    // }

    return (
      <div>
          
          <section className=" ">
          <div className="flex flex-wrap">
            <div className="w-3/4"> <p className=" text-lg hmvs-text-primary font-bold">Detailed Activity</p></div>
            {/* <div  className="w-1/4"><p className="hmvs-text-primary text-right text-sm font-bold"><button style={{height:"30px", width:"60px"}}className="hmvs-button-secondary rounded shadow" onClick={() => setHidden(false)}>Details</button></p> </div> */}
            {/* <div className="flex w-1/4 justify-end ">
                  <div className=""> 
                      <select id="1" name="duration"  className="hmvs-font text-sm block text-center hmvs-bg-neutaral-background lg:px-9  leading-tight text-white hmvs-text-dark border rounded focus:hmvs-text-dark focus:border-gray-500 focus:outline-none">
                          <option key={0} value={protoTypes.DetailedEarningRecord.DetailCase.PAYOUT}> Payout  </option>
                          <option key={1} value={protoTypes.DetailedEarningRecord.DetailCase.STAKE}>Stake </option>
                          <option key={2} value={protoTypes.DetailedEarningRecord.DetailCase.UNSTAKE}>Unstake </option>
                      </select>
                  </div> 
            </div> */}
          </div> 
          

          <div className="mt-2 mb-2 " style={{ border: "1px solid", borderColor: "#777777" }} />
        

            {/* <VanagerModal child={ValidatorActivityDetails} hidden={hidden} onHide={() => setHidden(true)} /> */}
          
          <ValidatorActivityDetails user_choice={protoTypes.DetailedEarningRecord.DetailCase.STAKE} />
          {/* <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} /> */}
          
      
          </section>
          
      </div>
    )
  }

export default StakeInstanceDetailedActivity;