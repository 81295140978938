import * as React from "react";
import {Helpers} from '../libs/helpers'
import { protoTypes } from '@hlmvs/hlmvs-client';
import { AuthContext } from "../contexts/authContext";
// import { useState } from "react";
// import {EmailShareButton,
//     EmailIcon,
//     FacebookMessengerShareButton,
//     FacebookMessengerIcon,
//     RedditShareButton,
//     RedditIcon,
//     TwitterShareButton,
//     WhatsappShareButton,
//     WhatsappIcon,
//     TwitterIcon} from "react-share"; 
// import SetupFlow from './setupflow';
// import { Line } from 'rc-progress';
// import StakeInstanceCard from "./stakeInstanceCard";
import { ClimbingBoxLoader } from "react-spinners";
import StakeInstanceCardPublic from "./stakeInstanceCardPublic";
// import ValidatorLeaderBoards from "./ValidatorLeaderBoards"; 
import HeliumExplorerStats from "./HeliumExplorerStats";
// import { useHistory } from "react-router-dom";
// import Ticker, { NewsTicker, FinancialTicker } from "@hlmvs/nice-react-ticker";

const PublicGroups: React.FunctionComponent<{}> = () => {
    
    // const [formEnabled, setFormEnabled] = React.useState(true);
   
    // const [loadError, setLoadError] = useState(false);
    // const [stakedInstances, setStakedInstances] = useState(new Map<string, protoTypes.StakeInstance>());
    // const [showNewStakedInstance, setShowNewStakedInstance] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [publicGroups, setPublicGroups] = React.useState(new Array<protoTypes.PublicStakeInstance>());
    // const [joiningGroupCode, /*setJoiningGroupCode*/] = React.useState("");
    // const [joiningPrivate, /*setJoiningPrivate*/] = React.useState(false);

    const authContext = React.useContext(AuthContext);   
    // const history = useHistory();

    const refreshPublicGroups = () => {
        setLoading(true);
        Helpers.listPublicGroups(authContext).then(groups => {
            const sorted = groups.sort((b,a) => Helpers.getHntFromHeliumAmount(a.getStakedamount()) - Helpers.getHntFromHeliumAmount(b.getStakedamount()))
            setPublicGroups(sorted);
            setLoading(false);
        });
    }

  

    React.useEffect(() => {
        refreshPublicGroups();
    // eslint-disable-next-line
    }, []);


    const loadingContent = (
        <div hidden={!loading} className="grid grid-cols-3   py-12 text-white ">
            <div></div>
            <div className=""><p className="px-0 hmvs-font " hidden={!loading} >Just a second while we load your data</p><div className="py-5"></div><div className="lg:px-32 px-14"><ClimbingBoxLoader  loading={loading} color="white" /></div></div>
            <div></div>
        </div>
    )

    const normalContent = (<div>
        <div className="flex flex-wrap mb-2">
        <div className="flex-col py-4 px-5 lg:px-11 "><p className="text-3xl hmvs-font hmvs-text-primary">Validator Groups</p>
        <p className="text-white hmvs-font mt-5">Go in on a validator together! All public, available validator groups are listed here. If you don’t see one you like, start your own public or private group. All groups have a code to share with friends.</p>
        <p className="text-white hmvs-font mt-2">Connect with us on Discord! Members can find groups to join, get help from the Vanager team, and get the latest updates on our progress. </p>
        <a href="https://discord.gg/dxD9yu9ssc"><p style={{height:"50px", width:"160px"}}className="pt-1 pl-3 mt-3 shadow center  mx-auto rounded bg-purple-300 text-white"> Go to Discord<img src="images/discordlogo.svg" alt="Join Discord button" style={{height:"40px", width:"40px"}} className="inline"></img></p>  </a>
</div>
        
    </div>
    <HeliumExplorerStats></HeliumExplorerStats>
    {/* <ValidatorLeaderBoards></ValidatorLeaderBoards> */}
    <div className="flex mt-28 lg:mt-0 flex-wrap">
    {
                    publicGroups.map(group => {
                        return (<StakeInstanceCardPublic publicStakeInstance={group} key={group.getGroupcode()}  />)
                    })                            
                }




    </div>


    {/* <section className="">

            <div className="flex flex-wrap">

                <SetupFlow showModal={showNewStakedInstance} onClose={() => setShowNewStakedInstance(false)}  onSaveSuccessful={validatorSaveSuccessful} prePopulatedGroupCode={joiningGroupCode === "" ? undefined : joiningGroupCode} joiningPrivate={joiningPrivate} />
              

                 <div id="stake" hidden={!this.handleNewStake}>
                     
                </div> 
            </div>
            
    

        </section> */}
        </div>
        )


    return loading ? loadingContent : normalContent;
}

export default PublicGroups