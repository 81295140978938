export const BLOG: React.FunctionComponent = () =>{
    return(
        <div> 
        <article className="py-12 px-4">
            <h1 className="text-4xl text-center text-white mb-4 font-semibold font-heading font-semibold">Vanager Blog</h1>
            <p className="text-center text-white">
                <span>April 30 2021, by</span>
                <span className="ml-1 text-white hover:underline">Vanager Team</span>
            </p>
            <div className="my-10"><img className="rounded mx-auto" src="images/V.png" alt="" style={{width: "50vh", height: "50vh" }}></img></div>
            <div className="max-w-3xl mx-auto">
                <p className="mb-4 text-white">Hey Everyone!</p>
                <p className="mb-4 text-white">I know it has been a while since we last communicated with you but we have been super busy building out a resilient service that performs to meet the needs of Helium network and our users. We also spent some time discovering the best way to validators accessible for everyone while also keeping prices low. We are now in a position to start taking deposits and supporting customers who want to take advantage of the earnings for being the first supporters of staking. Before we jump into that we want to first discuss with you what we have done the last couple of months. </p>
                <p className="mb-4 text-white font-semibold">New Name</p>
                <p className="mb-4 text-white">We recently changed our name from Helium Validator Service to Vanager. We wanted a unique name that really spoke to what our service provides from our expertise in building scalable solutions for the world's top tech companies - a Validator Advantage. </p>
                <p className="mb-4 text-white font-semibold">Building the service</p>
                <p className="mb-4 text-white">Over the last few months we have been running X nodes in Validator Test Network. The nodes that we run continuously perform at the top of the earnings scale for validators. On top of performing well we stress tested our service and built in extra monitoring so that we can prevent any outages that are within our control. This includes constantly monitoring the heartbeat of our servers, the application with the server (validators), and ensuring our nodes are geographically diverse. </p>
                <p className="mb-4 text-white font-semibold">Security and Trust</p>
                <p className="mb-4 text-white">One of the biggest areas we wanted to make sure we fully understood was Security. Managing public/private keys, account credentials, and various account states we built our system to expose and consume as little data as possible in order for us to reduce security risk. We have also been working on the method to support fully automatic non-custodial accounts, which we plan to release later in the year. </p>
                <p className="mb-4 text-white font-semibold">User Experience </p> 
                <p className="mb-4 text-white">The world of crypto is complex and rapidly changing. Our goal with building this service is to make it easy and accessible for everyone to participate. We took our time to think through each step of the customer journey from initial setup, instant payouts, and health reporting. With our process we even made it easier for you to quickly set up private groups with friends to start a validator. We handle the complex details of equal payouts minus expenses and you can focus on supporting the network. Oh yeah we even support mobile staking! </p> 
                <p className="mb-4 text-white font-semibold">Whats Next!</p> 
                <p className="mb-4 text-white">Now the fun stuff begins - we are welcoming everyone to join our service today. All you have to do is register for an account and send a deposit. Once that step is completed you will be ready to participate in Validators at launch! </p> 

                
                
       
                <blockquote className="text-center mb-10">
                <footer className="text-white">Can't wait to take this journey with you, The Vanager Team</footer>
                </blockquote>
                
            </div>
        </article>
        </div>
        
    );
}

export default BLOG