import { protoTypes } from "@hlmvs/hlmvs-client";
import React from "react";
import { Helpers } from "../libs/helpers";
import DepositInstructionsModal from "./despositInstructionsModal";

export const UserPaymentComp: React.FunctionComponent<{ stakeInstance: protoTypes.StakeInstance }> = (props) => {


    const [doesUserStillOweMoney, setDoesUserStillOweMoney] = React.useState(false);
    // const [userStillOwesAmountHnt, setUserStillOwesAmountHnt] = React.useState(0.0);
    const [showDepositInstructions, setShowDepositInstructions] = React.useState(false);
    const [depositedAmount, setDepositedAmount] = React.useState(0.0);

    const getPendingPaymentState = () => {
        const innerDepositAmount = Helpers.getHntFromHeliumAmount(props.stakeInstance.getCallerownerrecord()?.getDepositwalletamount());
        setDepositedAmount(innerDepositAmount);
        const owedAmount = Helpers.getHntFromHeliumAmount(props.stakeInstance.getCallerownerrecord()?.getStakedamount()) - innerDepositAmount;
        const userStillOwesMoney = owedAmount > 0;
        // setUserStillOwesAmountHnt(owedAmount);
        setDoesUserStillOweMoney(userStillOwesMoney);

    };

    React.useEffect(() => {
        getPendingPaymentState();
        // eslint-disable-next-line
    }, [props]);



    return (

        <div className="py-2  ">
            <p className="hmvs-text-primary text-xl">{props.stakeInstance.getName().toString()}</p>
            <div className="flex lg:flex-wrap rounded bg-black py-4  ">
                <div className="grid grid-cols-3 lg:w-full">
                    <div>
                        <p className="text-sm lg:text-2xl text-center text-white">{doesUserStillOweMoney ? `Incomplete` : "Complete"} </p>
                        <p className="text-white text-sm lg:text-lg text-center">Status</p>

                    </div>
                    <div>

                        <p className="text-sm lg:text-2xl text-center text-white">{doesUserStillOweMoney ? `${depositedAmount}/${Helpers.getHntFromHeliumAmount(props.stakeInstance.getCallerownerrecord()?.getStakedamount())}` : `${Helpers.getHntFromHeliumAmount(props.stakeInstance.getCallerownerrecord()?.getStakedamount())} `} </p>
                        <p className="text-sm lg:text-lg text-center text-white">{doesUserStillOweMoney ? ` HNT Remaining ` : ` HNT`} </p>
                    </div>
                    {/* <div>
                    <p className="text-white">Validator Name</p>
                    <p className="text-white">{props.stakeInstance.getName().toString()}</p>
                </div> */}
                    <div className="ml-8">
                        {doesUserStillOweMoney ? <button style={{ height: "30px", width: "30px" }} className=" hmvs-bg-primary text-black rounded" onClick={() => setShowDepositInstructions(true)}>Pay</button> : "Complete"}
                        <DepositInstructionsModal showQRCode={showDepositInstructions} stakeInstance={props.stakeInstance} onClose={() => {setShowDepositInstructions(false)}} />
                    </div>
                </div>

            </div>


        </div>
    )
}

export default UserPaymentComp;