import * as React from "react";
import StakeInstanceDetailedActivity from "./StakeInstanceDetailedActivity";
import UserTotalValue from "./userTotalValue"; 
// import UserValidatorPosition from "./userValidatorPosition";
// import ValidatorHealth from "./validatorHealth"; 



const handleDatePicker = (e: React.FormEvent) => {


  // if (e.target && (e.target as any).value === "HNT") {
  //     setState({ hntConversion: true })
  // } else {
  //     setState({ hntConversion: false })
  // }

}

export const UserProfileBreakdown: React.FunctionComponent<{  }> = () => {
    return (
      <div className="px-9">
          <div className="py-3"></div>
           <div className="">
                
                <div className="  ">
                   
                    <select id="1" name="duration" onChange={handleDatePicker} className="hmvs-font block text-center hmvs-bg-neutaral-background lg:px-9   leading-tight text-white hmvs-text-dark border rounded focus:hmvs-text-dark focus:border-gray-500 focus:outline-none">
                        <option key={0} value="1 Day"> 1D </option>
                        <option key={1} value="7 Days"> 7D </option>
                        <option key={2} value="30 Days"> 30D </option>

                    </select>
                </div>
              
                
            </div>
          <div className="py-3"></div>
          <section className="rounded shadow hmvs-bg-neutaral-dark py-3 px-3">
            <UserTotalValue/>
          </section>
          {/* <div className="py-3"></div> */}
          {/* <section className="rounded shadow hmvs-bg-neutaral-dark  py-2 px-2">
            <UserValidatorPosition/>
          </section> */}
          {/* <div className="py-3"></div> */}
          {/* <section className="rounded shadow hmvs-bg-neutaral-dark  py-2 px-2">
            <ValidatorHealth/>
          </section> */}
          <div className="py-3"></div>
         
          <section className="rounded shadow hmvs-bg-neutaral-dark  py-3 px-3">
            <StakeInstanceDetailedActivity/> 
          </section>
          <div className="py-3"></div>
          
      </div>
    )
  }

export default UserProfileBreakdown;