import Estimator from '../components/estimatorComponent'
import React from "react";
import ArticleFormat from "./articlerepository";

const article = (<div>
    <p className="hmvs-font mt-8 hmvs-text-primary text-xl mb-2 font-bold">How to mine Helium Tokens with Validators using Vanager</p>
        <p className="hmvs-font text-white">Once the validator is launched it will become available to be selected to participate in a consensus consensus groups. Consensus group verifies transactions and reach agreement on the ordering before forming a block and adding it to the blockchain. Use our earnings estimator to see your potential earnings. (Note: the most critical factor on your validator earnings are the number of validators online).  </p>
        <p className="hmvs-font mt-3 mb-2 text-black font-bold py-2 bg-yellow-500 px-2">Do not send funds from an Exchange wallet (eg. binance, bilaxy, etc) - only transfer your total stake amount from your Helium Wallet. If you use an exchange wallet your earnings could go back to a wallet owned by the exchange not you. You will risk losing access to your earnings and stake amount.</p>

        <section className="-mt-7 ">
          <Estimator/>
        </section>
        <section className="hmvs-bg-neutaral-dark rounded  shadow px-2 py-2">
            <p className="text-xl text-center hmvs-font hmvs-text-primary font-bold">Additional Resources </p>
            <div className="mt-2 mb-2 font-semibold hover:text-green-400"><a href="https://docs.google.com/spreadsheets/d/1C0Tsz31Qa2b8PHXrl0gMDh2_fXcd2qSWVgF0hmg9Hqg/edit#gid=63991307" className="text-white text-base">Basic $HNT Projection Model</a></div>
            <div className="mb-2 font-semibold hover:text-green-400"><a href="https://docs.google.com/spreadsheets/d/1E4XLpF-a3xo3ZtNpJzd9g6EcuMZ1nSKKvtmKy_LVjhE/edit#gid=1705424601" className="text-white text-base">Helium Validator Rewards Forecasting with Validator Ramp Calculations </a></div>
        </section>
               
        
</div>) 
export const HowToMineHeliumTokenWithValidators: React.FunctionComponent<{  }> = () => {
    return (
        <div className=" ">
        <ArticleFormat article={article} ></ArticleFormat>
        
    
      </div>
    )
  }

export default HowToMineHeliumTokenWithValidators;