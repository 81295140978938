import { HLMVSClientFactory, protoTypes, Timestamp } from '@hlmvs/hlmvs-client';
import {  AuthStatus, IAuth } from '../contexts/authContext'


// import { StakeInstanceKind } from '../../../hlmvs-client/node_modules/@hlmvs/hlmvs-gen-client/build/src';
// import { PartialPrivacy } from '../../../hlmvs-client/node_modules/@hlmvs/hlmvs-gen-client/build/src';
// import { HeliumAmountKind } from '../../../hlmvs-client/node_modules/@hlmvs/hlmvs-gen-client/build/src';
// import { HeliumAmount } from '../../../hlmvs-client/node_modules/@hlmvs/hlmvs-gen-client/build/src';


export interface AggregateEarnings {
    totalServiceFeesHnt: number;
    totalServerFeesHnt: number;
    validatorEarningsHnt: number;
    userEarningsHnt: number; 
}

export class Helpers {

    public static BONES_IN_ONE_HNT = 100_000_000;

    // public static getValidatorPenaltyData(address: string): protoTypes.ValidatorPenaltyData {

    // }

    public static getHntFromHeliumAmount(amount?: protoTypes.HeliumAmount) {
        if (!amount) {
            return 0;
        }
        const amt = amount.getUnit()! === protoTypes.HeliumAmountKind.BONES ? amount.getBones()! / Helpers.BONES_IN_ONE_HNT : amount.getHnt()!
        return Number.parseFloat(amt.toFixed(2));
    }

    public static getUserPoolId() {
        if (HLMVSClientFactory.isLocal() || HLMVSClientFactory.isTest()) {
            return "us-west-2_HoqGBXSXQ";
       } else {
           return "us-west-2_McxWbWOfS";
       }
    }
    public static getClientId() {
        if (HLMVSClientFactory.isLocal() || HLMVSClientFactory.isTest()) {
             return "2tks1cig3b60dtssgj1l2ajm9u";
        } else {
            return "437p7j0g2bal8aqe3oomecd2on";
        }
    }

    public static useTestnet() {
        return HLMVSClientFactory.isLocal() || HLMVSClientFactory.isTest();
    }

    private static existingClient: protoTypes.HMVSPromiseClient;
    public static anonymousClient() {
        if (!this.existingClient) {
            Helpers.existingClient = HLMVSClientFactory.build();
        }
        return Helpers.existingClient;
    }

    public static groupCodePreview(code: string, authContext: IAuth){
        const request = new protoTypes.GroupCodePreviewRequest();
        request.setGroupcode(code);
        return Helpers.authenticatedClient(authContext).then(client => {
            return client.groupCodePreview(request);
        })
    }

    public static listPublicGroups(authContext: IAuth) {
        const request = new protoTypes.ListPublicStakeInstancesRequest();
        return Helpers.authenticatedClient(authContext).then(client => {
            return client.listPublicStakeInstances(request).then(result => {
                return result.getGroupsList();
            });
        });
    }

    public static listLaunchedValidtors(authContext: IAuth) {
        const request = new protoTypes.GetPublicStatsRequest();
        return Helpers.authenticatedClient(authContext).then(client => {
            return client.getPublicStats(request).then(result => {
                return result.getValidatorstats();
            });
        });
    }

    public static unstake(stakeInstanceId: string, authContext: IAuth) {
        const request = new protoTypes.UnstakeRequest();
        request.setStakeinstanceid(stakeInstanceId);
        return Helpers.authenticatedClient(authContext).then(client => {
            return client.unstake(request);
        });
    }

    public static joinGroup(code: string, amountInHNT: number, authContext: IAuth) {
        const request = new protoTypes.JoinStakeInstanceRequest();
        request.setPartialownershipgroupcode(code);
        const amt = new protoTypes.HeliumAmount();
        amt.setUnit(protoTypes.HeliumAmountKind.HNT);
        amt.setHnt(amountInHNT)
        request.setStakedamount(amt);
        return Helpers.authenticatedClient(authContext).then(client => {
            return client.joinStakeInstance(request);
        });
    }

    public static authenticatedClient(authContext: IAuth): Promise<protoTypes.HMVSPromiseClient> {
        if (authContext.authStatus !== AuthStatus.SignedIn) {
            return Promise.reject();
        } else {
            
            return HLMVSClientFactory.buildAuth(new Promise<string>((resolve, reject) => {
                resolve(`Bearer ${authContext.sessionInfo?.accessToken}`);
            }));
            
        }
    }

    public static elippsify(input: string, before: number, after: number) {
      if (before + after > input.length) {
        return input;
      }
      return input.substr(0, before) + "..." + input.substr(input.length - after);
    }

    public static getStateDisplayString(state: protoTypes.StakeInstanceState): string {
        switch (state) {
            case protoTypes.StakeInstanceState.STAKE_INSTANCE_STATE_LIVE:
                return "Live"
            case protoTypes.StakeInstanceState.STAKE_INSTANCE_STATE_PENDING_PAYMENTS:
                return "Pending your payment"
            case protoTypes.StakeInstanceState.STAKE_INSTANCE_STATE_PENDING_STAKE_VALIDATION:
                return "Pending Staking"
            case protoTypes.StakeInstanceState.STAKE_INSTANCE_STATE_INITIAL:
                return "Pending more members joining group"
            case protoTypes.StakeInstanceState.STAKE_INSTANCE_STATE_PAYMENTS_RECEIVED_PENDING_SETUP:
                return "Pending Validator setup"
            case protoTypes.StakeInstanceState.STAKE_INSTANCE_STATE_COOLDOWN:
                return "Cooldown - unstaked"
            default:
                console.log(state);
                return "Unknown State"

        }
    }

    public static listStakedInstances(authContext: IAuth, viewAs?: string): Promise<protoTypes.ListStakeInstanceResult> {
        const request = new protoTypes.ListStakeInstanceRequest();
        if (viewAs) {
            request.setViewas(viewAs);
        }
        return Helpers.authenticatedClient(authContext).then(client => {
            return client.listStakeInstances(request)
        });
    }

    public static getStakedInstance(stakeInstanceId: string, authContext: IAuth): Promise<protoTypes.StakeInstance> {
        const request = new protoTypes.GetStakeInstanceRequest();
        request.setStakeinstanceid(stakeInstanceId);
        return Helpers.authenticatedClient(authContext).then(client => {
            return client.getStakeInstance(request).then(result => {
                return result.getStakeinstance()!;
            })
        });
    }

    public static getEarnings(authContext: IAuth) {
        const request = new protoTypes.DetailedEarningsRequest();
        // request.setStakeinstanceid(stakeInstanceId)
        return this.authenticatedClient(authContext).then(client => {
            return client.getDetailedEarnings(request);
        });
    }

    public static getAggregateEarnings(authContext: IAuth, stakeInstanceId: string, daysBack: number): Promise<AggregateEarnings> {
        const request = new protoTypes.DetailedEarningsRequest();
        const endTimestamp = new Timestamp();
        const nowAsSeconds = Math.trunc(new Date().getTime() / 1000);
        endTimestamp.setSeconds(nowAsSeconds);
        request.setEndDate(endTimestamp);

        const startTimestamp = new Timestamp();
        startTimestamp.setSeconds(nowAsSeconds - (daysBack*86400));
        request.setStartDate(startTimestamp);
        request.setStakeinstanceid(stakeInstanceId);
        return this.authenticatedClient(authContext).then(client => {
            return client.getDetailedEarnings(request).then(detailedEarnings => {
                let totalServerFeesHnt = 0;
                let totalServiceFeesHnt = 0;
                let userEarningsHnt = 0;
                let validatorEarningsHnt = 0;
                detailedEarnings.getDataList().forEach(data => {
                    switch (data.getDetailCase()) {
                        case protoTypes.DetailedEarningRecord.DetailCase.PAYOUT:
                            totalServiceFeesHnt += Helpers.getHntFromHeliumAmount(data.getPayout()?.getServicefee()?.getAmount());
                            totalServerFeesHnt += Helpers.getHntFromHeliumAmount(data.getPayout()?.getServerfee()?.getAmount());
                            userEarningsHnt += Helpers.getHntFromHeliumAmount(data.getPayout()?.getOwnerpayout()?.getAmount());
                            validatorEarningsHnt += Helpers.getHntFromHeliumAmount(data.getPayout()?.getValidatorrewardamount());
                            break;
                        default:
                            // no-op
                    }
                });
                return {
                    totalServerFeesHnt,
                    totalServiceFeesHnt,
                    userEarningsHnt,
                    validatorEarningsHnt,
                }
            });
        });
    }

    public static createValidator(details: {
        validator_name?: string; 
        stake_amount: number; 
        kind: protoTypes.StakeInstanceKind;
        privacy?: protoTypes.PartialPrivacy,
        
    }, authContext: IAuth): Promise<protoTypes.CreateStakeInstanceResult>{
        const request = new protoTypes.CreateStakeInstanceRequest();
        if (details.validator_name) {
            request.setName(details.validator_name);
        }
        request.setKind(details.kind);
        const amount = new protoTypes.HeliumAmount();
        amount.setHnt(details.stake_amount);
        amount.setUnit(protoTypes.HeliumAmountKind.HNT);
        request.setStakedamount(amount);
        let p = details.privacy;
        if (p === undefined && details.kind === protoTypes.StakeInstanceKind.STAKE_INSTANCE_KIND_PARTIAL) {
            request.setPartialprivacy(protoTypes.PartialPrivacy.PARTIAL_PRIVACY_PRIVATE);
        } else if (details.kind === protoTypes.StakeInstanceKind.STAKE_INSTANCE_KIND_PARTIAL) {
            request.setPartialprivacy(p!);
        }
        
        return Helpers.authenticatedClient(authContext).then(c => {
            return c.createStakeInstance(request)
        });
    }

    public static registerInterest(details: {
        email: string,
        comments?: string, 
        amount?: number,
        stakeType: number,
    }): Promise<void> {
        const request = new protoTypes.RegisterInterestRequest();
        if (details.comments) {
            request.setComments(details.comments);
        }
        request.setEmail(details.email);
        
        if (details.amount) {
            request.setStakeamount(details.amount);
        }
        // request.setImportancestakeoutearly();
        // request.setStaketype()
        return Helpers.anonymousClient().registerInterest(request).then(response => {
            return new Promise<void>((resolve, reject) => {
                if (response.getSaved() === true) {
                    resolve();
                } else {
                    reject();
                }
            });
        })
    }
}