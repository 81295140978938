export const UserValidatorPosition: React.FunctionComponent<{  }> = () => {
    return (
      <div>
          <div>
              <p className="hmvs-font text-lg hmvs-text-primary font-bold ">Your Position</p>
              <div className="mt-2" style={{ border: "1px solid", borderColor: "#777777" }} />
              <div className="flex flex-wrap text-white">
                  <div className=" text-center w-1/3">
                      <p className="mt-4 ">5</p>
                      <p className="text-sm ">Consensus Groups</p>
                   
                      
                  </div>
                  <div className=" text-center w-1/3">
                      {/* <p className="mt-4">200</p>
                      <p className="text-sm ">Staked Amount</p> */}
                      <p className="mt-4">200</p>
                      <p className="text-sm ">Mined</p>
                     
                  </div>
                  <div className=" text-center w-1/3">
                      {/* <p className="mt-4">200</p>
                      <p className="text-sm ">Staked Amount</p> */}
                      <p className="mt-4">2</p>
                      <p className="text-sm "># of Validators</p>
                     
                  </div>
              </div>
            
          </div>
      </div>
    )
  }

export default UserValidatorPosition;