

export const ArticleFormat: React.FunctionComponent<{ article: React.ReactElement}> = (props) => {
    return (
      <div className="px-5 ">
        <div >
            {props.article}

           
        </div>
        <div style={{maxWidth:"600px", minWidth:"300px"}} className="" >
        <div className="mt-5 center px-2 py-2 rounded bg-black">
            <p className="mt-2 text-center hmvs-text-primary hmvs-font font-bold text-xl">More information about Staking Helium Validators</p>
            <div className="mt-2"><a href="/#/WhichStakingOptionIsRightForYou" className="hmvs-font text-base text-white font-semibold hover:text-green-400 ">How to stake a Helium Validator with Vanager?</a></div>
            <div className="mt-2"><a href="/#/WhichStakingOptionIsRightForYou" className="hmvs-font text-base text-white font-semibold hover:text-green-400 ">Which Staking option is right for you?</a></div>
            <div className="mt-2"><a href="/#/HowToCompleteYourStake" className="hmvs-font text-base  text-white font-semibold hover:text-green-400 ">How to complete your stake?</a></div>
            <div className="mt-2"><a href="/#/HowToMineHeliumTokenWithValidators}" className="hmvs-font text-base  text-white  font-semibold hover:text-green-400 ">How to mine helium tokens with Validators?</a></div>
            <div className="mt-2 mb-2"><a href="/#/HowToUnstakeAHeliumValidator" className="hmvs-font text-base  text-white  font-semibold hover:text-green-400 ">How to unstake a Helium Validator?</a></div>

        </div>
        </div>

        <div className="py-4"></div>
      </div>
    )
  }

export default ArticleFormat;