import React, { useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import { useValidEmail, useValidPassword, /*useValidUsername*/ } from '../../hooks/useAuthHooks'
import { Email, Password, /*Username*/ } from '../../components/authComponents'

import { AuthContext } from '../../contexts/authContext'

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
})

const SignUp: React.FunctionComponent<{}> = () => {
  const classes = useStyles()

  const { email, setEmail, emailIsValid } = useValidEmail('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  // const { username, setUsername, usernameIsValid } = useValidUsername('')
  const [error, setError] = useState('')
  const [created, setCreated] = useState(false)

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('')

  const isValid =
    !emailIsValid ||
    email.length === 0 ||
    /*!usernameIsValid ||
    username.length === 0 ||*/
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  const history = useHistory()

  const authContext = useContext(AuthContext)

  const signInClicked = async () => {
    try {
      await authContext.signUpWithEmail(email, email, password)
      setCreated(true)
    } catch (err) {
      setError(err.message)
    }
  }

  const signUp = (
    <>
      <Box width="80%" m={1} className="bg-white rounded">
        <Email emailIsValid={emailIsValid} setEmail={setEmail} />
      </Box>
      {/* <Box width="80%" m={1}>
        <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
      </Box> */}
      <Box width="80%" m={1} className="bg-white rounded">
        <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
      </Box>
      <Box width="80%" m={1} className="bg-white rounded">
        <Password label="Confirm Password" passwordIsValid={passwordConfirmIsValid} setPassword={setPasswordConfirm} />
      </Box>
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <button className="rounded hmvs-font hmvs-button-secondary hover:bg-gray-600 shadow"
            onClick={() => history.goBack()} >
              Cancel
            </button>
          </Box>
          <Box m={1}> 

            <button className="rounded hmvs-font hmvs-button-primary hover:bg-white shadow"
            disabled={isValid} onClick={signInClicked}>
              Sign Up
            </button>

          </Box>
        </Grid>
      </Box>
    </>
  )

  const accountCreated = (
    <>
      <Typography className="text-white leading-relaxed text-sm">{`Created ${email} account`}</Typography>
      <Typography className="text-white leading-relaxed text-sm">{`Please verfiy code sent to ${email}`}</Typography>

      <Box m={4}>
        <button className="lg:mx-3 lg:mx-0 px-4 py-2 mt-2 text-sm font-semibold text-black hmvs-bg-primary rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
        onClick={() => history.push('/verify')} >
          Send Code
        </button>
      </Box>
    </>
  )

  return (
    <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} container direction="row" justify="center" alignItems="center" item>
        <Paper style={{ width: '100%', padding: 16, backgroundColor:"#000000" }}>
          <Grid container direction="column" justify="center" alignItems="center">
            {/* Title */}
            <Box m={3}>
              <img alt="Vanager Logo" src="images/V.png" style={{height:"15vh", width:"15vh", alignContent: "inline"}} className="mx-16"></img>

              <Grid container direction="row" justify="center" alignItems="center">

                <Typography ><p className="text-6xl text-white">Sign Up</p></Typography>
              </Grid>
            </Box>

            {!created ? signUp : accountCreated}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default SignUp
