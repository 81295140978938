export const FAQ: React.FunctionComponent = () => {
    return (
        <div>
        <section className="py-12 px-4">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-4xl text-center mb-12 font-semibold hmvs-font  hmvs-text-darkAlt">Frequently Asked Questions</h2>
            <div className="flex flex-wrap -mx-8">
              <div className=" px-8 mb-8">
                <h3 className="text-lg hmvs-font mb-1 font-semibold font-bold text-white">How do I get started?</h3>
                <p className="text-white hmvs-font">Start with registering for a free account, which will give you access to your validator dashboard. You’ll need to have a Helium wallet and at least 100 HNT in order to partial stake, and 10,000 HNT for a full stake. That’s it!</p>
              </div>
              <div className=" px-8 mb-8">
                <h3 className="text-lg hmvs-font mb-1 font-semibold font-bold text-white">What is the difference between Partial Staking and Full Staking? </h3>
                <p className="text-white hmvs-font">Partial staking allows multiple people who don’t have the full 10,000 HNT to go in together on a validator. The expenses and the earnings for partial stakers are proportional to amount staked. We do not offer pooling beyond the scope of a single validator, so earnings are specific to validators you have an ownership stake in. Full stakers will earn 100% of their Validator’s earnings (minus fees). </p>
              </div>
              <div className=" px-8 mb-8">
                <h3 className="text-lg hmvs-font mb-1 font-semibold font-bold text-white">What are the fees? Why? </h3>
                <p className="text-white hmvs-font">(1)Server Fee - this is a flat fee charged daily per validator. It covers the cost of running the server and monitoring. In case of partial staking, this fee is split proportionally amongst all owners. We monitor the uptime of our managed validators closely and record metrics on them. If we experience any issues that make a validator unavailable in your dashboard, we’ll waive that day’s server fee.</p>
                <p className="text-white hmvs-font">(2) Service Fee - Vanager charges a flat 3% fee on all earnings to support the growth of this platform.</p>                
              </div>
      
              <div className=" px-8 mb-8">
                <h3 className="text-lg hmvs-font mb-1 font-semibold font-bold text-white">Do we offer early unstaking? </h3>
                <p className="text-white hmvs-font">We do not currently support early unstaking. Let us know if this is a feature you’d like Vanager to support! </p>
              </div>
              <div className=" px-8 mb-8">
                <h3 className="text-lg hmvs-font mb-1 font-semibold font-bold text-white">What is Helium?</h3>
                <p className="text-white hmvs-font">See Helium Website <a href="https://www.helium.com/" className="hmvs-text-primary   font-semibold">here.</a> To learn more about Validators and the Helium team look <a href="https://www.helium.com/stake" className="hmvs-text-primary  font-semibold">here.</a>  </p>  
              </div>
              <div className="px-8 mb-8">
                <h3 className="text-lg hmvs-font mb-1 font-semibold font-bold text-white">What features does Vanager Support? </h3>
                <p className="text-white hmvs-font">Full Staking (10,000 HNT), Partial Staking (at least 100 HNT), Health Metrics, Earnings Metrics, Private Groups, Instant Payouts (minus Fees).</p>
              </div>
              <div className=" px-8 mb-8">
                <h3 className="text-lg mb-1 hmvs-font font-semibold  font-bold text-white">Why should I stake a Validator through Vanager</h3>
                <p className="text-white hmvs-font">Vanager offers security, flexibility, and transparency. We’re so excited about the potential of the people’s network - so we’re dedicated to identifying and solving some of the biggest barriers to entry. Partial staking makes it easier for anyone to get involved, our dashboards make sure you always know what’s going on, and the Vanager team’s deep, technical knowledge of space is ready to grow as the Helium network does. We’re in it for the long haul, and hope you’ll join us. </p>
              </div>
              <div className="px-8 mb-8">
                <h3 className="text-lg hmvs-font mb-1 font-semibold font-bold text-white">When do I get paid?</h3>
                <p className="text-white hmvs-font">Nearly instantly! There are no artificial delays in awarding your validator earnings. As soon as we process a reward transaction (every 3 minutes) we’ll send your payout immediately (minus fees). </p>
              </div>
              <div className="px-8 mb-8">
                <h3 className="text-lg hmvs-font mb-1 font-semibold font-bold text-white">What happens once I deposit funds?</h3>
                <p className="text-white hmvs-font">Vanager is constantly scanning the Helium Blockchain watching for transactions it should pay attention to. When we see funds sent to a depoist wallet - we record and process that transaction. If the deposit fulfills a pledged Validator - we consolidate all of the depoist funds to a single staking wallet. Once all the funds have been moved to the staking wallet - we will stake a new validator against that wallet. From there, we launch a copy of the Helium Validator software on that wallet's behalf. Through the same scanning mechanism from before, Vanager will see any rewards sent to to the staking wallet. The rewards will then be sent proportionally back to the owners of the Validator - minus our fees. Fees are split proportionally among owners. </p>
              </div>
              
            </div>
          </div>
        </section>

        <section className="py-12 px-4">
          <div className="flex flex-wrap max-w-4xl mx-auto">
            <div className="flex-col  overflow-y-auto ">
              <p className="heading hmvs-text-darkAlt font-semibold text-4xl lg:text-3xl">Validator Life Cycle</p>
              <p className="py-2"></p>
              <div className="flex-row hmvs-bg-neutaral-dark round shadow px-3">
                <div className="py-2"></div>
                <p className="text-xl hmvs-text-light hmvs-font font-bold">1. Deposit HNT </p>
                <p className=" text-white mt-2">Individuals stake 10,000 HNT for an entire Validator. Partial stakers setup private or public groups to split a Validator.</p>
                <div className="py-2"></div>

              </div>
              <div className="py-3"></div>
              <div className="flex-row hmvs-bg-neutaral-dark round shadow px-3">
                <div className="py-2"></div>
                <p className="text-xl hmvs-text-tertiary hmvs-font font-bold">2. Launch Validator</p>
                <p className=" text-white mt-2">Once deposits are validated on the chain, Vanager automatically creates a Validator instance in AWS.</p>
                <div className="py-2"></div>


              </div>
              <div className="py-3"></div>
              <div className="flex-row hmvs-bg-neutaral-dark round shadow px-3">
                <div className="py-2"></div>
                <p className="text-xl hmvs-text-primary hmvs-font font-bold">3. Mine HNT </p>
                <p className=" text-white mt-2">Validator mines HNT by doing consensus work.</p>
                <div className="py-2"></div>


              </div>
              <div className="py-3"></div>
              <div className="flex-row hmvs-bg-neutaral-dark round shadow px-3">
                <div className="py-2"></div>
                <p className="text-xl  hmvs-text-darkAlt hmvs-font font-bold">4. Recieve Earnings </p>
                <p className=" text-white mt-2">Full Stakers earn 100% of the earnings (minus fees) and Partial Stakers earn an amount proportional to their stake. </p>
                <div className="py-2"></div>

              </div>
              <div className="py-3"></div>
              <div className="flex-row hmvs-bg-neutaral-dark round shadow px-3">
                <div className="py-2"></div>
                <p className="text-xl hmvs-text-dark hmvs-font font-bold">5. Unstake Validator </p>
                <p className="hmvs-text text-white">A validator will be unstaked when more than 50% of the HNT provided by members have requested to unstake. (example: 10 members each contibute 1,000 HNT to a validator (10,000 HNT Total). If 6 of those members request an "unstake", which would be equal 6,000/10,000 HNT staked (60%), then the validator will be unstaked.) From that point all members have to wait ~5 Months (250,000 blocks) to have their individual stake amounts returned. The 5 month period is not set by us and is required by the Helium Organization. More details on the Helium Requirements for unstaking <a href="https://docs.helium.com/mine-hnt/validators/validator-faqs-resources/" className="hmvs-text-primary">here.</a></p>
                
                <div className="py-2"></div>

              </div>

            </div>
            <div className=""></div>
            {/* <div className="flex-col lg:mx-9 lg:mt-36">
              <div className="lg:py-9"></div>
              <img src="images/1.svg" alt="" className="hmvs-one-edge-shadow image-center lg:mt-8"  ></img>

            </div> */}

          </div>
        </section>
        </div>
    );
}

export default FAQ;