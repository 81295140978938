import * as React from "react";
import {Helpers} from '../libs/helpers'
import { protoTypes } from '@hlmvs/hlmvs-client';
import { AuthContext } from "../contexts/authContext";
import { LinkHelpers } from "../libs/link-helpers";
import { Client, Network } from "@helium/http";
import { Tooltip } from '@material-ui/core';

export const ValidatorLeaderBoards: React.FunctionComponent<{  }> = () => {

    const [launchedValidators, setlaunchedValidators] = React.useState(new Array<protoTypes.LaunchedStakeInstance>());
    const [loading, setLoading] = React.useState(false);
    const authContext = React.useContext(AuthContext);  
    const hlmClient = new Client(Helpers.useTestnet() ? Network.testnet : Network.production, {
      userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.69 Safari/537.36"
    });

    React.useEffect(() => {
        refreshLaunchedValidators();
// eslint-disable-next-line
    }, []);
    
    const refreshLaunchedValidators = () => {
        setLoading(true);
        Helpers.listLaunchedValidtors(authContext).then(validator => {
            const earningPromises: Promise<void>[] = [];
            validator?.getLauncedinstancesList().forEach(val => {
              const now = new Date();
              const sevenDaysAgo = new Date();
              sevenDaysAgo.setDate(sevenDaysAgo.getDate()-7);
              const thirtyDaysAgo = new Date();
              thirtyDaysAgo.setDate(thirtyDaysAgo.getDate()-30);
              const sevenDayPromise = hlmClient.account(val.getStakingwalletpubkey()?.getAddress()!).rewards.sum.get(sevenDaysAgo, now).then(result => {
                // console.log(`For staking wallet ${val.getStakingwalletpubkey()?.getAddress()} sum is ${result.sum}`);
                const newAmount = new protoTypes.HeliumAmount();
                newAmount.setBones(result.sum);
                newAmount.setUnit(protoTypes.HeliumAmountKind.BONES);
                val.setSevendayearnings(newAmount);
                return;
              });
              const thirtyDayPromise = hlmClient.account(val.getStakingwalletpubkey()?.getAddress()!).rewards.sum.get(thirtyDaysAgo, now).then(result => {
                const newAmount = new protoTypes.HeliumAmount();
                newAmount.setBones(result.sum);
                newAmount.setUnit(protoTypes.HeliumAmountKind.BONES);
                val.setThirtydayearnings(newAmount);
                return;
              });
              // const perfPenaltyPromise = Helpers.getValidatorPenaltyData(val.getAddress()?.getAddress()!).then(penaltyData => {
              //   val.setPentalyData()
              // })
              earningPromises.push(thirtyDayPromise, sevenDayPromise);
            });
            Promise.all(earningPromises || []).then(() => {
              setlaunchedValidators(validator?.getLauncedinstancesList()!);
              setLoading(false);
            });
        });
    }

    const loadingContent = (<div>
      <p>Fetching data - just a second...</p>
    </div>)

    const loadedContent = (
      <div>
          <p className="hmvs-font text-xl mx-2 hmvs-text-primary mb-2">Validator Earnings</p>

          <section className="mx-2 px-2 bg-black rounded shadow mb-2 py-2"> 
          <div className="grid grid-flow-col grid-cols-3 gap-1 hmvs-font text-white mb-1">
            <div>
                <p>Name</p>
            </div>
            {/* <div>
                <p className="text-center">Members</p>
           </div> */}
           <div>
                <p className="text-center">7D</p>
           </div>
           <div>
                <p className="text-center">30D</p>
           </div>

          </div>
          {launchedValidators.map(validator => {
                    if (validator.getAddress()?.getAddress() === "1ae7iFGpYWYdLwcnaaZ7JJUm6LbdyHkfeswMfdjnE3MDcRVDvN7") {
                        validator.setConsensusdetails(new protoTypes.ConsensusDetails());
                        validator.getConsensusdetails()?.setCurrentlyinconsensusgroup(true);
                    }
                    const inner = (<div className={`grid grid-flow-col grid-cols-3 gap-1 ${validator.getConsensusdetails()?.getCurrentlyinconsensusgroup() ? "hmvs-bg-darker" : "hmvs-bg-neutaral-dark"}  hmvs-font mb-5 mt-3 py-2`} key={`val${validator.getAddress()?.getAddress()}`}>
                                <div className="mx-2 text-white hmvs-font "><p className="hmvs-text-primary text-base">{validator.getName()}</p><a className={`text-sm text-white ` } href={LinkHelpers.getExplorerLinkForValidator(validator.getAddress()!)}>{validator.getValidatorname()}</a></div>
                                {/* <div className="text-white hmvs-font text-center">{validator.getStakercount()}</div> */}
                                <div className="text-white hmvs-font text-center">{Helpers.getHntFromHeliumAmount(validator.getSevendayearnings()).toFixed(2)}</div>
                                <div className="text-white hmvs-font text-center">{Helpers.getHntFromHeliumAmount(validator.getThirtydayearnings()).toFixed(2)}</div>
                            </div>);
                    return validator.getConsensusdetails()?.getCurrentlyinconsensusgroup() ? <Tooltip title="This validator is currently in a Consensus Group">{inner}</Tooltip> : inner;
                    })                            
                }
            <p className="text-sm text-center visible sm:invisible text-white">A green background means the validator is currently in a Consensus Group</p>
          </section>

          
    
        

      </div>
    );

    return loading ? loadingContent : loadedContent;
  }

export default ValidatorLeaderBoards;