import React from "react";
import ArticleFormat from "./articlerepository";

const article = (<div>
    <p className="hmvs-font mt-8 hmvs-text-primary font-bold text-xl">How to complete your Helium Validator stake</p>
        <p className="mt-2 hmvs-font text-white">Once you have selected the right staking option for you and the setup or joined a validator now you stake. If you are creating a new validator simply send your funds to the QR Code provided at the end. If you are joining a validator simply click “Join Group” and provide the group code. Validate the group details to ensure they are correct then enter in your stake amount and send your deposit to the account. </p>
        <p className="hmvs-font mt-3 hmvs-text-primary ">What are the requirements to launch a Helium validator on Vanager</p>
        <ul className="mt-2 list-decimal px-4">
            <li className="hmvs-font text-base text-white">The total pledge amount is equal to 10,000 HNT </li>
            <li className="hmvs-font text-base text-white">The total deposit amount is equal to 10,000 HNT </li>

        </ul>

        <div className="mt-6 hmvs-bg-primary px-2 py-2 rounded shadow">
            <p className="text-black text-center font-bold text-xl ">Important Terms</p>
            <p className="mt-2 text-black font-bold">Pledge Amount</p>
            <p className="px-2 text-black">Is the total amount of Helium tokens all members of “promised” to deposit to the account and are simply reserving their spot in a validator. Members have 48 hours from the time they pledge to deposit before their reservation is made available to another member. </p>
            <p className="mt-2  text-black font-bold">Deposit Amount</p>
            <p className="px-2 text-black mb-2"> Is the total amount of Helium tokens a pledged member has deposited in the validator. </p>
        </div>
        
        
</div>) 

export const HowToCompleteYourStake: React.FunctionComponent<{  }> = () => {
    return (
        <div className="">

    <ArticleFormat article={article} ></ArticleFormat>
        
        
       
      </div>
    )
  }

export default HowToCompleteYourStake;