
import ArticleFormat from "../components/articlerepository"

const article = (<div>
          <p className="hmvs-font mt-8 hmvs-text-primary mb-2 text-xl font-bold">How to stake a Helium Validator with Vanager</p>
            <p className="text-white">Creating a Helium Validator with Vanager is really simple and allows individuals, private groups, and public groups to start mining HNT. Follow these steps: </p>
            <ul className="mt-2 list-decimal px-4">
                <li className="hmvs-font text-base text-white mb-2">Register for a free account at vanger.io </li>
                <li className="hmvs-font text-base text-white mb-2">Decide which type of staking service is right for you? (Full Stake, Private Group, Public Group) </li>
                <li className="hmvs-font text-base text-white mb-2">Send your stake amount from a helium wallet to the deposit wallet by using the QR code </li>
                <p className="mt-2 mb-2 px-2 text-base py-2 rounded shadow text-white hmvs-font text-sm bg-red-700 mb-2">Important: Do not send funds from an Exchange wallet (eg. binance, bilaxy, etc) - only transfer your total stake amount from your Helium App Wallet. If you use and exchange wallet you may not be able to receive earnings or refund your original stake. If you have already used an exchange wallet please contact the Vanager team. </p>
                <li className="hmvs-font text-base  text-white mb-2">Earn HNT by completing consensus group activities instantly (creating new blocks and validating transactions) </li>
                <li className="hmvs-font text-base  text-white mb-2">Once you have sent the funds to the deposit wallet and the validator has the required 10,000 HNT minimum to stake the validator will launch. At this stage the validator will be eligible for mining events. </li>
            </ul>
</div>) 




export const HowToGuide: React.FunctionComponent<{  }> = () => {


    return (
        <div className=" ">
            <ArticleFormat article={article} ></ArticleFormat>
            {/* <p className="hmvs-font mt-8 hmvs-text-primary font-bold">How to stake a Helium Validator with Vanager</p>
            <p className="text-white">Creating a Helium Validator with Vanager is really simple and allows individuals, private groups, and public groups to start mining HNT. Follow these steps: </p>
            <ul className="mt-2 list-decimal px-14">
                <li className="hmvs-font text-base text-white">Register for a free account at vanger.io </li>
                <li className="hmvs-font text-base text-white">Decide which type of staking service is right for you? (Full Stake, Private Group, Public Group) </li>
                <li className="hmvs-font text-base text-white">Send your stake amount from a helium wallet to the deposit wallet by using the QR code </li>
                <p className="mt-2 mb-2 px-2 text-base  text-white hmvs-font text-sm bg-red-700">Important: Do not send funds from an Exchange wallet (eg. binance, bilaxy, etc) - only transfer your total stake amount from your Helium Wallet. If you use an exchange wallet your earnings could go back to that wallet but could change and you risk losing access to your earnings and stake amount</p>
                <li className="hmvs-font text-base  text-white">Earn HNT by completing consensus group activities instantly (creating new blocks and validating transactions) </li>
                <li className="hmvs-font text-base  text-white">Once you have sent the funds to the deposit wallet and the validator has the required 10,000 HNT minimum to stake the validator will launch. At this stage the validator will be eligible for mining events. </li>
            </ul>

            <div className="mt-5">
                <p className="hmvs-text-primary hmvs-font font-bold">More information about Staking Helium Validators</p>
                <div><a href="/#/WhichStakingOptionIsRightForYou" className="hmvs-font text-base text-white ">Which Staking option is right for you?</a></div>
                <div><a href="/#/HowToCompleteYourStake" className="hmvs-font text-base  text-white ">How to complete your stake?</a></div>
                <div><a href="/#/HowToMineHeliumTokenWithValidators}" className="hmvs-font text-base  text-white ">How to mine helium tokens with Validators?</a></div>
                <div><a href="/#/HowToUnstakeAHeliumValidator" className="hmvs-font text-base  text-white ">How to unstake a Helium Validator?</a></div>
      
            </div> */}

        {/* <div className="py-14"></div> */}
        </div>
    )
  }

export default HowToGuide;